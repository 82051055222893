import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  createStyles,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Autocomplete } from "@material-ui/lab";

import DialogCommonDefault from "../../../../components/dialogs/DialogCommonDefault";
import { buttonColors } from "../../../../utils/enum";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import ValidationMessage from "../../../../components/validation/ValidationMessage";
import TextfieldDefaultNew from "../../../../components/textField/TextfieldDefaultNew";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    autoComplete: {
      marginTop: "4px",
      "& .MuiAutocomplete-input": {
        color: theme.palette.custom.orange.contrastText,
      },
      "& .MuiInputBase-root": {
        color: "inherit",
        backgroundColor: theme.palette.background.entity_background,
        borderRadius: 10,
      },
      [`& fieldset`]: {
        borderRadius: 10,
        border: "none",
        color: theme.palette.custom.orange.contrastText,
      },
    },
    titleColor: {
      color: theme.palette.custom.orange.contrastText,
    },
    textField: {
      overflowWrap: "break-word",
      wordWrap: "break-word",
      backgroundColor: theme.palette.background.entity_highlight_background,
      borderRadius: "10px",
      border: "none",
      [`& fieldset`]: {
        borderRadius: "10px",
        border: "none",
        cursor: "pointer",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
  }),
);

export interface EditPostCodeProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  handleUpdate: any;
  isLoading: boolean;
  locationNode: any;
  handleChangePostCodeLocation: any;
  selectedPostcode: any;
  postCodeNode: any;
  editPostCode: any;
}

/**
 * EditPostCode Component
 *
 * A React functional component for editing a postcode. This component uses Material-UI for styling and layout.
 * It manages the state for the postcode, location selection, error messages, and loading status. The component
 * allows users to update an existing postcode with validation checks for both the postcode format and location.
 * The component interacts with the provided `handleUpdate` function to submit the updated data and also includes
 * UI elements such as text fields and an autocomplete dropdown for location selection.
 *
 * Props:
 * - isOpen (boolean): Whether the dialog is open.
 * - setIsOpen (function): Function to toggle the dialog's open state.
 * - handleUpdate (function): Function to update the postcode.
 * - isLoading (boolean): Loading state for disabling the update button.
 * - locationNode (array): List of locations for the autocomplete dropdown.
 * - handleChangePostCodeLocation (function): Function to handle location selection.
 * - selectedPostcode (object): The currently selected postcode from the dropdown.
 * - postCodeNode (array): List of existing postcodes.
 * - editPostCode (object): The postcode object to be edited.
 *
 * The component maintains several state variables, including `postcode`, `selectedPostcodeInitial`,
 * `postcodeInitial`, `postCodeErrorMessage`, `isError`, and `open` to manage form inputs, error messages,
 * and the dropdown's open state. It includes functions for handling changes in the postcode input, updating
 * the postcode, and managing location selection.
 */
const EditPostCode: React.FC<EditPostCodeProps> = ({
  isOpen,
  setIsOpen,
  handleUpdate,
  isLoading,
  locationNode,
  handleChangePostCodeLocation,
  selectedPostcode,
  postCodeNode,
  editPostCode,
}) => {
  const [postcode, setPostcode] = useState("");
  const [postcodeInitial, setPostcodeInitial] = useState("");
  const [selectedPostcodeInitial, setSelectedPostcodeInitial] = useState("");
  const [postCodeErrorMessage, setPostCodeErrorMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [open, setOpen] = useState(false);

  /**
   * useEffect hook that runs when the component is opened
   */
  useEffect(() => {
    // Check if the dialog is open
    if (isOpen) {
      // Set the postcode from the editPostCode prop
      const { postCode } = editPostCode;
      setPostcode(postCode); // Update the local state with the postcode from editPostCode

      // Set the initial selected postcode ID from the selectedPostcode prop
      setSelectedPostcodeInitial(selectedPostcode.id);

      // Set the initial postcode value for comparison during update operations
      setPostcodeInitial(postCode);

      // Clear any existing error state
      setIsError(false);

      // Clear any existing postcode error message
      setPostCodeErrorMessage("");
    }
  }, [isOpen]);

  /**
   * EditPostCode Component
   *
   * A React functional component that renders a dialog for editing a postcode.
   */
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Update the postcode state with the input value, converting it to uppercase to ensure consistency
    setPostcode(e.target.value);
    // If the input field has a value
    if (e.target.value) {
      // Clear any existing error state
      setIsError(false);

      // Clear any existing postcode error message
      setPostCodeErrorMessage("");
    }
  };

  /**
   * Validates the format of a given postcode.
   *
   * The postcode format is defined as:
   * - It must start with an uppercase letter.
   * - Followed by zero or more uppercase letters or digits.
   * - Ends with a single digit.
   *
   * @param {string} value - The postcode string to validate.
   * @returns {boolean} - Returns `true` if the postcode matches the format, otherwise `false`.
   */
  const validatePostcode = (value: string): boolean => {
    // Regular expression to match the postcode format:
    // - Starts with an uppercase letter.
    // - Followed by zero or more uppercase letters and digits.
    // - Ends with a digit.
    const regex = /^[A-Z][A-Z0-9]*\d$/;

    // Test the provided value against the regex pattern.
    return regex.test(value);
  };

  /**
   * Function to handle the update of the postcode.
   *
   * The function performs several checks to ensure the validity and uniqueness of the postcode before proceeding with the update.
   */
  const handleUpdatePostcode = () => {
    // Flag to track if there's an error during validation.
    let isError = false;

    // Check if the current postcode already exists in the list of existing postcodes.
    postCodeNode.map((postCodeNode: any) => {
      if (
        postcode.trim() === postCodeNode.postCode && // Compare trimmed postcode with existing postcodes.
        selectedPostcode.id === selectedPostcodeInitial // Ensure the selected postcode ID hasn't changed.
      ) {
        // Set error flag if the postcode already exists.
        isError = true;
      }
    });

    // If the postcode already exists, set the error state with a corresponding message.
    if (isError) {
      setIsError(true);
      return setPostCodeErrorMessage("Postcode already exist");
    }

    // If the postcode is empty or no location is selected, set the error state.
    if (!postcode.trim() || Object.keys(selectedPostcode).length === 0) {
      setIsError(true);
      return;
    }

    // Validate the format of the postcode using the validatePostcode function.
    if (!validatePostcode(postcode)) {
      setIsError(true);
      setPostCodeErrorMessage(
        "Invalid postcode format. Ensure it starts with a letter and ends with a number.",
      );
      return;
    }

    // Clear any error messages and proceed with updating the postcode.
    setIsError(false);
    handleUpdate(postcode.trim(), postcodeInitial, selectedPostcodeInitial);
  };

  const classes = useStyles();

  return (
    <DialogCommonDefault
      open={isOpen}
      setOpen={setIsOpen}
      isNeedFixedHeight={false}
      backgroundColor="entity_background"
      maxWidth="xs"
    >
      <DialogTitle>{`Edit postcode - ${postcodeInitial}`}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextfieldDefaultNew
              id="postcode"
              name="postcode"
              style={{ width: "100%" }}
              label="Postcode"
              onChange={handleNameChange}
              value={postcode}
              type="text"
            />
            {((isError && !postcode) || (isError && postCodeErrorMessage)) && (
              <ValidationMessage
                message={postCodeErrorMessage || "Name is required"}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              size={"small"}
              id="locationSelectGlobal"
              color="inherit"
              options={locationNode}
              value={selectedPostcode}
              getOptionLabel={(option: any) => option.label || ""}
              disableClearable
              onChange={handleChangePostCodeLocation}
              classes={{ root: classes.autoComplete }}
              popupIcon={<ArrowDropDownIcon color={"inherit"} />}
              renderOption={(props: any) => {
                return (
                  <Grid
                    container
                    {...props}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      zIndex: 0,
                      height: "20px",
                      marginBottom: "4px",
                    }}
                  >
                    <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        className={classes.titleColor}
                      >
                        {props.label}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              }}
              renderInput={(params: any) => (
                <TextField
                  color="inherit"
                  {...params}
                  label=""
                  placeholder="Select Department"
                  variant="outlined"
                />
              )}
            />
            {isError && Object.keys(selectedPostcode).length === 0 && (
              <ValidationMessage message="Location is required" />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ marginBottom: "12px" }}>
        <ButtonCommon
          onClick={() => setIsOpen(false)}
          variant="contained"
          style={{ fontSize: 11, width: "120px", marginRight: "12px" }}
          color={buttonColors.CANCEL_BUTTON_COLOR}
          disabled={isLoading}
        >
          Cancel
        </ButtonCommon>
        <ButtonCommon
          onClick={handleUpdatePostcode}
          variant="contained"
          style={{ fontSize: 11, width: "120px", marginRight: "14px" }}
          color={buttonColors.UPDATE_BUTTON_COLOR}
          disabled={
            isLoading ||
            (postcodeInitial === postcode &&
              selectedPostcode.id === selectedPostcodeInitial)
          }
        >
          {isLoading ? (
            <CircularProgress size={20} color="secondary" />
          ) : (
            "Update"
          )}
        </ButtonCommon>
      </DialogActions>
    </DialogCommonDefault>
  );
};

export default EditPostCode;
