import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import { Box } from "@material-ui/core";
import _ from "lodash";

import withAuthority from "../../../../components/Auth/withAuthority";
import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import { fetchAllFilterLocations } from "../../../../services/locationApp/locationFilterService";
import InventoryDetailsDefault from "../../../../components/common/InventoryDetails/InventoryDetailsDefault";
import ChainConfigurationNode from "./ChainConfigurationNode";
import AlertDialog from "../../../../components/alerts/AlertDialog";
import {
  fetchChainLocation,
  fetchPostCodeLookUp,
} from "../../../../services/locationApp/chainConfiguration";

interface Location {
  id: string;
  businessDisplayName: string;
}

interface ChainResponse {
  id: string;
  primary_location: string;
  locations: string[];
}

/**
 * PostCodeLockUp Component
 *
 * This component manages the chain configuration for locations, including the retrieval and display of postcodes,
 * locations, and chain-specific details. It utilizes several Material-UI components for styling and layout,
 * and handles API calls to fetch the necessary data.
 *
 * Features:
 * - Fetches and displays a list of locations and associated chain configurations.
 * - Displays alerts for success and error messages.
 * - Manages stock configuration changes and error handling.
 * - Implements skeleton loading for better user experience during data fetches.
 */
const PostCodeLockUp: React.FunctionComponent = () => {
  const [error, setError] = useState("");
  const [isStockEnable, setIsStockEnable] = useState(true);
  const [postCodeNode, setPostCodeNode] = useState<any>([]);
  const [locationNode, setLocationNode] = useState<any>([]);
  const [locationObj, setLocationObj] = useState<any>({});
  const [primaryLocationId, setPrimaryLocationId] = useState("");

  const [chainId, setChainId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [
    successfullyChangeStockConfiguration,
    setSuccessfullyChangeStockConfiguration,
  ] = useState(false);
  const [locationSelectorList, setLocationSelectorList] = useState([]);
  const [isOpenSkeletonLoading, setIsOpenSkeletonLoading] = useState(true);
  const [success, setSuccess] = useState("");

  const match: any = useRouteMatch();

  // Effect to set the document title and fetch locations on component mount
  useEffect(() => {
    document.title = "Location - Chain Configuration";
    getAllFilterLocation();
  }, []);

  /**
   * Fetches postcode details for a given location and updates state.
   */
  const handleGetPostCodeLookUp = async () => {
    try {
      // Make an API call to fetch postcode lookup data using the location ID from route parameters.
      const res = await fetchPostCodeLookUp(match.params.locationId);

      // Check if the response contains data in the expected structure.
      if (res.data.data) {
        // If data is present, update the `postCodeNode` state with the retrieved data.
        setPostCodeNode(res.data.data);
      } else {
        // If no data is present, set `postCodeNode` to an empty array to handle gracefully.
        setPostCodeNode([]);
      }
      // Disable the skeleton loading indicator after data is fetched successfully.
      setIsOpenSkeletonLoading(false);
    } catch (error) {
      // Handle any errors that occur during the API call by setting an error message.
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);

      // Ensure the skeleton loading indicator is disabled, even if an error occurs.
      setIsOpenSkeletonLoading(false);
    }
  };

  /**
   * handleGetLocationChain:
   *
   * This function is responsible for fetching and processing chain configuration details for a specific location.
   * It performs the following tasks:
   */
  const handleGetLocationChain = async (locationNode: Location[]) => {
    try {
      // Fetch chain location details.
      const res = await fetchChainLocation(match.params.locationId);

      // Fetch postcode lookup details.
      await handleGetPostCodeLookUp();

      // Destructure data for better clarity.
      const chainData: ChainResponse | undefined = res?.data?.data;

      // Return early if chain data or locationNode is missing.
      if (!chainData?.locations || !locationNode) {
        setLocationObj({});
        setLocationNode([]);
        return;
      }

      // Filter and map locationNode to match chain locations.
      const locationDetails = locationNode
        .filter((location) => chainData.locations.includes(location.id))
        .map((location) => ({
          id: location.id,
          label: location.businessDisplayName,
        }));

      // Transform locationDetails into an object for quick lookups.
      const locationObj = locationDetails.reduce(
        (acc, location) => ({
          ...acc,
          [location.id]: location,
        }),
        {} as Record<string, { id: string; label: string }>,
      );

      // Update states with fetched and processed data.
      setChainId(chainData.id || "");
      setPrimaryLocationId(chainData.primary_location);
      setLocationObj(locationObj);
      setLocationNode(locationDetails);
    } catch (error) {
      // Handle unexpected errors and ensure loading state is cleared.
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsOpenSkeletonLoading(false);
    }
  };

  /**
   * getAllFilterLocation:
   *
   * This function is responsible for fetching all locations that can be filtered
   * and then processing the fetched data to handle the chain configuration.
   */
  const getAllFilterLocation = async () => {
    try {
      // Fetch all filterable locations from the server.
      const res = await fetchAllFilterLocations("");

      // Pass the fetched location data to the `handleGetLocationChain` function.
      // If no data is available, pass an empty array to avoid potential errors.
      handleGetLocationChain(res.data.data || []);
    } catch (error) {
      // Handle any errors that occur during the API call.
      // Update the error state with a predefined error message.
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);

      // Step 4: Stop the skeleton loading state to reflect that the process has concluded.
      setIsOpenSkeletonLoading(false);
    }
  };

  return (
    <>
      <Box>
        <InventoryDetailsDefault
          locationSelectorList={locationSelectorList}
          isOpenSkeletonLoading={isOpenSkeletonLoading}
          topic="Chain Configuration"
          isNeedAddButton={false}
          nodeList={[1]}
          isActiveLocationId={true}
        >
          <ChainConfigurationNode
            postCodeNode={postCodeNode}
            locationNode={locationNode}
            setPostCodeNode={setPostCodeNode}
            chainId={chainId}
            locationObj={locationObj}
            primaryLocationId={primaryLocationId}
          />
        </InventoryDetailsDefault>
        <DefaultAlert
          open={!!success}
          handleClose={() => setSuccess("")}
          message={success}
          severity={"success"}
        />
        <DefaultAlert
          open={!!error}
          handleClose={() => setError("")}
          message={error}
          severity="error"
        />
      </Box>

      {!isStockEnable && (
        <AlertDialog
          open={!isStockEnable}
          title="Activation Required"
          desc={errorMessage}
          severity="error"
          confirmLabel="Ok"
          disableBackdropClick
          confirmAction={() => {
            setIsStockEnable(true);
          }}
          color="secondary"
        />
      )}

      {successfullyChangeStockConfiguration && (
        <AlertDialog
          open={successfullyChangeStockConfiguration}
          title="Success"
          desc="Successfully change stock configuration."
          severity="success"
          confirmLabel="Ok"
          disableBackdropClick
          confirmAction={() => {
            setSuccessfullyChangeStockConfiguration(false);
          }}
          color="secondary"
        />
      )}
    </>
  );
};

export default withAuthority(
  PostCodeLockUp,
  Authorities.STOCK_CONFIGURATION_READ,
);
