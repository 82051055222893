/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-use-before-define */
import * as React from "react";
import {
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useRouteMatch } from "react-router-dom";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import _ from "lodash";
import StorefrontIcon from "@material-ui/icons/Storefront";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import DashboardIcon from "@material-ui/icons/Dashboard";
import BookmarkBorder from "@material-ui/icons/BookmarkBorder";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import { Icon } from "@iconify/react";
import queryString from "query-string";
import ColorLensIcon from "@material-ui/icons/ColorLens";
import { getLocationBasicInfo } from "../../../services/locationApp/storeService";
import { CustomTheme } from "../../../types/customTheme";
import ArtTrackIcon from "@material-ui/icons/ArtTrack";
import ListAltIcon from "@material-ui/icons/ListAlt";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import LockOpenIcon from "@material-ui/icons/LockOpen";

export interface PageHeaderMobileProps {
  title?: any;
  subTitle?: any;
  icon?: any;
  color?: any;
  isNeedFixed?: any;
}

/* This component displays the header for each page. */
const PageHeaderMobile: React.FunctionComponent<PageHeaderMobileProps> = ({
  isNeedFixed,
}) => {
  const [locationData, setLocationData] = React.useState<any>();

  const match: any = useRouteMatch();

  const fetchBasicInfo = async () => {
    try {
      const res = await getLocationBasicInfo(match.params.locationId);
      setLocationData(res.data.data);
    } catch (error) {}
  };
  React.useEffect(() => {
    fetchBasicInfo();
  }, [document.title]);

  const handleIcons = (title: string) => {
    if (title === " Dashboard") {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "48px" }}
        >
          Dashboard
        </span>
      );
    } else if (
      window.location.href.includes(
        "/internalConfiguration/paymentConfiguration",
      )
    ) {
      return <Icon icon="dashicons:admin-generic" height="52" width="52" />;
    } else if (
      window.location.href.includes("/internalConfiguration/users-permission")
    ) {
      return <VerifiedUserIcon style={{ fontSize: "36px" }} />;
    } else if (title === " Sale Information") {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "48px" }}
        >
          list_alt
        </span>
      );
    } else if (title === " Shift Summary") {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "48px" }}
        >
          description
        </span>
      );
    } else if (title === " Item Sales") {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "48px" }}
        >
          format_list_numbered
        </span>
      );
    } else if (title === " Category Sales") {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "48px" }}
        >
          view_list
        </span>
      );
    } else if (title === " Daily Sales Report") {
      return <DateRangeOutlinedIcon style={{ fontSize: "48px" }} />;
    } else if (title === " Cashier Summary Report") {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "40px" }}
        >
          user_attributes
        </span>
      );
    } else if (title === " Discount Summary Report") {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "44px" }}
        >
          heap_snapshot_thumbnail
        </span>
      );
    } else if (title === " Sales By Cashier Report") {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "44px" }}
        >
          recent_actors
        </span>
      );
    } else if (title === " Driver Sessions Summary") {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "44px" }}
        >
          pedal_bike
        </span>
      );
    } else if (title === " Revenue Center Report") {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "36px" }}
        >
          hub
        </span>
      );
    } else if (title === " Pay In Out") {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "48px" }}
        >
          swap_horiz
        </span>
      );
    } else if (title === " Payment Report") {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "48px" }}
        >
          wallet
        </span>
      );
    } else if (title === " VAT Summary") {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "48px" }}
        >
          assured_workload
        </span>
      );
    } else if (title === " Sale Report") {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "48px" }}
        >
          receipt_long
        </span>
      );
    } else if (title === " Sale Cancel") {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "48px" }}
        >
          cancel
        </span>
      );
    } else if (title === " Void Transaction") {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "48px" }}
        >
          delete_forever
        </span>
      );
    } else if (window.location.href.includes("/presto-locations/basic-info")) {
      return <StorefrontIcon style={{ fontSize: "48px" }} />;
    } else if (
      window.location.href.includes(
        "/presto-locations/internalConfiguration/scheduledTask",
      )
    ) {
      return <AssignmentTurnedInIcon style={{ fontSize: "48px" }} />;
    } else if (
      window.location.href.includes("/presto-locations/opening-times")
    ) {
      return <AccessTimeIcon style={{ fontSize: "48px" }} />;
    } else if (window.location.href.includes("/presto-locations")) {
      return <DashboardIcon style={{ fontSize: "48px" }} />;
    } else if (window.location.href.includes("/eatpresto/orders")) {
      return <BookmarkBorder style={{ fontSize: "48px" }} />;
    } else if (window.location.href.includes("/eatpresto/discounts")) {
      return <Icon icon="tabler:discount-2" height="52" width="52" />;
    } else if (window.location.href.includes("/eatpresto/delivery-info")) {
      return <LocalShippingIcon style={{ fontSize: "48px" }} />;
    } else if (window.location.href.includes("/eatpresto/theme")) {
      return <ColorLensIcon style={{ fontSize: "48px" }} />;
    } else if (window.location.href.includes("/eatpresto/banners")) {
      return <ArtTrackIcon style={{ fontSize: "48px" }} />;
    } else if (window.location.href.includes("/eatpresto/closureDates")) {
      return <QueryBuilderIcon style={{ fontSize: "48px" }} />;
    } else if (window.location.href.includes("/eatpresto/dailyOrdersReport")) {
      return <Icon height="52" width="52" icon="carbon:report" />;
    } else if (window.location.href.includes("/eatpresto")) {
      return <DashboardIcon style={{ fontSize: "48px" }} />;
    } else if (window.location.href.includes("/presto-task/task")) {
      return <ListAltIcon style={{ fontSize: "48px" }} />;
    } else if (window.location.href.includes("/presto-task")) {
      return <DashboardIcon style={{ fontSize: "48px" }} />;
    } else if (window.location.href.includes("/presto-menus/menu")) {
      return <MenuBookIcon style={{ fontSize: "48px" }} />;
    } else if (window.location.href.includes("/presto-menus/printerMapping")) {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "48px" }}
        >
          print
        </span>
      );
    } else if (
      window.location.href.includes("/presto-menus/mappingStrategies")
    ) {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "48px" }}
        >
          alt_route
        </span>
      );
    } else if (
      window.location.href.includes("/presto-menus") &&
      !window.location.href.includes(
        "/presto-menus/internalConfiguration/productConfiguration",
      )
    ) {
      return <DashboardIcon style={{ fontSize: "48px" }} />;
    } else if (window.location.href.includes("/stockItems")) {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "48px" }}
        >
          inventory_2
        </span>
      );
    } else if (window.location.href.includes("/stockOrders")) {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "48px" }}
        >
          draft_orders
        </span>
      );
    } else if (window.location.href.includes("/stockMovements")) {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "48px" }}
        >
          swap_horiz
        </span>
      );
    } else if (window.location.href.includes("/waste")) {
      return <Icon icon="ri:filter-fill" height="28" width="28" />;
    } else if (window.location.href.includes("/stockTake")) {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "48px" }}
        >
          inventory
        </span>
      );
    } else if (window.location.href.includes("/admin/group")) {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "48px" }}
        >
          hive
        </span>
      );
    } else if (window.location.href.includes("/admin/department")) {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "48px" }}
        >
          token
        </span>
      );
    } else if (window.location.href.includes("/admin/supplier")) {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "48px" }}
        >
          local_shipping
        </span>
      );
    } else if (
      window.location.href.includes("/internalConfiguration/stockConfiguration")
    ) {
      return <Icon icon="dashicons:admin-generic" height="52" width="52" />;
    } else if (
      window.location.href.includes(
        "/internalConfiguration/loyaltyConfiguration",
      )
    ) {
      return <Icon icon="dashicons:admin-generic" height="52" width="52" />;
    } else if (
      window.location.href.includes(
        "/internalConfiguration/apiKeyConfiguration",
      )
    ) {
      return <LockOpenIcon style={{ fontSize: "36px" }} />;
    } else if (
      window.location.href.includes(
        "/internalConfiguration/productConfiguration",
      )
    ) {
      return <Icon icon="dashicons:admin-generic" height="52" width="52" />;
    } else if (window.location.href.includes("/presto-customer/customerList")) {
      return (
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "48px" }}
        >
          group
        </span>
      );
    } else if (window.location.href.includes("/presto-customer/strategyList")) {
      return <Icon icon="material-symbols:strategy" height="40" width="40" />;
    } else if (window.location.href.includes("/presto-customer/bannerList")) {
      return (
        <Icon
          icon="material-symbols:planner-banner-ad-pt"
          height="40"
          width="40"
        />
      );
    } else if (window.location.href.includes("/presto-customer/rewardList")) {
      return (
        <Icon
          icon="material-symbols-light:rewarded-ads"
          height="40"
          width="40"
        />
      );
    }
  };

  const handleTitle = (title: string) => {
    if (title === " Sale Information") {
      return "Sale Transactions";
    } else if (
      window.location.href.includes(
        "/internalConfiguration/paymentConfiguration",
      )
    ) {
      return "Payment Configuration";
    } else if (
      window.location.href.includes("/internalConfiguration/users-permission")
    ) {
      return "Users & Permissions";
    } else if (title === " Pay In Out") {
      return "Paid In-Out Report";
    } else if (title === " Payment Report") {
      return "Payment Types Report";
    } else if (title === " VAT Summary") {
      return "VAT Summary Report";
    } else if (title === " Sale Report") {
      return "Sale summary report";
    } else if (title === " Sale Cancel") {
      return "Cancelled Sales Transactions";
    } else if (title === " Void Transaction") {
      return "Voided Transactions";
    } else if (
      window.location.href.includes("/presto-locations/opening-times")
    ) {
      return "Opening Times";
    } else if (window.location.href.includes("/presto-locations/basic-info")) {
      return "Business Info";
    } else if (
      window.location.href.includes(
        "/presto-locations/internalConfiguration/scheduledTask",
      )
    ) {
      return "Scheduled Tasks";
    } else if (window.location.href.includes("/presto-locations")) {
      return "Dashboard";
    } else if (window.location.href.includes("/eatpresto/orders")) {
      return "Eat Presto Online Orders";
    } else if (window.location.href.includes("/eatpresto/discounts")) {
      return "Discounts";
    } else if (window.location.href.includes("/eatpresto/delivery-info")) {
      return "Collection, Delivery & Dine-In Information";
    } else if (window.location.href.includes("/eatpresto/theme")) {
      return "Online Ordering Theme";
    } else if (window.location.href.includes("/eatpresto/banners")) {
      return "Banners";
    } else if (window.location.href.includes("/eatpresto/closureDates")) {
      return "Closure Dates";
    } else if (window.location.href.includes("/eatpresto/dailyOrdersReport")) {
      return "Daily Orders Report";
    } else if (window.location.href.includes("/eatpresto")) {
      return "Dashboard";
    } else if (window.location.href.includes("/presto-task/task")) {
      return "Task Information";
    } else if (window.location.href.includes("/presto-task")) {
      return "Dashboard";
    } else if (window.location.href.includes("/presto-menus/menu")) {
      return "Menu Information";
    } else if (window.location.href.includes("/presto-menus/printerMapping")) {
      return "Menu Printer Information";
    } else if (
      window.location.href.includes("/presto-menus/mappingStrategies")
    ) {
      return "Mapping Strategy Information";
    } else if (
      window.location.href.includes("/presto-menus") &&
      !window.location.href.includes(
        "/presto-menus/internalConfiguration/productConfiguration",
      )
    ) {
      return "Dashboard";
    } else if (window.location.href.includes("/stockItems")) {
      return "Stock Items";
    } else if (window.location.href.includes("/stockOrders")) {
      return "Stock Orders";
    } else if (window.location.href.includes("/stockMovements")) {
      return "Stock Movements Report";
    } else if (window.location.href.includes("/waste")) {
      return "Waste";
    } else if (window.location.href.includes("/stockTake")) {
      return "Stock Take";
    } else if (window.location.href.includes("/admin/group")) {
      return "Groups";
    } else if (window.location.href.includes("/admin/department")) {
      return "Departments";
    } else if (window.location.href.includes("/admin/supplier")) {
      return "Suppliers";
    } else if (
      window.location.href.includes("/internalConfiguration/stockConfiguration")
    ) {
      return "Stock Configuration";
    } else if (
      window.location.href.includes(
        "/internalConfiguration/loyaltyConfiguration",
      )
    ) {
      return "Loyalty Configuration";
    } else if (
      window.location.href.includes(
        "/internalConfiguration/apiKeyConfiguration",
      )
    ) {
      return "API Key Configuration";
    } else if (
      window.location.href.includes(
        "/internalConfiguration/productConfiguration",
      )
    ) {
      return "Product Configuration";
    } else if (window.location.href.includes("/presto-customer/customerList")) {
      return "Customer List";
    } else if (window.location.href.includes("/presto-customer/strategyList")) {
      return "Loyalty Strategies";
    } else if (window.location.href.includes("/presto-customer/rewardList")) {
      return "Loyalty Rewards";
    } else if (window.location.href.includes("/presto-customer/bannerList")) {
      return "Loyalty Banners";
    } else {
      return title;
    }
  };

  const handleSubTitle = (app: string, title: string) => {
    if (app === "Sale " && title === " Dashboard") {
      return `Sale Overview-${locationData?.businessDisplayName}`;
    } else if (
      window.location.href.includes(
        "/internalConfiguration/paymentConfiguration",
      )
    ) {
      return `Payment Configuration-${locationData?.businessDisplayName}`;
    } else if (title === " Sale Information") {
      return `Browse sales transactions-${locationData?.businessDisplayName}`;
    } else if (title === " Shift Summary") {
      return `Browse shift reports-${locationData?.businessDisplayName}`;
    } else if (title === " Item Sales") {
      return `Generate itemized sales report-${locationData?.businessDisplayName}`;
    } else if (title === " Category Sales") {
      return `Generate itemized sales report-${locationData?.businessDisplayName}`;
    } else if (title === " Daily Sales Report") {
      return `Generate Daily Sales report-${locationData?.businessDisplayName}`;
    } else if (title === " Cashier Summary Report") {
      return `Generate Cashier Summary-${locationData?.businessDisplayName}`;
    } else if (title === " Discount Summary Report") {
      return `Generate Discount Summary-${locationData?.businessDisplayName}`;
    } else if (title === "  Driver Sessions Summary") {
      return `Generate  Driver Sessions Summary-${locationData?.businessDisplayName}`;
    } else if (title === " Sales By Cashier Report") {
      return `Generate Sales By Cashier Report-${locationData?.businessDisplayName}`;
    } else if (title === " Driver Sessions Summary") {
      return `Generate Driver Sessions Summary-${locationData?.businessDisplayName}`;
    } else if (title === " Revenue Center Report") {
      return `Generate Revenue Center report-${locationData?.businessDisplayName}`;
    } else if (title === " Pay In Out") {
      return `Generate paid in-out report-${locationData?.businessDisplayName}`;
    } else if (title === " Payment Report") {
      return `Generate payment types report-${locationData?.businessDisplayName}`;
    } else if (title === " VAT Summary") {
      return `Generate VAT summary report-${locationData?.businessDisplayName}`;
    } else if (title === " Sale Report") {
      return `Generate Sale summary report-${locationData?.businessDisplayName}`;
    } else if (title === " Sale Cancel") {
      return `Browse cancelled sales transactions-${locationData?.businessDisplayName}`;
    } else if (title === " Void Transaction") {
      return `Browse voided transactions-${locationData?.businessDisplayName}`;
    } else if (
      app === "Locations " &&
      window.location.href.includes("/presto-locations/basic-info")
    ) {
      return `Manage business information-${locationData?.businessDisplayName}`;
    } else if (
      app === "Location " &&
      window.location.href.includes(
        "/presto-locations/internalConfiguration/scheduledTask",
      )
    ) {
      return `Manage Scheduled Tasks-${locationData?.businessDisplayName}`;
    } else if (
      app === "Locations " &&
      window.location.href.includes("/presto-locations/opening-times")
    ) {
      return `Manage opening times-${locationData?.businessDisplayName}`;
    } else if (
      app === "Locations " &&
      window.location.href.includes("/presto-locations")
    ) {
      return `Location Overview-${locationData?.businessDisplayName}`;
    } else if (window.location.href.includes("/eatpresto/orders")) {
      return `Browse online orders-${locationData?.businessDisplayName}`;
    } else if (window.location.href.includes("/eatpresto/discounts")) {
      return `Manage discounts-${locationData?.businessDisplayName}`;
    } else if (window.location.href.includes("/eatpresto/delivery-info")) {
      return `Manage collection, delivery & dine-in information-${locationData?.businessDisplayName}`;
    } else if (window.location.href.includes("/eatpresto/theme")) {
      return `Manage theme colours-${locationData?.businessDisplayName}`;
    } else if (window.location.href.includes("/eatpresto/banners")) {
      return `Manage promo and information banners-${locationData?.businessDisplayName}`;
    } else if (window.location.href.includes("/eatpresto/closureDates")) {
      return `Manage irregular business closure dates-${locationData?.businessDisplayName}`;
    } else if (window.location.href.includes("/eatpresto/dailyOrdersReport")) {
      return `Daily Orders Report-${locationData?.businessDisplayName}`;
    } else if (window.location.href.includes("/eatpresto")) {
      return `Eat Presto online ordering-${locationData?.businessDisplayName}`;
    } else if (window.location.href.includes("/presto-task/task")) {
      return `Eat Presto online ordering-${locationData?.businessDisplayName}`;
    } else if (window.location.href.includes("/presto-task")) {
      return `Task Overview-${locationData?.businessDisplayName}`;
    } else if (window.location.href.includes("/presto-menus/menu")) {
      return `Menu Overview-${locationData?.businessDisplayName}`;
    } else if (window.location.href.includes("/presto-menus/printerMapping")) {
      return `Menu Printer Overview-${locationData?.businessDisplayName}`;
    } else if (
      window.location.href.includes("/presto-menus/mappingStrategies")
    ) {
      return `Mapping Strategy Overview-${locationData?.businessDisplayName}`;
    } else if (window.location.href.includes("/presto-menus")) {
      return `Menu Overview-${locationData?.businessDisplayName}`;
    } else if (window.location.href.includes("/stockItems")) {
      return `Stock Items Overview-${locationData?.businessDisplayName}`;
    } else if (window.location.href.includes("/stockOrders")) {
      return `Stock Orders Overview-${locationData?.businessDisplayName}`;
    } else if (window.location.href.includes("/stockMovements")) {
      return `Stock Movements Report-${locationData?.businessDisplayName}`;
    } else if (window.location.href.includes("/waste")) {
      return `Stock Waste Overview-${locationData?.businessDisplayName}`;
    } else if (window.location.href.includes("/stockTake")) {
      return `Stock Take Overview-${locationData?.businessDisplayName}`;
    } else if (window.location.href.includes("/admin/group")) {
      return `Groups Overview-${locationData?.businessDisplayName}`;
    } else if (window.location.href.includes("/admin/department")) {
      return `Departments Overview-${locationData?.businessDisplayName}`;
    } else if (window.location.href.includes("/admin/supplier")) {
      return `Suppliers Overview-${locationData?.businessDisplayName}`;
    } else if (
      window.location.href.includes("/internalConfiguration/stockConfiguration")
    ) {
      return `Stock Configuration-${locationData?.businessDisplayName}`;
    } else if (
      window.location.href.includes(
        "/internalConfiguration/loyaltyConfiguration",
      )
    ) {
      return `Loyalty Configuration-${locationData?.businessDisplayName}`;
    } else if (
      window.location.href.includes(
        "/internalConfiguration/apiKeyConfiguration",
      )
    ) {
      return `API Key Configuration-${locationData?.businessDisplayName}`;
    } else if (
      window.location.href.includes(
        "/internalConfiguration/productConfiguration",
      )
    ) {
      return `Product Configuration-${locationData?.businessDisplayName}`;
    } else if (window.location.href.includes("/presto-customer/customerList")) {
      return `Customer List-${locationData?.businessDisplayName}`;
    } else if (window.location.href.includes("/presto-customer/strategyList")) {
      return `Strategy List-${locationData?.businessDisplayName}`;
    } else if (window.location.href.includes("/presto-customer/rewardList")) {
      return `Loyalty Rewards-${locationData?.businessDisplayName}`;
    } else if (window.location.href.includes("/presto-customer/bannerList")) {
      return `Loyalty Banners-${locationData?.businessDisplayName}`;
    } else if (
      window.location.href.includes("/internalConfiguration/users-permission")
    ) {
      return `Users & Permissions-${locationData?.businessDisplayName}`;
    }
  };
  const maxWidth = useMediaQuery("(max-width: 600px)");
  const maxWidthTab = useMediaQuery("(max-width: 960px)");
  const theme: CustomTheme = useTheme();
  const isAppEmbedded = queryString.parse(window.location.search).isAppEmbedded;

  return (
    <>
      {isAppEmbedded !== "true" && (
        <Grid container>
          <Grid
            item
            xs={12}
            style={
              isNeedFixed
                ? {
                    position: "fixed",
                    top: maxWidth ? 56 : 64,
                    zIndex: 5,
                    width: maxWidth ? "94%" : maxWidthTab ? "96%" : "97%",
                    backgroundColor: theme.palette.background.default,
                  }
                : {}
            }
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  marginTop: "6px",
                  color: theme.palette.custom.orange.contrastText,
                }}
              >
                {handleIcons(document.title.split("-")[1])}
              </Typography>
              <div style={{ marginLeft: "4px" }}>
                <div
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    display: "flex",
                    color: theme.palette.custom.orange.contrastText,
                  }}
                >
                  {handleTitle(document.title.split("-")[1])}
                </div>
                <div
                  style={{
                    color: theme.palette.custom.orange.contrastText,
                    fontSize: "12px",
                    display: "flex",
                    fontWeight: "lighter",
                  }}
                >
                  {handleSubTitle(
                    document.title.split("-")[0],
                    document.title.split("-")[1],
                  )}
                </div>
              </div>
            </div>
            <Divider
              variant="fullWidth"
              style={{ height: "3px", borderRadius: "10px" }}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default PageHeaderMobile;
