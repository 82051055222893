import { useTheme } from "@material-ui/core";
import React from "react";
import { CustomTheme } from "../../../types/customTheme";
import _ from "lodash";

export interface InputBoxDefaultProps {
  autoUnitConvert: any;
  showRemainderSep: any;
  displaySuffix: any;
  inputMultiplier: any;
  inputSuffix: any;
  stockLevel: any;
}

/* This component is displayed if the sale information is empty. */
const StockLevelRow: React.FunctionComponent<InputBoxDefaultProps> = ({
  autoUnitConvert,
  showRemainderSep,
  displaySuffix,
  inputMultiplier,
  inputSuffix,
  stockLevel,
}) => {
  const theme: CustomTheme = useTheme();
  const roundToDecimalPlacesUsingSlice = (number: any) => {
    // Convert the number to a string
    const numString = parseFloat(number).toFixed(3).toString();

    // Find the position of the dot (decimal point)
    const dotIndex = numString.indexOf(".");

    // If the dot is not found, return the original number
    if (dotIndex === -1) {
      return number;
    }

    // Use slice to extract the desired number of decimal places
    const roundedString = numString.slice(0, dotIndex + 3 + 1);

    // Convert the result back to a number
    return parseFloat(roundedString);
  };

  const formatNumberWithDynamicDecimals = (number: any) => {
    const decimalCount = (number.toString().split(".")[1] || []).length;

    if (decimalCount === 0) {
      return parseFloat(number.toFixed(3)); // Show up to three decimals for integers
    } else if (decimalCount <= 3) {
      return parseFloat(number.toFixed(decimalCount)); // Show the exact number of decimals for non-integers with up to three decimals
    } else {
      return parseFloat(number.toFixed(3)); // Round values with more than three decimals to three decimals
    }
  };

  const handleStockLevel = () => {
    if (autoUnitConvert) {
      if (showRemainderSep) {
        let quotient = 0;
        let remainder = 0;
        if (
          inputMultiplier !== "" &&
          parseFloat(inputMultiplier.toString()) !== 0
        ) {
          quotient = Math.floor(stockLevel / inputMultiplier);
          remainder = Math.round(stockLevel % inputMultiplier);
        }

        if (
          parseFloat(inputMultiplier) > parseFloat(stockLevel) &&
          parseFloat(stockLevel) > -1 * parseFloat(inputMultiplier)
        ) {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  marginRight: "8px",
                  fontWeight: "bold",
                  fontSize: "16px",
                  marginBottom: "1px",
                }}
              >
                <div style={{ color: theme.palette.secondary.main }}>
                  {formatNumberWithDynamicDecimals(parseFloat(stockLevel))}
                </div>
              </div>
              {displaySuffix ? <div>{displaySuffix} </div> : ""}
            </div>
          );
        } else {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  marginRight: "8px",
                  fontWeight: "bold",
                  fontSize: "16px",
                  marginBottom: "1px",
                }}
              >
                <div style={{ color: theme.palette.secondary.main }}>
                  {quotient}
                </div>
              </div>
              {inputSuffix ? <div>{inputSuffix} </div> : ""}

              {remainder !== 0 && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      marginRight: "8px",
                      marginLeft: "8px",
                      fontWeight: "bold",
                      fontSize: "16px",
                      marginBottom: "1px",
                    }}
                  >
                    <div style={{ color: theme.palette.secondary.main }}>
                      {remainder.toString().slice(0, 3)}
                    </div>
                  </div>
                  {displaySuffix ? <div>{displaySuffix} </div> : ""}
                </div>
              )}
            </div>
          );
        }
      } else {
        if (
          parseFloat(stockLevel) < parseFloat(inputMultiplier) &&
          parseFloat(stockLevel) > -1 * parseFloat(inputMultiplier)
        ) {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  marginRight: "8px",
                  fontWeight: "bold",
                  fontSize: "16px",
                  marginBottom: "1px",
                }}
              >
                <div style={{ color: theme.palette.secondary.main }}>
                  {formatNumberWithDynamicDecimals(parseFloat(stockLevel))}
                </div>
              </div>
              {displaySuffix ? <div>{displaySuffix} </div> : ""}
            </div>
          );
        } else {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  marginRight: "8px",
                  fontWeight: "bold",
                  fontSize: "16px",
                  marginBottom: "1px",
                }}
              >
                <div style={{ color: theme.palette.secondary.main }}>
                  {inputMultiplier === "" || parseFloat(inputMultiplier) === 0
                    ? 0
                    : Number.isInteger(
                        parseFloat(stockLevel) / parseFloat(inputMultiplier),
                      )
                    ? parseFloat(stockLevel) / parseFloat(inputMultiplier)
                    : formatNumberWithDynamicDecimals(
                        parseFloat(stockLevel) / parseFloat(inputMultiplier),
                      )}
                </div>
              </div>
              {inputSuffix ? <div>{inputSuffix} </div> : ""}
            </div>
          );
        }
      }
    } else {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              marginRight: "8px",
              fontWeight: "bold",
              fontSize: "16px",
              marginBottom: "1px",
            }}
          >
            <div style={{ color: theme.palette.secondary.main }}>
              {roundToDecimalPlacesUsingSlice(stockLevel)}
            </div>
          </div>
          {displaySuffix ? <div>{displaySuffix} </div> : ""}
        </div>
      );
    }
  };
  return <>{handleStockLevel()}</>;
};

export default StockLevelRow;
