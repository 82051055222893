import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  createStyles,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
  Typography,
  Chip,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import DialogCommonDefault from "../../../../components/dialogs/DialogCommonDefault";
import { buttonColors } from "../../../../utils/enum";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import ValidationMessage from "../../../../components/validation/ValidationMessage";
import TextfieldDefaultNew from "../../../../components/textField/TextfieldDefaultNew";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    autoComplete: {
      marginTop: "4px",
      "& .MuiAutocomplete-input": {
        color: theme.palette.custom.orange.contrastText,
      },
      "& .MuiInputBase-root": {
        color: "inherit",
        backgroundColor: theme.palette.background.entity_background,
        borderRadius: 10,
      },
      [`& fieldset`]: {
        borderRadius: 10,
        border: "none",
        color: theme.palette.custom.orange.contrastText,
      },
    },
    titleColor: {
      color: theme.palette.custom.orange.contrastText,
    },
    chipContainer: {
      display: "flex",
      flexWrap: "wrap",
      gap: "8px",
    },
    chip: {
      backgroundColor: theme.palette.background.entity_highlight_background,
    },
  }),
);

export interface StockLevelModalProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  handleCreate: any;
  isLoading: boolean;
  locationNode: any;
  handleChangePostCodeLocation: any;
  selectedPostcode: any;
  postCodeNode: any;
}

/**
 * CreateNewChain Component
 *
 * This component renders a modal dialog for adding new postcodes to a selected location in an application.
 * It includes functionality to input, validate, and add postcodes via a text field and autocomplete dropdown for location selection.
 */

const CreateNewChain: React.FC<StockLevelModalProps> = ({
  isOpen,
  setIsOpen,
  handleCreate,
  isLoading,
  locationNode,
  handleChangePostCodeLocation,
  selectedPostcode,
  postCodeNode,
}) => {
  const [postcode, setPostcode] = useState("");
  const [postcodeChips, setPostcodeChips] = useState<string[]>([]);
  const [isError, setIsError] = useState(false);
  const [postcodeErrorMessage, setPostcodeErrorMessage] = useState("");
  const [locationErrorMessage, setLocationErrorMessage] = useState("");
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  /**
   * useEffect to Reset State on Modal Open
   *
   * This useEffect hook runs when the component mounts or when the `isOpen` prop changes.
   * It resets the postcode input, clears all existing postcode chips, and resets the error state.
   *
   * - `isOpen`: Boolean prop indicating whether the modal is open.
   * - `setPostcode`: State setter to clear the postcode input.
   * - `setPostcodeChips`: State setter to clear the list of postcode chips.
   * - `setIsError`: State setter to reset the error state.
   */
  useEffect(() => {
    if (isOpen) {
      setPostcode("");
      setPostcodeChips([]);
      setIsError(false);
    }
  }, [isOpen]);

  /**
   * validatePostcode
   *
   * Function to validate a postcode.
   *
   * - `value`: The postcode string to be validated.
   * - Returns: A boolean indicating whether the postcode matches the required pattern.
   *
   * The function uses a regular expression to validate the postcode format:
   * - The postcode must start with an uppercase letter (A-Z).
   * - It may contain alphanumeric characters (A-Z, 0-9).
   * - The postcode must end with a number (0-9).
   *
   * Example of a valid postcode: "A1", "B23", "C4D5E6"
   * Example of an invalid postcode: "1A", "B23#", "C D4E"
   */
  const validatePostcode = (value: string): boolean => {
    const regex = /^[A-Z][A-Z0-9]*\d$/;
    return regex.test(value);
  };

  /**
   * handlePostcodeChange
   *
   * Event handler for changes in the postcode input field.
   *
   * Example:
   * If the input is "a1 b2" and the function is called, it will update `postcode` to "A1B2".
   * If "A1B2" is a valid postcode, the error message will be cleared.
   */
  const handlePostcodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Clean and format input
    const input = e.target.value.toUpperCase().replace(/\s/g, "");
    // Update postcode state
    setPostcode(input);

    // Validate input
    if (input && !validatePostcode(input)) {
      // Invalid postcode format
      setPostcodeErrorMessage(
        "Postcode must be alphanumeric, start with a letter, and end with a number.",
      );
    } else {
      // Clear error message if valid
      setPostcodeErrorMessage("");
    }

    // Reset error state
    setIsError(false);
  };

  /**
   * handleAddPostcodeChip
   *
   * This function handles the process of adding a new postcode chip to the list.
   *
   * - This function performs the following checks:
   * 1. **Validate the postcode**:
   *    - Uses the `validatePostcode` function to check if the current `postcode` value is valid.
   *    - If the postcode format is invalid, sets the `isError` state to `true` and updates the `postcodeErrorMessage` with a relevant error message.
   *    - Returns early from the function, stopping any further processing if the postcode format is invalid.
   *
   * 2. **Check if the postcode is already in the list**:
   *    - Checks if the current `postcode` is already present in the `postcodeChips` array.
   *    - If it is, sets the `isError` state to `true` and updates the `postcodeErrorMessage` with an error message indicating that this postcode has already been added.
   *    - Returns early from the function, stopping any further processing if the postcode is a duplicate.
   *
   * 3. **Add the postcode**:
   *    - If the postcode passes both checks, it is added to the `postcodeChips` array.
   *    - The `setPostcodeChips` function updates the state with the new postcode.
   *    - The `setPostcode` function clears the current input field.
   *    - Clears any existing error message by resetting `postcodeErrorMessage` to an empty string.
   */
  const handleAddPostcodeChip = () => {
    // Step 1: Validate the postcode format
    if (!validatePostcode(postcode)) {
      setIsError(true);
      setPostcodeErrorMessage(
        "Invalid postcode format. Ensure it starts with a letter and ends with a number.",
      );
      return;
    }

    // Step 2: Check for duplicate postcode
    if (postcodeChips.includes(postcode)) {
      setIsError(true);
      setPostcodeErrorMessage("This postcode prefix has already been added.");
      return;
    }

    // Step 3: Add postcode to the chips array
    setPostcodeChips((prev) => [...prev, postcode]);
    setPostcode(""); // Clear the postcode input field
    setPostcodeErrorMessage(""); // Clear any existing error message
  };

  /**
   * handleChipDelete
   *
   * This function is used to handle the deletion of a postcode chip from the `postcodeChips` array.
   *
   * - The function takes a single argument `chipToDelete` which is the postcode chip to be removed.
   * - The function filters out the `chipToDelete` from the `postcodeChips` array.
   * - The `setPostcodeChips` function updates the state with the new array of chips excluding the one to be deleted.
   *
   * @param {string} chipToDelete - The postcode chip that needs to be removed.
   */
  const handleChipDelete = (chipToDelete: string) => {
    setPostcodeChips((prev) => prev.filter((chip) => chip !== chipToDelete));
  };

  /**
   * Handles the creation of new postcodes.
   *
   * This function validates the input postcode(s) and location selection, ensures there are no duplicate postcodes,
   * and then calls the `handleCreate` function with the valid postcodes to submit. The function manages various
   * states such as `isError` and error messages (`postcodeErrorMessage`, `locationErrorMessage`) to provide feedback
   * to the user. It ensures data integrity by preventing duplicate postcodes and requiring a location selection.
   *
   * The function operates based on the following conditions:
   * - If no postcodes are selected and the input is empty, it sets an error.
   * - If a location is not selected, it sets an error.
   * - If the postcode format is invalid, it sets an error.
   * - If the selected postcodes include duplicates, it sets an error.
   * - If all validations pass, it proceeds to call the `handleCreate` function with the valid postcodes.
   */
  const handleCreateNewPostcode = () => {
    // Check if no postcodes are selected and the input is empty
    if (postcodeChips.length === 0 && !postcode.trim()) {
      setIsError(true);
      setPostcodeErrorMessage("Postcode is required.");

      // Check if a location is not selected
      if (Object.values(selectedPostcode).length === 0) {
        setIsError(true);
        setLocationErrorMessage("Location is required.");
        return;
      }
      return;
    }

    // Check if no location is selected
    if (Object.values(selectedPostcode).length === 0) {
      setIsError(true);
      setLocationErrorMessage("Location is required.");
      return;
    }

    // Validate the postcode format
    if (postcode.trim() && !validatePostcode(postcode)) {
      setIsError(true);
      setPostcodeErrorMessage(
        "Invalid postcode format. Ensure it starts with a letter and ends with a number.",
      );
      return;
    }

    // Determine which postcodes to submit
    const postcodesToSubmit =
      postcodeChips.length > 0
        ? postcodeChips
        : [postcode.trim().toUpperCase()];

    // Check for duplicates in the existing postcodes
    let hasDuplicate = false;

    postCodeNode.map((postCodeNode: any) => {
      if (postcodesToSubmit.includes(postCodeNode.postCode)) {
        hasDuplicate = true;
      }
    });

    // If there are duplicates, show an error
    if (hasDuplicate) {
      setIsError(true);
      setPostcodeErrorMessage("This postcode prefix has already been added.");
      return;
    }

    // Clear errors and proceed with the creation
    setIsError(false);
    handleCreate(postcodesToSubmit);
  };

  return (
    <DialogCommonDefault
      open={isOpen}
      setOpen={setIsOpen}
      isNeedFixedHeight={false}
      backgroundColor="entity_background"
      maxWidth="xs"
    >
      <DialogTitle>Add Postcodes</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextfieldDefaultNew
              id="postcode"
              name="postcode"
              style={{ width: "100%" }}
              label="Postcode Prefix"
              onChange={handlePostcodeChange}
              value={postcode}
              type="text"
              onKeyDown={(e: any) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleAddPostcodeChip();
                }
              }}
            />
            {postcodeErrorMessage && isError && (
              <ValidationMessage message={postcodeErrorMessage} />
            )}
          </Grid>
          <Grid item xs={12}>
            <div className={classes.chipContainer}>
              {postcodeChips.map((chip, index) => (
                <Chip
                  key={index}
                  label={chip}
                  onDelete={() => handleChipDelete(chip)}
                  className={classes.chip}
                />
              ))}
            </div>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              size={"small"}
              id="locationSelectGlobal"
              color="inherit"
              options={locationNode}
              value={selectedPostcode}
              getOptionLabel={(option: any) => option.label || ""}
              disableClearable
              onChange={handleChangePostCodeLocation}
              classes={{ root: classes.autoComplete }}
              popupIcon={<ArrowDropDownIcon color={"inherit"} />}
              renderOption={(props: any) => {
                return (
                  <Grid
                    container
                    {...props}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      zIndex: 0,
                      height: "20px",
                      marginBottom: "4px",
                    }}
                  >
                    <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        className={classes.titleColor}
                      >
                        {props.label}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              }}
              renderInput={(params: any) => (
                <TextField
                  color="inherit"
                  {...params}
                  label=""
                  placeholder="Select Location"
                  variant="outlined"
                />
              )}
            />
            {isError &&
              locationErrorMessage &&
              Object.keys(selectedPostcode).length === 0 && (
                <ValidationMessage message={locationErrorMessage} />
              )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ marginBottom: "12px" }}>
        <ButtonCommon
          onClick={() => setIsOpen(false)}
          variant="contained"
          style={{ fontSize: 11, width: "120px", marginRight: "12px" }}
          color={buttonColors.CANCEL_BUTTON_COLOR}
          disabled={isLoading}
        >
          Cancel
        </ButtonCommon>
        <ButtonCommon
          onClick={handleCreateNewPostcode}
          variant="contained"
          style={{ fontSize: 11, width: "120px", marginRight: "14px" }}
          color={buttonColors.CREATE_BUTTON_COLOR}
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress size={20} color="secondary" />
          ) : (
            "Create"
          )}
        </ButtonCommon>
      </DialogActions>
    </DialogCommonDefault>
  );
};

export default CreateNewChain;
