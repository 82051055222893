import React, { useEffect, useState } from "react";
import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import _ from "lodash";
import GetAppIcon from "@material-ui/icons/GetApp";
import WithLoading from "../../../../utils/WithLoading";
import MenuButtonCommon from "../../../../components/buttons/MenuButtonCommon";
import { CustomTheme } from "../../../../types/customTheme";
import { handlePdf } from "./convert/handlePdf";
import { handleExcel } from "./convert/handleExcel";
import CardCommon from "../../../../components/card/CardCommon";
import moment from "moment";
import {
  convertDateFormat,
  convertDateTimeFormat,
  convertDateTimeFormatInventory,
} from "../../../../utils/ConvertDateTimeFormat";
import { useRouteMatch } from "react-router-dom";
import LocationDetailsCard from "../../../../components/common/LocationDetailsCard";
import DriverSessionBody from "./DriverSessionBody";
import DialogCommon from "../../../../components/dialogs/DialogCommon";
import TotalModal from "./TotalModal";

export interface ShiftHeaderProps {
  isLoading: any;
  driverSessions: any;
  filterDetails: any;
  filterData: any;
  locationSelectorList: any;
  setOpenFilterCard: any;
  driverList: any;
  setDriverList: any;
  driverSelectedList: any;
}

const useStyles = makeStyles((theme: any) =>
  createStyles({
    startTextStyle: {
      display: "flex",
      justifyContent: "start",
      paddingLeft: "16px",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        marginTop: "8px",
      },
    },
    endTextStyle: {
      display: "flex",
      justifyContent: "end",
      paddingRight: "16px",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        marginBottom: "8px",
      },
    },
  }),
);

/**
 * DriverSessionsHeader Component:
 *
 * This component handles the header section of the Shift component, including
 * sorting controls, location selection, and shift generation. It displays shift
 * information in a table-like format with sortable columns and provides options
 * for selecting shifts, generating reports, and handling user interactions.
 */
const DriverSessionsHeader: React.FunctionComponent<ShiftHeaderProps> = ({
  isLoading,
  driverSessions,
  filterDetails,
  filterData,
  locationSelectorList,
  setOpenFilterCard,
  driverList,
  setDriverList,
  driverSelectedList,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [pdfHeaders, setPdfHeaders] = useState<any>([]);
  const [nodesPdfExcel, setNodesPdfExcel] = useState<any>([]);
  const [nodes, setNodes] = useState<any>([]);
  const [excelHeaders, setExcelHeaders] = useState<any>([]);
  const [totalBox, setTotalBox] = useState<any>({});
  const [totalOrders, setTotalOrders] = useState<any>({});

  useEffect(() => {
    const driverDetails: any = [];

    setPdfHeaders([
      { title: "Customer Name", field: "customerName" },
      { title: "Address", field: "address" },
      { title: "Payment Type", field: "paymentType" },
      { title: "Driver Earning", field: "driverEarning" },
      { title: "Distance", field: "drivingDistance" },
      { title: "Created", field: "created" },
    ]);

    setExcelHeaders([
      { header: "Customer Name", key: "customerName", width: 50, height: 68 },
      { header: "Address", key: "address", width: 50, height: 68 },
      { header: "Payment Type", key: "paymentType", width: 50, height: 68 },
      { header: "Driver Earning", key: "driverEarning", width: 50, height: 68 },
      {
        header: "Distance",
        key: "drivingDistance",
        width: 50,
        height: 68,
      },
      { header: "Created", key: "created", width: 50, height: 68 },
      {
        header: "Comments",
        key: "comments",
        width: 50,
        height: 68,
      },
    ]);

    const cloneDriverSessions = _.cloneDeep(driverSessions);
    cloneDriverSessions.map((item: any) => {
      if (item.cmsDriverName !== "UNASSIGNED" && item.cmsDriverName !== "ALL") {
        driverDetails.push({
          id: item.cmsDriverName,
          label: item.cmsDriverName,
        });
      }
    });

    setDriverList(driverDetails);
  }, []);

  useEffect(() => {
    if (driverSelectedList?.length > 0) {
      let abc: any = [];
      const totalOrders: any = {};
      const totals = {
        cashCollect: 0,
        deliveryWage: 0,
        dailyWage: 0,
        totalWage: 0,
        adjustment: 0,
        totalOrders: 0,
        totalReDrops: 0,
      };
      let count = 0;
      const cloneDriverSessions = _.cloneDeep(driverSessions);
      cloneDriverSessions.map((item: any, index: any) => {
        if (
          item.cmsDriverName !== "UNASSIGNED" &&
          item.cmsDriverName !== "ALL" &&
          driverSelectedList.includes(item.cmsDriverName)
        ) {
          count = count + 1;
          const list: any = [];

          totals["cashCollect"] = parseFloat(
            (totals["cashCollect"] + parseFloat(item.cashCollect)).toFixed(2),
          );
          totals["deliveryWage"] = parseFloat(
            (totals["deliveryWage"] + parseFloat(item.milageFee)).toFixed(2),
          );
          totals["dailyWage"] = parseFloat(
            (
              totals["dailyWage"] +
              parseFloat(item.fixedFee) +
              parseFloat(item.adjustments)
            ).toFixed(2),
          );
          totals["adjustment"] = parseFloat(
            (
              totals["adjustment"] +
              (parseFloat(item.adjustments).toFixed(0) !== "0"
                ? parseFloat(item.adjustments)
                : 0)
            ).toFixed(2),
          );

          totals["totalWage"] = parseFloat(
            (
              totals["totalWage"] +
              parseFloat(item.fixedFee) +
              parseFloat(item.milageFee) +
              parseFloat(item.adjustments)
            ).toFixed(2),
          );
          if (item?.orders) {
            item.orders.map((data: any) => {
              if (
                !totalOrders[`${count}_${item["cmsDriverName"]}_count_orders`]
              ) {
                totalOrders[
                  `${count}_${item["cmsDriverName"]}_count_orders`
                ] = 0;
              }
              if (
                !totalOrders[`${count}_${item["cmsDriverName"]}_count_redrops`]
              ) {
                totalOrders[
                  `${count}_${item["cmsDriverName"]}_count_orders_redrops`
                ] = 0;
              }
              if (data?.isRedrop) {
                totals["totalReDrops"] = totals["totalReDrops"] + 1;

                totalOrders[
                  `${count}_${item["cmsDriverName"]}_count_orders_redrops`
                ] =
                  totalOrders[
                    `${count}_${item["cmsDriverName"]}_count_orders_redrops`
                  ] + 1;
              } else {
                totals["totalOrders"] = totals["totalOrders"] + 1;

                totalOrders[`${count}_${item["cmsDriverName"]}_count_orders`] =
                  totalOrders[
                    `${count}_${item["cmsDriverName"]}_count_orders`
                  ] + 1;
              }
              list.push({
                customerName: data.customerName,
                address: data.address,
                paymentType:
                  data.paymentType === "cash"
                    ? `£ ${parseFloat(data.paymentAmount).toFixed(2)}`
                    : "PAID",
                driverEarning: parseFloat(data.driverEarning).toFixed(2),
                drivingDistance: `${parseFloat(data.drivingDistance).toFixed(
                  2,
                )} Miles`,
                created: convertDateTimeFormat(data.created),
              });
            });
          }

          item["orders"] = list;
          abc.push(item);
        }
      });

      setTotalBox(totals);

      setNodesPdfExcel(abc);
      setNodes(abc);
    } else {
      let abc: any = [];
      const totalOrders: any = {};
      const totals = {
        cashCollect: 0,
        deliveryWage: 0,
        dailyWage: 0,
        totalWage: 0,
        adjustment: 0,
        totalOrders: 0,
        totalReDrops: 0,
      };
      let count = 0;
      const cloneDriverSessions = _.cloneDeep(driverSessions);
      cloneDriverSessions.map((item: any, index: any) => {
        if (
          item.cmsDriverName !== "UNASSIGNED" &&
          item.cmsDriverName !== "ALL"
        ) {
          const list: any = [];
          count = count + 1;

          totals["cashCollect"] = parseFloat(
            (totals["cashCollect"] + parseFloat(item.cashCollect)).toFixed(2),
          );
          totals["deliveryWage"] = parseFloat(
            (totals["deliveryWage"] + parseFloat(item.milageFee)).toFixed(2),
          );
          totals["dailyWage"] = parseFloat(
            (
              totals["dailyWage"] +
              parseFloat(item.fixedFee) +
              parseFloat(item.adjustments)
            ).toFixed(2),
          );
          totals["adjustment"] = parseFloat(
            (
              totals["adjustment"] +
              (parseFloat(item.adjustments).toFixed(0) !== "0"
                ? parseFloat(item.adjustments)
                : 0)
            ).toFixed(2),
          );

          totals["totalWage"] = parseFloat(
            (
              totals["totalWage"] +
              parseFloat(item.fixedFee) +
              parseFloat(item.milageFee) +
              parseFloat(item.adjustments)
            ).toFixed(2),
          );
          if (item?.orders) {
            item.orders.map((data: any) => {
              if (
                !totalOrders[`${count}_${item["cmsDriverName"]}_count_orders`]
              ) {
                totalOrders[
                  `${count}_${item["cmsDriverName"]}_count_orders`
                ] = 0;
              }
              if (
                !totalOrders[
                  `${count}_${item["cmsDriverName"]}_count_orders_redrops`
                ]
              ) {
                totalOrders[
                  `${count}_${item["cmsDriverName"]}_count_orders_redrops`
                ] = 0;
              }
              if (data?.isRedrop) {
                totals["totalReDrops"] = totals["totalReDrops"] + 1;

                totalOrders[
                  `${count}_${item["cmsDriverName"]}_count_orders_redrops`
                ] =
                  totalOrders[
                    `${count}_${item["cmsDriverName"]}_count_orders_redrops`
                  ] + 1;
              } else {
                totals["totalOrders"] = totals["totalOrders"] + 1;

                totalOrders[`${count}_${item["cmsDriverName"]}_count_orders`] =
                  totalOrders[
                    `${count}_${item["cmsDriverName"]}_count_orders`
                  ] + 1;
              }

              list.push({
                customerName: data.customerName,
                address: data.address,
                paymentType:
                  data.paymentType === "cash"
                    ? `£ ${parseFloat(data.paymentAmount).toFixed(2)}`
                    : "PAID",
                driverEarning: parseFloat(data.driverEarning).toFixed(2),
                drivingDistance: `${parseFloat(data.drivingDistance).toFixed(
                  2,
                )} Miles`,
                isRedrop: data?.isRedrop ? data.isRedrop : "",
                comments: data?.comments ? data.comments : "",
                created: convertDateTimeFormat(data.created),
              });
            });
          }

          item["orders"] = list;
          abc.push(item);
        }
      });
      setTotalOrders(totalOrders);
      setTotalBox(totals);

      setNodesPdfExcel(abc);
      setNodes(abc);
    }
  }, [driverSelectedList, driverSessions]);

  /* Open PDF / Excel selection menu */
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /* Close PDF / Excel selection menu */
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnClickText = () => {
    setOpenFilterCard(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [totalModalName, setTotalModalName] = useState(false);

  const handleOpen = (totalName: any) => {
    setTotalModalName(totalName);
    setIsOpenModal(true);
  };

  const theme: CustomTheme = useTheme();
  const open = Boolean(anchorEl);
  const match: any = useRouteMatch();
  const locationId = match.params.locationId;
  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "8px",
          }}
        >
          <LocationDetailsCard
            locationSelectedList={
              filterDetails ? [{ label: filterDetails.split("*")[0] }] : []
            }
            handleOnClickText={handleOnClickText}
          />
          {nodesPdfExcel.length > 0 && (
            <div style={{ width: "140px" }}>
              <MenuButtonCommon
                id="basic-button"
                variant="contained"
                style={{
                  fontSize: 11,
                  height: "38px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
                color="blue"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <GetAppIcon />
                <Typography
                  variant="body1"
                  style={{ paddingLeft: "16px", color: "white" }}
                >
                  Export
                </Typography>
              </MenuButtonCommon>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                PaperProps={{
                  elevation: 0,
                  style: {
                    borderRadius: "10px",
                    border: `1px solid ${theme.palette.background.entity_border}`,
                  },
                }}
                style={{ top: "56px" }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem
                  onClick={() => {
                    handlePdf(
                      pdfHeaders,
                      nodesPdfExcel,
                      filterDetails,
                      totalBox,
                      totalOrders,
                    );
                    handleClose();
                  }}
                >
                  Export as PDF
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleExcel(
                      excelHeaders,
                      nodesPdfExcel,
                      filterDetails,
                      totalBox,
                      totalOrders,
                    );
                    handleClose();
                  }}
                >
                  Export as CSV
                </MenuItem>
              </Menu>
            </div>
          )}
        </Grid>

        {nodes && (
          <Grid container spacing={2} style={{ marginTop: "20px" }}>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "start" }}
            >
              <Typography variant="h5" style={{ fontWeight: "bold" }}>
                Totals
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div
                style={{ width: "19%", cursor: "pointer" }}
                onClick={() => handleOpen("cashCollect")}
              >
                <CardCommon
                  backgroundColor="entity_background"
                  style={{
                    height: "100px",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    <Typography variant={"body2"}>CASH COLLECT</Typography>
                    <Typography
                      variant="h5"
                      style={{ fontWeight: "bold", marginTop: "8px" }}
                    >
                      {`£ ${parseFloat(totalBox?.cashCollect).toFixed(2)}`}
                    </Typography>
                  </div>
                </CardCommon>
              </div>

              <div
                style={{ width: "19%", cursor: "pointer" }}
                onClick={() => handleOpen("deliveryWage")}
              >
                <CardCommon
                  backgroundColor="entity_background"
                  style={{
                    height: "100px",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    <Typography variant={"body2"}>DELIVERY WAGE</Typography>
                    <Typography
                      variant="h5"
                      style={{ fontWeight: "bold", marginTop: "8px" }}
                    >
                      {`£ ${parseFloat(totalBox?.deliveryWage).toFixed(2)}`}
                    </Typography>
                  </div>
                </CardCommon>
              </div>
              <div
                style={{ width: "19%", cursor: "pointer" }}
                onClick={() => handleOpen("dailyWage")}
              >
                <CardCommon
                  backgroundColor="entity_background"
                  style={{
                    height: "100px",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    <Typography variant={"body2"}>DAILY WAGE</Typography>
                    <Typography
                      variant="h5"
                      style={{ fontWeight: "bold", marginTop: "8px" }}
                    >
                      <div>{`£ ${totalBox.dailyWage?.toFixed(2)}`}</div>
                      <div style={{ fontSize: "16px", fontWeight: "normal" }}>
                        {parseFloat(totalBox.adjustment) > 0 &&
                          `Adjustment: ${parseFloat(
                            totalBox.adjustment,
                          )?.toFixed(2)}`}
                      </div>
                    </Typography>
                  </div>
                </CardCommon>
              </div>
              <div
                style={{ width: "19%", cursor: "pointer" }}
                onClick={() => handleOpen("totalWage")}
              >
                <CardCommon
                  backgroundColor="entity_background"
                  style={{
                    height: "100px",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    <Typography variant={"body2"}>TOTAL WAGE</Typography>
                    <Typography
                      variant="h5"
                      style={{ fontWeight: "bold", marginTop: "8px" }}
                    >
                      {`£ ${parseFloat(totalBox.totalWage)?.toFixed(2)}`}
                    </Typography>
                  </div>
                </CardCommon>
              </div>
              <div
                style={{ width: "19%", cursor: "pointer" }}
                onClick={() => handleOpen("deliveryTotalWage")}
              >
                <CardCommon
                  backgroundColor="entity_background"
                  style={{
                    height: "100px",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid container>
                    <Grid item xs={4}>
                      <div>
                        <Typography variant={"body2"}>ORDERS</Typography>
                        <Typography
                          variant="h5"
                          style={{ fontWeight: "bold", marginTop: "8px" }}
                        >
                          {totalBox.totalOrders}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div>
                        <Typography variant={"body2"}>RE-DROPS</Typography>
                        <Typography
                          variant="h5"
                          style={{ fontWeight: "bold", marginTop: "8px" }}
                        >
                          {totalBox.totalReDrops}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div>
                        <Typography variant={"body2"}>TOTAL</Typography>
                        <Typography
                          variant="h5"
                          style={{ fontWeight: "bold", marginTop: "8px" }}
                        >
                          {totalBox.totalOrders + totalBox.totalReDrops}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </CardCommon>{" "}
              </div>
            </Grid>
          </Grid>
        )}
        {nodes &&
          nodes.map(
            (driverDetails: any, index: any) =>
              driverDetails?.orders?.length > 0 &&
              driverDetails.cmsDriverName !== "UNASSIGNED" &&
              driverDetails.cmsDriverName !== "ALL" && (
                <DriverSessionBody
                  driverDetails={driverDetails}
                  ordersLength={totalOrders}
                  index={index + 1}
                />
              ),
          )}
      </Grid>
      <TotalModal
        open={isOpenModal}
        setOpen={setIsOpenModal}
        driverDetails={nodes}
        totalModalName={totalModalName}
        totalOrders={totalOrders}
        totalBox={totalBox}
      />
    </>
  );
};

export default WithLoading(DriverSessionsHeader);
