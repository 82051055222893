import React, { useState } from "react";
import {
  createStyles,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import _ from "lodash";

import CardCommon from "../../../../components/card/CardCommon";
import VoidTransactionInfoNode from "./VoidTransactionInfoNode";
import SortingMethod from "../../../../components/common/SortingMethod/SortingMethod";
import LocationDetailsCard from "../../../../components/common/LocationDetailsCard";
import VoidTransactionDetailsModal from "./VoidTransactionDetailsModal";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    headerContainerStyle: {
      [theme.breakpoints.down("sm")]: {
        visibility: "hidden",
      },
    },
    headerFirstItemStyle: {
      display: "flex",
      justifyContent: "flex-start",
      placeItems: "center",
      paddingLeft: "9px",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: "5px",
      },
    },
    headerItemStyle: {
      display: "flex",
      justifyContent: "center",
      placeItems: "center",
    },
    headerLastItemStyle: {
      display: "flex",
      justifyContent: "end",
      placeItems: "center",
      paddingRight: "16px",
    },
  }),
);

export interface VoidTransactionHeaderProps {
  nodeData: any;
  handleChangeOrderListSorting: any;
  handleRemoveOrderListSorting: any;
  disableButton: any;
  locationDetailsList: any;
  userList: any;
  locationSelectedList: any;
  handleOnClickText: any;
  isSort: any;
  headerName: any;
  sortingMethod: any;
}

/**
 * VoidTransactionHeader Component:
 *
 * This component represents the header section of the Void Transaction list.
 * It includes sorting options for various columns like User, Location, Date, and Amount.
 * The component also renders individual VoidTransactionInfoNode components for each void transaction.
 */
const VoidTransactionHeader: React.FunctionComponent<
  VoidTransactionHeaderProps
> = ({
  nodeData,
  handleChangeOrderListSorting,
  handleRemoveOrderListSorting,
  disableButton,
  locationDetailsList,
  userList,
  locationSelectedList,
  handleOnClickText,
  isSort,
  headerName,
  sortingMethod,
}) => {
  const [
    isOpenVoidTransactionDetailsModal,
    setIsOpenVoidTransactionDetailsModal,
  ] = useState(false);
  const [extraInfo, setExtraInfo] = useState<any>([]);
  const [selectedData, setSelectedData] = useState<any>([]);

  const handleOpenVoidDetailsModal = (extraInfo: any, nodeData: any) => {
    setExtraInfo(extraInfo);
    setSelectedData(nodeData);
    setIsOpenVoidTransactionDetailsModal(true);
  };

  const maxWidth = useMediaQuery("(max-width: 960px)");
  const maxWidthLocationChips = useMediaQuery("(max-width: 600px)");
  const classes = useStyles();
  return (
    <>
      <Grid
        container
        style={{
          marginBottom: "20px",
          marginTop: "8px",
        }}
      >
        <Grid item xs={12}>
          <div
            style={
              maxWidthLocationChips
                ? {
                    display: "block",
                  }
                : {
                    display: "flex",
                  }
            }
          >
            <LocationDetailsCard
              locationSelectedList={locationSelectedList}
              handleOnClickText={handleOnClickText}
            />
          </div>
        </Grid>
      </Grid>
      {!maxWidth && (
        <div className={classes.headerContainerStyle}>
          <CardCommon backgroundColor={"table_header_background"}>
            <Grid container style={{ minHeight: "36px" }}>
              <Grid item xs={3} className={classes.headerFirstItemStyle}>
                <SortingMethod
                  handleChangeOrderListSorting={handleChangeOrderListSorting}
                  handleRemoveOrderListSorting={handleRemoveOrderListSorting}
                  headerName={"User"}
                  headerId={"userId"}
                  disableButton={disableButton}
                  color={"orange"}
                  isSort={headerName === "userId" ? isSort : false}
                  sortingMethod={headerName === "userId" ? sortingMethod : ""}
                />
              </Grid>
              <Grid item xs={3} className={classes.headerItemStyle}>
                <SortingMethod
                  handleChangeOrderListSorting={handleChangeOrderListSorting}
                  handleRemoveOrderListSorting={handleRemoveOrderListSorting}
                  headerName={"Location"}
                  headerId={"locationId"}
                  disableButton={disableButton}
                  color={"orange"}
                  isSort={headerName === "locationId" ? isSort : false}
                  sortingMethod={
                    headerName === "locationId" ? sortingMethod : ""
                  }
                />
              </Grid>
              <Grid item xs={2} className={classes.headerItemStyle}>
                <SortingMethod
                  handleChangeOrderListSorting={handleChangeOrderListSorting}
                  handleRemoveOrderListSorting={handleRemoveOrderListSorting}
                  headerName={"Date"}
                  headerId={"date"}
                  disableButton={disableButton}
                  color={"orange"}
                  isSort={headerName === "date" ? isSort : false}
                  sortingMethod={headerName === "date" ? sortingMethod : ""}
                />
              </Grid>
              <Grid item xs={2} className={classes.headerItemStyle}>
                <SortingMethod
                  handleChangeOrderListSorting={handleChangeOrderListSorting}
                  handleRemoveOrderListSorting={handleRemoveOrderListSorting}
                  headerName={"Amount"}
                  headerId={"amount"}
                  disableButton={disableButton}
                  color={"orange"}
                  isSort={headerName === "amount" ? isSort : false}
                  sortingMethod={headerName === "amount" ? sortingMethod : ""}
                />
              </Grid>
              <Grid item xs={2} className={classes.headerLastItemStyle}>
                <SortingMethod
                  handleChangeOrderListSorting={handleChangeOrderListSorting}
                  handleRemoveOrderListSorting={handleRemoveOrderListSorting}
                  headerName={"Comments"}
                  headerId={""}
                  disableButton={true}
                  color={"orange"}
                  isSort={headerName === "date" ? isSort : false}
                  sortingMethod={headerName === "date" ? sortingMethod : ""}
                />
              </Grid>
            </Grid>
          </CardCommon>
        </div>
      )}
      <Grid container style={{ marginTop: "1px" }}>
        {!_.isEmpty(nodeData) ? (
          nodeData.map((nodeData: any) => (
            <Grid
              item
              xs={12}
              key={nodeData.id}
              style={{ placeItems: "center", marginTop: "4px" }}
            >
              <VoidTransactionInfoNode
                nodeData={nodeData}
                locationDetailsList={locationDetailsList}
                userList={userList}
                handleOpenVoidDetailsModal={handleOpenVoidDetailsModal}
              />
            </Grid>
          ))
        ) : (
          <div style={{ width: "100%", margin: "auto", marginTop: "5%" }}>
            <Typography variant="h4" align="center">
              No voidTransaction available.
            </Typography>
          </div>
        )}
      </Grid>

      <VoidTransactionDetailsModal
        isOpen={isOpenVoidTransactionDetailsModal}
        setIsOpen={setIsOpenVoidTransactionDetailsModal}
        extraInfo={extraInfo}
        selectedData={selectedData}
        userList={userList}
        locationDetailsList={locationDetailsList}
      />
    </>
  );
};

export default VoidTransactionHeader;
