import React, { useState } from "react";
import {
  Button,
  Grid,
  Hidden,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import _ from "lodash";
import EditIcon from "@material-ui/icons/Edit";
import { useRouteMatch } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { AxiosError } from "axios";

import {
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  ERROR_MESSAGE_UPDATING_ERROR,
  SUCCESSFULLY_CREATED,
  SUCCESSFULLY_DELETED,
  SUCCESSFULLY_UPDATED,
} from "../../../../utils/consts";
import CardCommon from "../../../../components/card/CardCommon";
import ScheduleEditModal, {
  generateDailyTimeNodeUsingMinutes,
  getDateObject,
} from "./ScheduleEditModal";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import { buttonColors, HttpStatus } from "../../../../utils/enum";
import ScheduleCreateModal from "./ScheduleCreateModal";
import {
  createNewScheduledTask,
  fetchAllScheduledTask,
  removeScheduledTask,
  updateScheduledTask,
} from "../../../../services/locationApp/scheduledTaskConfiguration";
import ScheduleRemoveModal from "./ScheduleRemoveModal";
import { CustomTheme } from "../../../../types/customTheme";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";

export interface StockConfigurationNodeProps {
  scheduleTaskNode: any;
  setScheduleTaskNode: any;
}

const daysObj: any = {
  0: "-",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
  7: "Sunday",
};

/**
 * ScheduleTaskConfigurationNode Component
 *
 * This component is responsible for managing and displaying scheduled tasks within an application.
 * It provides functionalities to add, edit, delete, and view details of scheduled tasks.
 *
 * Props:
 * - `scheduleTaskNode`: Array of scheduled tasks to display.
 * - `setScheduleTaskNode`: Function to update the `scheduleTaskNode` state.
 *
 * States:
 * - `isOpenDeleteModal`: Boolean to control the visibility of the delete modal.
 * - `isLoading`: Boolean to indicate whether an action (create, update, delete) is in progress.
 * - `scheduledId`: The ID of the scheduled task to be deleted.
 * - `error`: Error message to display in case of an error.
 * - `isOpenEditModal`: Boolean to control the visibility of the edit modal.
 * - `isOpenCreateModal`: Boolean to control the visibility of the create modal.
 * - `editScheduleDetails`: The details of the schedule being edited.
 * - `success`: Success message to display after a successful operation.
 *
 * Methods:
 * - `handleCreateNewSchedule(formData)`: Creates a new scheduled task by calling `createNewScheduledTask` service function.
 * - `handleGetScheduleTask(data)`: Fetches the schedule details from the server before updating if a conflict occurs.
 * - `handleUpdateSchedule(data, version)`: Updates an existing scheduled task with new data. Handles conflict resolution if necessary.
 * - `handleOpenDeleteModal(id)`: Sets the ID for the scheduled task to delete and opens the delete modal.
 * - `handleDelete()`: Deletes a scheduled task based on the `scheduledId`.
 * - `handelConvertUpperCase(str)`: Converts the string to a more readable format by capitalizing each word.
 * - `handleEdit(item)`: Opens the edit modal with the selected scheduled task's details.
 * - `handleOpenCreateModal()`: Opens the create modal for adding a new scheduled task.
 * - `handleEmail(item)`: Formats the email recipients from the task data.
 * - `handleTooltip(item)`: Prepares a tooltip text for tasks with a specific type.
 *
 * Rendering:
 * - Renders the header and the list of scheduled tasks using Material-UI components.
 * - Displays error and success messages using `DefaultAlert` components.
 * - Modals for adding, editing, and deleting scheduled tasks are displayed conditionally based on the corresponding state.
 *
 * Usage:
 * - The component can be integrated into a larger page or view within the application where scheduled tasks need to be managed.
 * - The component interacts with the backend through API calls for CRUD operations on scheduled tasks.
 */
const ScheduleTaskConfigurationNode: React.FunctionComponent<
  StockConfigurationNodeProps
> = ({ scheduleTaskNode, setScheduleTaskNode }) => {
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [scheduledId, setScheduledId] = useState("");
  const [error, setError] = useState("");
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [editScheduleDetails, setEditScheduleDetails] = useState<any>({});
  const [success, setSuccess] = useState("");
  const match: any = useRouteMatch();

  /**
   * handleCreateNewSchedule - Function to handle the creation of a new scheduled task.
   *
   * @param {any} formData - The form data submitted by the user for creating a new scheduled task.
   */
  const handleCreateNewSchedule = async (formData: any) => {
    try {
      // Set loading state to true to indicate that an operation is in progress.
      setIsLoading(true);

      // Make an API call to create a new scheduled task with the provided location ID and form data.
      const res = await createNewScheduledTask(
        match.params.locationId,
        formData,
      );

      // Extract the response data from the API call.
      const responseData = res.data.data;

      // Serialize the nested data object to a JSON string for consistency.
      responseData.data = JSON.stringify(res.data.data.data);

      // Clone the current scheduleTaskNode to avoid mutating the state directly.
      const cloneScheduleTaskNode = _.cloneDeep(scheduleTaskNode);

      // Add the newly created task to the cloned scheduleTaskNode array.
      cloneScheduleTaskNode.push(responseData);

      // Update the scheduleTaskNode state with the new cloned array.
      setScheduleTaskNode(cloneScheduleTaskNode);

      // Set a success message to inform the user that the task was created successfully.
      setSuccess(SUCCESSFULLY_CREATED);

      // Turn off the loading state as the operation is now complete.
      setIsLoading(false);

      // Close the modal used for creating a new scheduled task.
      setIsOpenCreateModal(false);
    } catch (error) {
      // In case of an error, turn off the loading state.
      setIsLoading(false);

      // Set an error message to inform the user about the unexpected issue.
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Fetches and updates a scheduled task.
   *
   * @param {any} data - The updated data for the scheduled task.
   */
  const handleGetScheduleTask = async (data: any) => {
    try {
      // Fetch all scheduled tasks for the current location ID.
      const res = await fetchAllScheduledTask(match.params.locationId);

      // Filter the fetched scheduled tasks to find the task that matches the ID of the task being edited.
      const filterNode = res?.data?.filter(
        (data: any) => data.id === editScheduleDetails.id,
      );

      // Call the function to update the schedule using the provided data and the version of the matching task.
      handleUpdateSchedule(data, filterNode[0].version);
    } catch (error) {
      // Set an error message to inform the user of an issue while updating.
      setError(ERROR_MESSAGE_UPDATING_ERROR);

      // Ensure that the loading state is turned off if an error occurs.
      setIsLoading(false);
    }
  };

  /**
   * Updates a scheduled task with the provided data and optional version.
   *
   * @param {any} data - The updated data for the scheduled task.
   * @param {any} [version] - Optional version of the task for handling conflicts.
   */
  const handleUpdateSchedule = async (taskData: any, version?: any) => {
    try {
      // Set loading state to indicate an operation is in progress.
      setIsLoading(true);

      // Attach version if provided to handle conflict resolution.
      if (version) {
        taskData.version = version;
      }

      // Make API call to update the scheduled task.
      const res = await updateScheduledTask(match.params.locationId, taskData);

      // Check for conflict response from API.
      if (res.data.status === HttpStatus.CONFLICT_409) {
        // Resolve conflict by fetching updated data.
        handleGetScheduleTask(taskData);
      } else {
        // Parse the response and prepare the updated task data.
        const updatedTask = {
          ...res.data,
          data: JSON.stringify(res.data.data),
        };

        // Update the schedule task list in the state.
        const updatedScheduleTaskNode = scheduleTaskNode.map((item: any) =>
          item.id === editScheduleDetails.id
            ? { ...item, ...updatedTask }
            : item,
        );

        // Set success message and close edit modal.
        setSuccess(SUCCESSFULLY_UPDATED);
        setScheduleTaskNode(updatedScheduleTaskNode);
        setIsOpenEditModal(false);
      }
    } catch (error) {
      console.error("Error updating schedule:", error);

      // Handle specific error cases based on HTTP status or API response.
      const err = error as AxiosError;
      if (err.response?.status === HttpStatus.CONFLICT_409) {
        await handleGetScheduleTask(taskData); // Resolve conflict if detected.
      } else {
        setError(ERROR_MESSAGE_UNEXPECTED_ERROR); // Generic error message.
      }
    } finally {
      // Ensure loading state is cleared after completion or error.
      setIsLoading(false);
    }
  };

  /**
   * Opens the delete modal for a specific scheduled task.
   */
  const handleOpenDeleteModal = (taskId: any) => {
    // Set the ID of the task to be deleted.
    setScheduledId(taskId);

    // Open the delete modal.
    setIsOpenDeleteModal(true);
  };

  /**
   * Handles the deletion of a scheduled task.
   * Removes the task from the server and updates the UI.
   */
  const handleDelete = async () => {
    if (!scheduledId) {
      setError("No scheduled task selected for deletion.");
      return;
    }

    try {
      // Indicate the loading state to the user.
      setIsLoading(true);

      // Make an API call to delete the task by its ID.
      await removeScheduledTask(match.params.locationId, scheduledId);

      // Notify the user of successful deletion.
      setSuccess(SUCCESSFULLY_DELETED);

      // Filter out the deleted task from the local state.
      setScheduleTaskNode((prevTaskNode: any[]) =>
        prevTaskNode.filter((task: any) => task.id !== scheduledId),
      );

      // Close the delete modal after successful operation.
      setIsOpenDeleteModal(false);
    } catch (error) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    } finally {
      // Reset the loading state after the operation completes.
      setIsLoading(false);
    }
  };

  /**
   * Converts a kebab-case or lowercase string to Title Case.
   * Each word's first letter is capitalized, and words are separated by spaces.
   *
   * @param {string} str - The input string to convert.
   * @returns {string} - The converted string in Title Case.
   */
  const handelConvertUpperCase = (str: any): string => {
    return str
      .toString()
      .toLowerCase()
      .split("-")
      .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  /**
   * Handles the process of opening the edit modal and setting the selected item's details.
   *
   * @param {Record<string, any>} item - The item to edit, containing details to populate the edit form.
   */
  const handleEdit = (item: Record<string, any>): void => {
    setEditScheduleDetails(item); // Set the item details for editing.
    setIsOpenEditModal(true); // Open the edit modal.
  };

  /**
   * Opens the create modal by updating the state.
   */
  const handleOpenCreateModal = () => {
    setIsOpenCreateModal(true);
  };

  /**
   * Processes the 'item' object to format email recipients based on its type.
   *
   * @param {any} item - The item containing type and data fields.
   * @returns {string} - A formatted string of email recipients or "-" if no recipients.
   */
  const handleEmail = (item: any): string => {
    // Destructure the 'type' and 'data' fields from the 'item' object.
    const { type, data } = item;

    // Check if the type is 'daily-summary-email'.
    if (type === "daily-summary-email") {
      try {
        // Attempt to parse the 'data' field as JSON.
        const jsonParseData = JSON.parse(data);

        // Extract the 'recipients' array from the parsed JSON.
        const { recipients } = jsonParseData;

        // Check if 'recipients' is an array and has items.
        if (Array.isArray(recipients) && recipients.length > 0) {
          // If there are multiple recipients, format the output to show the first recipient and the count of additional recipients.
          return recipients.length > 1
            ? `${recipients[0]} + ${recipients.length - 1} more`
            : recipients[0];
        }
      } catch (error) {
        // If parsing fails, log an error message for debugging purposes.
        console.error("Error parsing recipients data:", error);
      }
    }

    // If the type is not 'daily-summary-email' or there are no valid recipients, return "-".
    return "-";
  };

/**
 * Processes the 'item' object to generate a tooltip based on its type and data.
 * 
 * @param {any} item - The item containing type and data fields.
 * @returns {string} - A formatted string suitable for a tooltip, or a default message if no valid data.
 */
const handleTooltip = (item: any): string => {
  // Destructure the 'type' and 'data' fields from the 'item' object.
    const { type, data } = item;

    if (type === "daily-summary-email") {
      try {
        const jsonParseData = JSON.parse(data);
        const { recipients } = jsonParseData;
        if (Array.isArray(recipients) && recipients.length > 0) {
          if (recipients.length > 1) {
            return recipients.join("\n");
          } else {
            return recipients[0];
          }
        }
      } catch (error) {
        return "Invalid data format";
      }
    }

    return "No tooltip available";
  };

  const theme: CustomTheme = useTheme();
  const tabletScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Grid container>
        <Hidden xsDown>
          <Grid
            item
            xs={12}
            style={{
              marginBottom: "12px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h4"
              style={{ fontWeight: "bold" }}
              color="secondary"
              align="left"
            >
              Scheduled Task
            </Typography>

            <ButtonCommon
              style={{
                marginRight: 4,
                width: "240px",
              }}
              variant="contained"
              color={buttonColors.CREATE_BUTTON_COLOR}
              onClick={handleOpenCreateModal}
            >
              <Typography style={{ textTransform: "none", color: "white" }}>
                Add New Scheduled Task
              </Typography>
            </ButtonCommon>
          </Grid>
        </Hidden>

        <Hidden smUp>
          <Grid
            item
            xs={12}
            style={{
              marginBottom: "12px",
              display: "flex",
              justifyContent: "start",
            }}
          >
            <Typography
              variant="h4"
              style={{ fontWeight: "bold" }}
              color="secondary"
              align="left"
            >
              Scheduled Task
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              marginBottom: "12px",
              display: "flex",
              justifyContent: "start",
            }}
          >
            <ButtonCommon
              style={{
                marginRight: 4,
                width: "240px",
              }}
              variant="contained"
              color={buttonColors.CREATE_BUTTON_COLOR}
              onClick={handleOpenCreateModal}
            >
              <Typography style={{ textTransform: "none", color: "white" }}>
                Add New Scheduled Task
              </Typography>
            </ButtonCommon>
          </Grid>
        </Hidden>
      </Grid>
      <Hidden smDown>
        <CardCommon backgroundColor={"table_header_background"}>
          <Grid container style={{ minHeight: "36px", placeItems: "center" }}>
            {/* Location Header */}
            <Grid item xs={2} style={{ paddingLeft: "12px" }}>
              <Typography align="left">Execution Type</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography>Execute Time</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography>Execution Day</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography>Executed</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>Info</Typography>
            </Grid>
            <Grid item xs={3} style={{ paddingRight: "110px" }}>
              <Typography align="right">Type</Typography>
            </Grid>
          </Grid>
        </CardCommon>
      </Hidden>
      <Grid container>
        {!_.isEmpty(scheduleTaskNode) ? (
          scheduleTaskNode.map((item: any, index: number) => (
            <Grid item xs={12} style={{ marginTop: "8px" }}>
              <CardCommon
                backgroundColor={
                  index % 2 === 0
                    ? "entity_background"
                    : "entity_highlight_background"
                }
              >
                <Grid
                  container
                  style={{ minHeight: "36px", placeItems: "center" }}
                >
                  <Grid
                    item
                    xs={12}
                    md={2}
                    style={{
                      paddingLeft: "12px",
                      marginTop: tabletScreen ? "8px" : "0px",
                    }}
                  >
                    <Typography align={tabletScreen ? "center" : "left"}>
                      {handelConvertUpperCase(item.executionType)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <Typography>
                      {
                        generateDailyTimeNodeUsingMinutes(item.executeTime)
                          ?.name
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Typography>
                      {item.executionType === "daily" ||
                      item.executionType === "weekly"
                        ? daysObj[item.executionDay]
                        : getDateObject(item.executionDay)?.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <Typography style={{ marginTop: "4px" }}>
                      {item.status ? (
                        <CheckCircleIcon
                          style={{
                            color: theme.palette.custom.green.dark,
                          }}
                        />
                      ) : (
                        <CloseIcon
                          style={{
                            color: theme.palette.custom.red.dark,
                          }}
                        />
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Tooltip
                      title={
                        <div style={{ whiteSpace: "pre-line" }}>
                          {handleTooltip(item)}
                        </div>
                      }
                    >
                      <Typography>{handleEmail(item)}</Typography>
                    </Tooltip>
                  </Grid>
                  <Hidden smDown>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      style={{
                        paddingRight: "12px",
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                      }}
                    >
                      <Typography align="right">
                        {handelConvertUpperCase(item.type)}
                      </Typography>
                      <Button
                        style={{
                          minWidth: "40px",
                          maxWidth: "40px",
                          marginLeft: "8px",
                        }}
                        onClick={() => handleEdit(item)}
                      >
                        <EditIcon />
                      </Button>
                      <Button
                        style={{
                          minWidth: "40px",
                          maxWidth: "40px",
                          marginLeft: "8px",
                        }}
                        onClick={() => handleOpenDeleteModal(item.id)}
                      >
                        <DeleteIcon />
                      </Button>
                    </Grid>
                  </Hidden>
                  <Hidden mdUp>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography align="center">
                        {handelConvertUpperCase(item.type)}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "8px",
                      }}
                    >
                      <Button
                        style={{
                          minWidth: "40px",
                          maxWidth: "40px",
                          marginLeft: "8px",
                        }}
                        onClick={() => handleEdit(item)}
                      >
                        <EditIcon />
                      </Button>
                      <Button
                        style={{
                          minWidth: "40px",
                          maxWidth: "40px",
                          marginLeft: "8px",
                        }}
                        onClick={() => handleOpenDeleteModal(item.id)}
                      >
                        <DeleteIcon />
                      </Button>
                    </Grid>
                  </Hidden>
                </Grid>
              </CardCommon>
            </Grid>
          ))
        ) : (
          <Grid container>
            <Grid item xs={12} style={{ marginTop: "8%" }}>
              <Typography align="center" variant="h4">
                No Scheduled Task Available
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>

      <ScheduleEditModal
        isOpen={isOpenEditModal}
        setIsOpen={setIsOpenEditModal}
        isLoading={isLoading}
        editScheduleDetails={editScheduleDetails}
        handleUpdateSchedule={handleUpdateSchedule}
      />

      <ScheduleCreateModal
        isOpen={isOpenCreateModal}
        setIsOpen={setIsOpenCreateModal}
        isLoading={isLoading}
        handleCreateNewSchedule={handleCreateNewSchedule}
      />

      <ScheduleRemoveModal
        isOpen={isOpenDeleteModal}
        setIsOpen={setIsOpenDeleteModal}
        handleRemoveSchedule={handleDelete}
        isLoading={isLoading}
      />

      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />

      <DefaultAlert
        open={!!success}
        handleClose={() => setSuccess("")}
        message={success}
        severity={"success"}
      />
    </>
  );
};

// Apply the WithLoading higher-order component to the ScheduleTaskConfigurationNode component
export default ScheduleTaskConfigurationNode;
