import React, { useEffect, useState } from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import _, { split } from "lodash";

import DialogCommonDefault from "../../../../components/dialogs/DialogCommonDefault";
import { buttonColors } from "../../../../utils/enum";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import IncrementDecrementTextboxNormal from "../../../../components/common/IncrementDecrementTextbox/IncrementDecrementTextboxNoraml";
import VoidLine from "./VoidLine";
import VoidOut from "./VoidOut";

export interface VoidTransactionDetailsModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  extraInfo: any;
  selectedData: any;
  userList: any;
  locationDetailsList: any;
}

const VoidTransactionDetailsModal: React.FC<
  VoidTransactionDetailsModalProps
> = ({
  isOpen,
  setIsOpen,
  extraInfo,
  selectedData,
  userList,
  locationDetailsList,
}) => {
  return (
    <DialogCommonDefault
      open={isOpen}
      setOpen={setIsOpen}
      isNeedFixedHeight={false}
      backgroundColor="entity_background"
      maxWidth="md"
    >
      {Object.values(extraInfo).length > 0 ? (
        extraInfo.type === "void-line" ? (
          <VoidLine
            extraInfo={extraInfo}
            nodeData={selectedData}
            userList={userList}
            locationDetailsList={locationDetailsList}
          />
        ) : (
          <VoidOut
            extraInfo={extraInfo}
            userList
            nodeData={{
              ...extraInfo.data,
              ...selectedData,
              comments: extraInfo?.comments ? extraInfo.comments : "",
            }}
            locationDetailsList={locationDetailsList}
          />
        )
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "20px 0px",
          }}
        >
          <Typography variant="h4">No Void Details Available.</Typography>
        </div>
      )}
      <DialogActions style={{ marginBottom: "12px", marginTop: "12px" }}>
        <ButtonCommon
          onClick={() => setIsOpen(false)}
          variant="contained"
          style={{ fontSize: 11, width: "120px", marginRight: "16px" }}
          color={buttonColors.CANCEL_BUTTON_COLOR}
        >
          Cancel
        </ButtonCommon>

        {/* <ButtonCommon
          disabled={stockLevelInitial === stockLevel || isLoadingToast}
          onClick={handleSaveStockLevel}
          variant="contained"
          style={{ fontSize: 11, width: "120px", marginRight: "14px" }}
          color={buttonColors.CREATE_BUTTON_COLOR}
        >
          Done
        </ButtonCommon> */}
      </DialogActions>
    </DialogCommonDefault>
  );
};

export default VoidTransactionDetailsModal;
