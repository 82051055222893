import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

import DialogCommonDefault from "../../../../components/dialogs/DialogCommonDefault";
import { buttonColors } from "../../../../utils/enum";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import ValidationMessage from "../../../../components/validation/ValidationMessage";
import TextfieldDefaultNew from "../../../../components/textField/TextfieldDefaultNew";

export interface StockLevelModalProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  handleCreate: (name: string) => void;
  isLoading: boolean;
}

const CreateNewChain: React.FC<StockLevelModalProps> = ({
  isOpen,
  setIsOpen,
  handleCreate,
  isLoading,
}) => {
  const [name, setName] = useState("");
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setName("");
      setIsError(false); // Reset error when modal opens
    }
  }, [isOpen]);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    if (e.target.value) setIsError(false);
  };

  const handleCreateNewChain = () => {
    if (!name.trim()) {
      setIsError(true);
    } else {
      setIsError(false);
      handleCreate(name.trim());
    }
  };

  return (
    <DialogCommonDefault
      open={isOpen}
      setOpen={setIsOpen}
      isNeedFixedHeight={false}
      backgroundColor="entity_background"
      maxWidth="xs"
    >
      <DialogTitle>Create a New Chain</DialogTitle>
      <DialogContent>
        <TextfieldDefaultNew
          id="name"
          name="name"
          style={{ width: "100%" }}
          label="Name"
          onChange={handleNameChange}
          value={name}
          type="text"
        />
        {isError && !name && <ValidationMessage message="Name is required" />}
      </DialogContent>
      <DialogActions style={{ marginBottom: "12px" }}>
        <ButtonCommon
          onClick={() => setIsOpen(false)}
          variant="contained"
          style={{ fontSize: 11, width: "120px", marginRight: "12px" }}
          color={buttonColors.CANCEL_BUTTON_COLOR}
          disabled={isLoading}
        >
          Cancel
        </ButtonCommon>
        <ButtonCommon
          onClick={handleCreateNewChain}
          variant="contained"
          style={{ fontSize: 11, width: "120px", marginRight: "14px" }}
          color={buttonColors.CREATE_BUTTON_COLOR}
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress size={20} color="secondary" />
          ) : (
            "Create"
          )}
        </ButtonCommon>
      </DialogActions>
    </DialogCommonDefault>
  );
};

export default CreateNewChain;
