import { createStyles, makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import * as React from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { CustomTheme } from "../../types/customTheme";

interface Props {
  severity: any;
}

/* Change the header color of warning messages. */
const getColorBySeverity = (theme: CustomTheme, severity: any) => {
  switch (severity) {
    case "error":
      return theme.palette.error.dark;
      break;
    case "warning":
      return theme.palette.warning.dark;
      break;
    case "info":
      return theme.palette.info.dark;
      break;
    case "success":
      return theme.palette.success.dark;
      break;
    case "secondary":
      return theme.palette.secondary.main;
      break;
    default:
      break;
  }
}; 
const getFontColor = (severity: any) => {
  switch (severity) {
    case "error":
      return "white";
      break;
    case "warning":
      return "white";
      break;
    case "info":
      return "white";
      break;
    case "success":
      return "white";
      break;
    case "secondary":
      return "black";
      break;
    default:
      break;
  }
};

const useStyles = makeStyles<any, Props>((theme: CustomTheme) =>
  createStyles({
    alertHeader: {
      height: "45px",
      paddingTop: "5px",
      color: "#ffffff",
      backgroundColor: ({ severity }) => getColorBySeverity(theme, severity),
    },
    titleStyle: {
      color: ({ severity }) => getFontColor(severity),
    },
    alertDescription: {
      paddingTop: "15px",
    },
    cancelLabelStyle: {
      color: theme.palette.text.primary
    }
  }),
);

export interface DialogCommonProps {
  open: boolean;
  confirmAction?: any;
  title: string;
  desc: string;
  disableBackdropClick: boolean;
  confirmLabel: string;
  severity: "warning" | "info" | "error" | "success" | "secondary";
  color?: "primary" | "secondary";
  cancelLabel?: any;
  cancelAction?: any;
}

/* Show the warning, success, error, and information notification using modal. */
const AlertDialog: React.FunctionComponent<DialogCommonProps> = ({
  open,
  confirmAction,
  title,
  desc,
  confirmLabel,
  severity,
  disableBackdropClick,
  color = "primary",
  cancelLabel,
  cancelAction,
}) => {
  const classes = useStyles({ severity });

  return (
    <Dialog
      disableBackdropClick={disableBackdropClick}
      className={classes.root}
      open={open}
    >
      <DialogTitle className={classes.alertHeader} id="alert-dialog-title-id">
        <Typography variant="h6" className={classes.titleStyle}>
        {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          className={classes.alertDescription}
          id="alert-dialog-description-id"
        >
          {desc}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {cancelLabel && (
          <Button onClick={cancelAction} className={classes.cancelLabelStyle}>
            {cancelLabel}
          </Button>
        )}
        <Button onClick={confirmAction}  color={color}>
          {confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
