import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  FormControlLabel,
  Typography,
} from "@material-ui/core";

import { buttonColors } from "../../../../utils/enum";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import ValidationMessage from "../../../../components/validation/ValidationMessage";
import CardCommon from "../../../../components/card/CardCommon";
import TextfieldDefault from "../../../../components/textField/TextfieldDefault";
import SwitchCommon from "../../../../components/switches/SwitchCommon";
import { useRouteMatch } from "react-router-dom";

export interface StockLevelModalProps {
  handleUpdate: any;
  isLoading: any;
  currentChain: any;
  setIsOpenAlert: any;
}

const ChainDetails: React.FC<StockLevelModalProps> = ({
  handleUpdate,
  isLoading,
  currentChain,
  setIsOpenAlert,
}) => {
  const [name, setName] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [nameInitial, setNameInitial] = useState("");
  const [isActiveInitial, setIsActiveInitial] = useState(false);
  const [isError, setIsError] = useState(false);
  const match: any = useRouteMatch();

  useEffect(() => {
    if (currentChain) {
      setName(currentChain.name);
      setNameInitial(currentChain.name);
      setIsActive(currentChain.status);
      setIsActiveInitial(currentChain.status);
    }
  }, [currentChain]);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    if (e.target.value) setIsError(false);
  };

  const handleCreateNewChain = () => {
    if (!name.trim()) {
      setIsError(true);
    } else {
      setIsError(false);
      handleUpdate({
        id: currentChain.id,
        name: name.trim(),
        status: isActive,
      });
    }
  };

  const handleActivate = () => {
    setIsActive(!isActive);
  };

  const handleReset = () => {
    setName(nameInitial);
    setIsActive(isActiveInitial);
  };

  return (
    <CardCommon backgroundColor={"entity_highlight_background"}>
      <div style={{ margin: "16px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            placeItems: "center",
          }}
        >
          <div>
            <TextfieldDefault
              id="name"
              name="name"
              style={{ width: "100%" }}
              label="Name"
              onChange={handleNameChange}
              value={name}
              disabled={
                currentChain?.["primary_location"] !== match.params.locationId
              }
              type="text"
            />
            {isError && !name && (
              <ValidationMessage message="Name is required" />
            )}
          </div>
          <FormControlLabel
            style={{ marginRight: "0px" }}
            control={<SwitchCommon />}
            checked={isActive}
            disabled={
              currentChain?.["primary_location"] !== match.params.locationId
            }
            onChange={handleActivate}
            label="Active"
            labelPlacement="top"
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "16px",
            marginBottom: "20px",
          }}
        >
          <ButtonCommon
            onClick={handleReset}
            variant="contained"
            style={{ fontSize: 11, width: "120px" }}
            color={buttonColors.CANCEL_BUTTON_COLOR}
            disabled={
              isLoading ||
              (isActive === isActiveInitial && name === nameInitial)
            }
          >
            Cancel
          </ButtonCommon>
          <ButtonCommon
            onClick={handleCreateNewChain}
            variant="contained"
            style={{ fontSize: 11, width: "120px" }}
            color={buttonColors.CREATE_BUTTON_COLOR}
            disabled={
              isLoading ||
              (isActive === isActiveInitial && name === nameInitial)
            }
          >
            {isLoading ? (
              <CircularProgress size={20} color="secondary" />
            ) : (
              "Update"
            )}
          </ButtonCommon>
          {currentChain?.["primary_location"] !== match.params.locationId && (
            <ButtonCommon
              onClick={() => setIsOpenAlert(true)}
              variant="contained"
              style={{
                fontSize: 11,
                textTransform: "none",
                width: "120px",
              }}
              disabled={
                currentChain?.["primary_location"] === match.params.locationId
              }
              color={buttonColors.DELETE_BUTTON_COLOR}
            >
              <Typography variant="h6" style={{ color: "white" }}>
                Un-link from the chain
              </Typography>
            </ButtonCommon>
          )}
        </div>
      </div>
    </CardCommon>
  );
};

export default ChainDetails;
