import { axiosClient } from "../client";
const queryString = require("query-string");

const API_SCHEDULED_TASK_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/taskSchedule`;

const API_SCHEDULED_TASK_DELETE = (locationId, id) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/taskSchedule/id/${id}`;

const SCHEDULED_TASK_UPDATE = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/taskSchedule`;

const SCHEDULED_TASK_CREATE = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_SALES}/v1/location/${locationId}/taskSchedule`;

export const removeScheduledTask = (locationId, id) =>
  axiosClient({
    method: "DELETE",
    url: queryString.stringifyUrl({
      url: API_SCHEDULED_TASK_DELETE(locationId, id),
    }),
  });

export const fetchAllScheduledTask = (locationId) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl({
      url: API_SCHEDULED_TASK_GET_ALL(locationId),
    }),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const updateScheduledTask = (locationId, data) => {
  return axiosClient({
    method: "PATCH",
    url: SCHEDULED_TASK_UPDATE(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
    data,
  });
};

export const createNewScheduledTask = (locationId, data) => {
  return axiosClient({
    method: "POST",
    url: SCHEDULED_TASK_CREATE(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
    data,
  });
};
