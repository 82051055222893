import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  Box,
  Chip,
  createStyles,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { getCookie } from "../../../../utils/cookies";
import {
  fetchAllSalesCancelItemInfo,
  fetchAllSalesItemInfo,
  fetchOtherPaymentInfo,
} from "../../../../services/salesApp/salesService";
import { OrderChannelDetailsObject } from "../../../../utils/consts/list";
import { CustomTheme } from "../../../../types/customTheme";
import { getFilterListFromArrayObject } from "../../../../utils/commonArrayMap";
import { Skeleton } from "@material-ui/lab";
import BlockIcon from "@material-ui/icons/Block";
import StorefrontIcon from "@material-ui/icons/Storefront";
import ReceiptOutlinedIcon from "@material-ui/icons/ReceiptOutlined";
import {
  convertDateTimeFormat,
  convertDateTimeFormatAndConvertTimeBold,
} from "../../../../utils/ConvertDateTimeFormat";
import CardCommon from "../../../../components/card/CardCommon";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    rightSideTopAlign: {
      marginTop: "0px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "12px",
      },
    },
    headerFirstItemStyle: {
      paddingLeft: "12px",
    },
    headerStyle: {
      display: "flex",
      justifyContent: "center",
    },
    headerLastItemStyle: {
      display: "flex",
      justifyContent: "end",
      paddingRight: "12px",
    },
    topAlign: {
      marginTop: "10px",
    },
    gridTopAlignRightSide: {
      marginTop: "12px",
    },
    style: {
      marginBottom: "16px",
      display: "flex",
    },
    gridTopAlign: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "10px",
    },
    vatHeaderStyle: {
      marginLeft: "18px",
      marginRight: "18px",
      height: "28px",
      marginBottom: "4px",
      display: "flex",
      placeItems: "center",
      justifyContent: "start",
      backgroundColor: theme.palette.custom.blue.main,
      borderRadius: "10px",
      border: `1px solid ${theme.palette.background.entity_border}`,
    },
    vatStyle: {
      color: theme.palette.custom.blue.contrastText,
    },
    vatBodyStyle: {
      marginTop: "4px",
      marginLeft: "18px",
      marginRight: "18px",
      height: "28px",
      marginBottom: "4px",
      display: "flex",
      placeItems: "center",
      borderRadius: "10px",
      border: `1px solid ${theme.palette.background.entity_border}`,
      backgroundColor: theme.palette.background.imgBackground,
      [theme.breakpoints.down("sm")]: {
        marginLeft: "16px",
        marginRight: "16px",
      },
    },
    locationNameStyle: {
      marginLeft: "4px",
      backgroundColor: theme.palette.custom.blue.main,
    },
    channelNameStyle: {
      marginLeft: "12px",
    },
    textColor: {
      color: theme.palette.custom.blue.contrastText,
    },
    dividerStyle: {
      marginTop: "12px",
      marginBottom: "16px",
      width: "100%",
      height: "2px",
      marginLeft: "20px",
      marginRight: "20px",
      backgroundColor: theme.palette.background.entity_highlight_background,
    },
    cancelBoxStyle: {
      backgroundColor: theme.palette.custom.red.main,
      width: "100%",
      display: "flex",
      height: "120px",
      borderRadius: "10px",
      border: `1px solid ${theme.palette.background.entity_border}`,
      marginRight: "12px",
      marginLeft: "2px",
    },
    mealDealStatusIsZeroAndOneStyleInFirstColumn: {
      marginTop: "12px",
    },
    firstSaleItemStyle: {
      marginTop: "4px",
    },
    mealDealStatusIsTwoStyle: {
      marginTop: "2px",
    },
    mealDealStatusIsZeroAndOneFontSizeStyle: {
      fontWeight: "bolder",
      fontSize: "14px",
    },
    mealDealStatusIsFontSizeStyle: {
      fontWeight: "normal",
      fontSize: "10px",
    },
    firstColumnMargin: {
      marginRight: "12px",
      [theme.breakpoints.down("sm")]: {
        marginRight: "20px",
      },
    },
  }),
);

export interface VoidLineProps {
  extraInfo: any;
  userList: any;
  nodeData: any;
  locationDetailsList: any;
}

const VoidOut: React.FC<VoidLineProps> = ({
  extraInfo,
  userList,
  nodeData,
  locationDetailsList,
}) => {
  const [customerDetails, setCustomerDetails] = useState<any>({});
  const [discountPercent, setDiscountPercent] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [saleVatList, setSaleVatList] = useState<any>([]);
  const [saleItemList, setSaleItemList] = useState<any>([]);
  const [otherPayment, setOtherPayment] = useState<any>([]);
  const [locationName, setLocationName] = useState("");
  const [saleCancelItemList, setSaleCancelItemList] = useState<any>([]);
  const idToken = getCookie("idToken");

  /* Get cancel sale information. */
  const getCancelSaleInfo = async (saleId: any) => {
    try {
      const res = await fetchAllSalesCancelItemInfo(
        idToken,
        nodeData.locationId,
        `saleId!(${saleId})`,
      );
      setSaleCancelItemList(res.data.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const locationDetails = getFilterListFromArrayObject(
      locationDetailsList,
      nodeData.locationId,
    );

    // Only if there is a detail of the location, A location name entered into a state.
    if (!_.isEmpty(locationDetails)) {
      const { label } = locationDetails[0];
      setLocationName(label);
    } else {
      // If there is no location name, the state will be changed to 'unknown location'.
      setLocationName("Unknown location");
    }
  }, []);

  /* vatPercent When there are equal objects, only one object is returned. */
  const handleSaleVatList = (data: any) => {
    const vatList = data.reduce((previousValue: any, currentValue: any) => {
      if (
        !previousValue.some(
          (prevValue: any) => prevValue.vatPercent === currentValue.vatPercent,
        )
      ) {
        previousValue.push(currentValue);
      }
      return previousValue;
    }, []);

    return vatList;
  };

  const handleGetSalesItems = async (saleId: any) => {
    const vatList = handleSaleVatList(nodeData.saleItems);
    setSaleVatList(vatList);
    setSaleItemList(nodeData.saleItems);
    setIsLoading(false);
  };
  /* Get other payments information */
  const handleGetOtherPayment = async (saleId: any) => {
    try {
      const res = await fetchOtherPaymentInfo(
        idToken,
        nodeData.locationId,
        `saleId!(${saleId})`,
      );
      setOtherPayment(res.data.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  /* Making API calls about sales details and other payments. */
  useEffect(() => {
    // Get the sale items details
    handleGetSalesItems(nodeData.id);

    /* Only if the other payment value is greater than 0, 
         make the API call that retrieves the other payment information. */
    if (nodeData.otherPayment > 0) {
      // Get the other payment details
      handleGetOtherPayment(nodeData.id);
    } else {
      setOtherPayment([]);
    }
  }, [nodeData]);

  /* Making API calls about sales details and other payments. */
  useEffect(() => {
    if (nodeData.cancelled) {
      // Get the sale details
      getCancelSaleInfo(nodeData.id);
    }
  }, [nodeData.id]);

  // Use the useEffect hook to run a function after rendering the component
  useEffect(() => {
    setDiscountPercent(nodeData.discountPercent);
    let customerObject: any = {};
    // Check if nodeData.customerObject is not empty
    if (!_.isEmpty(nodeData.customerObject)) {
      // If it's not empty, parse the JSON string and assign it to customerObject
      customerObject = JSON.parse(nodeData.customerObject);
      // Set the customer details state to customerObject
      setCustomerDetails(customerObject);
    }
  }, [otherPayment, nodeData]);

  /* Get vat using percentage */
  const getVat = (percent: any) => {
    let sum = 0;
    // Iterate over each item in the saleItemList array using the map method
    saleItemList.map((item: any) => {
      // Check if the item's vatPercent matches the percent parameter
      if (item.vatPercent === percent) {
        sum = sum + item.vatAmount;
      }
    });
    return sum;
  };

  /* Get incVat using percentage */
  const getIncVat = (percent: any) => {
    let sum = 0;
    // Iterate over each item in the saleItemList array using the map method
    saleItemList.map((item: any) => {
      // Check if the item's vatPercent matches the percent parameter
      if (item.vatPercent === percent) {
        sum = sum + item.amount;
      }
    });
    return sum;
  };

  /* Get user name using user id */
  const handleUserName = (userId: any) => {
    if (!_.isEmpty(userList) && !_.isEmpty(userList[userId])) {
      return userList[userId].label;
    } else {
      return userId;
    }
  };

  /* Create a orderChannel pin. */
  const handleOrderChannelPin = (nodeData: any) => {
    // Destructure the platform and orderChannel properties from the nodeData object
    const { platform, orderChannel } = nodeData;
    // If the platform is "online"
    if (platform === "online") {
      // Return a Chip component with a background color based on the orderChannel and a label with the channel name
      if (orderChannel === "wix") {
        return (
          <Chip
            className={classes.channelNameStyle}
            style={{
              backgroundColor: OrderChannelDetailsObject["eatpresto"].color,
            }}
            label={
              <Typography
                variant="body1"
                className={classes.textColor}
                align="left"
              >
                {OrderChannelDetailsObject["eatpresto"].channelName}
              </Typography>
            }
          />
        );
      } else if (!_.isEmpty(OrderChannelDetailsObject[orderChannel])) {
        return (
          <Chip
            className={classes.channelNameStyle}
            style={{
              backgroundColor: OrderChannelDetailsObject[orderChannel].color,
            }}
            label={
              <Typography
                variant="body1"
                className={classes.textColor}
                align="left"
              >
                {OrderChannelDetailsObject[orderChannel].channelName}
              </Typography>
            }
          />
        );
      } else {
        return (
          <Chip
            className={classes.channelNameStyle}
            style={{
              backgroundColor: OrderChannelDetailsObject["default"].color,
            }}
            label={
              <Typography
                variant="body1"
                className={classes.textColor}
                align="left"
              >
                {orderChannel}
              </Typography>
            }
          />
        );
      }
    } else {
      // If the platform is not "online", return a Chip component with a fixed background color and a label with "EPOS"
      return (
        <Chip
          className={classes.channelNameStyle}
          style={{ backgroundColor: "#F7931E" }}
          label={
            <Typography
              variant="body1"
              className={classes.textColor}
              align="left"
            >
              EPOS
            </Typography>
          }
        />
      );
    }
  };

  const classes = useStyles();

  /* Change the margin top of the sale item details using mealDealStatus. */
  const handleSaleItemTitleStyle = (
    previousObject: any,
    currentObject: any,
    index: number,
  ) => {
    if (
      (previousObject && previousObject.mealDealStatus === 0) ||
      (previousObject &&
        previousObject.mealDealStatus === 2 &&
        currentObject.mealDealStatus === 1) ||
      (previousObject &&
        previousObject.mealDealStatus === 2 &&
        currentObject.mealDealStatus === 0) ||
      (previousObject &&
        previousObject.mealDealStatus === 1 &&
        currentObject.mealDealStatus === 0)
    ) {
      // If any of the conditions are true, apply a class with larger margin top
      return classes.mealDealStatusIsZeroAndOneStyleInFirstColumn;
    } else if (index === 0) {
      // If none of the conditions are true but the index is 0, apply a class with top of the raw
      return classes.firstSaleItemStyle;
    } else {
      // If none of the conditions are true and the index is not 0, apply a class with smaller margin top
      return classes.mealDealStatusIsTwoStyle;
    }
  };

  /* Change the fontsize sale item details using mealDealStatus. */
  const handleSaleItemDataStyle = (mealDealStatus: any) => {
    if (mealDealStatus === 0 || mealDealStatus === 1) {
      // If the meal deal status is 0 or 1, apply a class with larger font size
      return classes.mealDealStatusIsZeroAndOneFontSizeStyle;
    } else {
      // Otherwise, apply a class with a smaller font size
      return classes.mealDealStatusIsFontSizeStyle;
    }
  };

  /* This function takes an address string and returns a JSX element with each line
     of the address separated by a line break */
  const handleCustomerAddress = (address: any) => {
    return address.split("\n").map(
      (line: any, index: number) =>
        line !== "" && (
          // Create a div element with the line of the address and a line break
          <div key={index}>
            {line}
            <br />
          </div>
        ),
    );
  };

  const theme: CustomTheme = useTheme();
  const mobileScreen = useMediaQuery("(max-width: 600px)");
  return (
    <>
      <DialogTitle>Voided Order Item</DialogTitle>
      <DialogContent>
        <Grid container>
          {!isLoading ? (
            <>
              {mobileScreen ? (
                <>
                  <Grid item xs={12} className={classes.style}>
                    <Chip
                      className={classes.locationNameStyle}
                      label={
                        <Typography
                          variant="body1"
                          className={classes.textColor}
                          align="left"
                        >
                          {locationName}
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      marginBottom: "16px",
                    }}
                  >
                    {handleOrderChannelPin(nodeData)}
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={10} className={classes.style}>
                    <Chip
                      className={classes.locationNameStyle}
                      label={
                        <Typography
                          variant="body1"
                          className={classes.textColor}
                          align="left"
                        >
                          {locationName}
                        </Typography>
                      }
                    />
                    {handleOrderChannelPin(nodeData)}
                  </Grid>
                </>
              )}
            </>
          ) : (
            <>
              <Grid item xs={12} style={{ display: "flex" }}>
                <Skeleton
                  style={{
                    marginTop: "4px",
                    marginRight: "8px",
                    borderRadius: "10px",
                    border: `1px solid ${theme.palette.background.entity_border}`,
                  }}
                  variant="rect"
                  width={160}
                  height={28}
                />
                <Skeleton
                  style={{
                    marginTop: "4px",
                    marginRight: "8px",
                    borderRadius: "10px",
                    border: `1px solid ${theme.palette.background.entity_border}`,
                  }}
                  variant="rect"
                  width={100}
                  height={28}
                />
              </Grid>
            </>
          )}
          <Grid
            item
            xs={12}
            md={6}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
            }}
          >
            <Grid container>
              {nodeData.cancelled &&
                (!isLoading ? (
                  !_.isEmpty(saleCancelItemList) && (
                    <Grid
                      container
                      style={{ marginTop: "2px", marginBottom: "12px" }}
                    >
                      <Box className={classes.cancelBoxStyle}>
                        {saleCancelItemList.map((data: any) => (
                          <Grid container style={{ marginTop: "4px" }}>
                            <Grid
                              item
                              xs={10}
                              style={{
                                display: "flex",
                                justifyContent: "start",
                                paddingLeft: "8px",
                                marginBottom: "4px",
                              }}
                            >
                              <Typography
                                style={{
                                  fontSize: "24px",
                                  fontWeight: "normal",
                                }}
                                className={classes.textColor}
                              >
                                CANCELLED SALE
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              style={{
                                display: "flex",
                                justifyContent: "end",
                                paddingRight: "4px",
                                marginTop: "4px",
                              }}
                            >
                              <BlockIcon
                                style={{ color: "white" }}
                                fontSize="large"
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              style={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                                paddingLeft: "8px",
                                marginTop: "4px",
                              }}
                            >
                              <Typography
                                variant={"body2"}
                                className={classes.textColor}
                                style={{ marginTop: "2px" }}
                              >
                                Cancelled by
                              </Typography>
                              {userList && (
                                <Typography
                                  variant={"body1"}
                                  style={{
                                    fontWeight: "bolder",
                                    paddingLeft: "8px",
                                  }}
                                  className={classes.textColor}
                                >
                                  {handleUserName(data.userId)}
                                </Typography>
                              )}
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              style={{
                                display: "flex",
                                justifyContent: "start",
                                paddingLeft: "8px",
                              }}
                            >
                              <Typography
                                style={{
                                  fontWeight: "bolder",
                                  fontSize: "18px",
                                  color: "white",
                                }}
                              >
                                {convertDateTimeFormat(data.date)}
                              </Typography>
                            </Grid>
                          </Grid>
                        ))}
                      </Box>
                    </Grid>
                  )
                ) : (
                  <Skeleton
                    style={{
                      marginTop: "16px",
                      borderRadius: "10px",
                      border: `1px solid ${theme.palette.background.entity_border}`,
                    }}
                    variant="rect"
                    width={"100%"}
                    height={120}
                  />
                ))}

              {!isLoading ? (
                <Grid item xs={12} className={classes.firstColumnMargin}>
                  <CardCommon backgroundColor={"entity_background"}>
                    <Grid
                      container
                      style={{
                        marginTop: !_.isEmpty(saleItemList) ? "12px" : "0px",
                      }}
                    >
                      {!_.isEmpty(saleItemList) && (
                        <Grid
                          item
                          xs={3}
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            paddingLeft: "20px",
                            color: theme.palette.custom.orange.contrastText,
                          }}
                        >
                          Quantity
                        </Grid>
                      )}
                      {!_.isEmpty(saleItemList) && (
                        <Grid
                          item
                          xs={6}
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            paddingLeft: "24px",
                            color: theme.palette.custom.orange.contrastText,
                          }}
                        >
                          Item
                        </Grid>
                      )}
                      {!_.isEmpty(saleItemList) && (
                        <Grid
                          item
                          xs={3}
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingRight: "20px",
                            color: theme.palette.custom.orange.contrastText,
                          }}
                        >
                          Price
                        </Grid>
                      )}

                      {!_.isEmpty(saleItemList) && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <Box className={classes.dividerStyle}></Box>
                        </div>
                      )}
                      {!_.isEmpty(saleItemList)
                        ? saleItemList.map(
                            (item: any, index: number, array: any) => {
                              return (
                                <>
                                  <Grid
                                    item
                                    xs={3}
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      paddingLeft: "20px",
                                    }}
                                    className={handleSaleItemTitleStyle(
                                      array[index - 1],
                                      item,
                                      index,
                                    )}
                                  >
                                    <Typography
                                      className={handleSaleItemDataStyle(
                                        item.mealDealStatus,
                                      )}
                                      style={{
                                        color:
                                          theme.palette.custom.orange
                                            .contrastText,
                                      }}
                                    >
                                      {item.mealDealStatus !== 2 && item.qty}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    className={handleSaleItemTitleStyle(
                                      array[index - 1],
                                      item,
                                      index,
                                    )}
                                  >
                                    <Typography
                                      align="left"
                                      className={handleSaleItemDataStyle(
                                        item.mealDealStatus,
                                      )}
                                      style={{
                                        color:
                                          theme.palette.custom.orange
                                            .contrastText,
                                      }}
                                    >
                                      {!_.isEmpty(item.itemName)
                                        ? item.itemName
                                        : "Unknown Item"}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={3}
                                    className={handleSaleItemTitleStyle(
                                      array[index - 1],
                                      item,
                                      index,
                                    )}
                                  >
                                    <Typography
                                      align="right"
                                      className={handleSaleItemDataStyle(
                                        item.mealDealStatus,
                                      )}
                                      style={{
                                        paddingRight: "20px",
                                        color:
                                          theme.palette.custom.orange
                                            .contrastText,
                                      }}
                                    >
                                      {item.mealDealStatus === 2
                                        ? item.amount > 0 &&
                                          parseFloat(
                                            item.amount + item.discount,
                                          ).toFixed(2)
                                        : parseFloat(
                                            item.amount + item.discount,
                                          ).toFixed(2)}
                                    </Typography>
                                  </Grid>
                                </>
                              );
                            },
                          )
                        : null}

                      {!_.isEmpty(saleItemList) && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                            marginTop: "24px",
                          }}
                        >
                          <Box className={classes.dividerStyle}></Box>
                        </div>
                      )}
                    </Grid>
                    <Grid
                      container
                      style={{
                        marginTop: !_.isEmpty(saleItemList) ? "12px" : "0px",
                        marginBottom: "12px",
                      }}
                    >
                      <>
                        <Grid
                          item
                          xs={6}
                          className={classes.topAlign}
                          style={{
                            paddingLeft: "20px",
                            marginTop: "20px",
                            display: "flex",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "12px",
                              color: theme.palette.custom.orange.contrastText,
                            }}
                            align="left"
                          >
                            Discount
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          xs={6}
                          className={classes.gridTopAlign}
                          style={{ paddingRight: "20px", marginTop: "20px" }}
                        >
                          <Typography
                            style={{
                              fontSize: "12px",
                              color: theme.palette.custom.orange.contrastText,
                            }}
                            align="left"
                          >
                            {parseFloat(nodeData.discountValue) > 0
                              ? `- ${parseFloat(nodeData.discountValue).toFixed(
                                  2,
                                )}`
                              : `${parseFloat(nodeData.discountValue).toFixed(
                                  2,
                                )}`}
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          xs={6}
                          className={classes.topAlign}
                          style={{ paddingLeft: "20px", marginBottom: "10px" }}
                        >
                          <Typography
                            style={{
                              fontSize: "12px",
                              color: theme.palette.custom.orange.contrastText,
                            }}
                            align="left"
                          >
                            Total Amount
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          className={classes.gridTopAlign}
                          style={{ paddingRight: "20px", marginBottom: "10px" }}
                        >
                          <Typography
                            style={{
                              fontSize: "12px",
                              color: theme.palette.custom.orange.contrastText,
                            }}
                            align="left"
                          >
                            {parseFloat(nodeData.totalAmount).toFixed(2)}
                          </Typography>
                        </Grid>
                        {nodeData.cashPayment > 0 && (
                          <>
                            <Grid
                              item
                              xs={6}
                              className={classes.topAlign}
                              style={{ paddingLeft: "20px", display: "flex" }}
                            >
                              <Typography
                                style={{
                                  fontSize: "12px",
                                  color:
                                    theme.palette.custom.orange.contrastText,
                                }}
                                align="left"
                              >
                                Cash Payment
                              </Typography>
                            </Grid>
                          </>
                        )}

                        {nodeData.cashPayment > 0 && (
                          <Grid
                            item
                            xs={6}
                            className={classes.gridTopAlign}
                            style={{ paddingRight: "20px" }}
                          >
                            <Typography
                              style={{
                                fontSize: "12px",
                                color: theme.palette.custom.orange.contrastText,
                              }}
                              align="left"
                            >
                              {nodeData.cashPayment.toFixed(2)}
                            </Typography>
                          </Grid>
                        )}

                        {nodeData.cardPayment > 0 && (
                          <>
                            <Grid
                              item
                              xs={6}
                              className={classes.topAlign}
                              style={{ paddingLeft: "20px" }}
                            >
                              <Typography
                                style={{
                                  fontSize: "12px",
                                  color:
                                    theme.palette.custom.orange.contrastText,
                                }}
                                align="left"
                              >
                                Card Payment
                              </Typography>
                            </Grid>
                          </>
                        )}
                        {nodeData.cardPayment > 0 && (
                          <Grid
                            item
                            xs={6}
                            className={classes.gridTopAlign}
                            style={{ paddingRight: "20px" }}
                          >
                            <Typography
                              style={{
                                fontSize: "12px",
                                color: theme.palette.custom.orange.contrastText,
                              }}
                              align="left"
                            >
                              {nodeData.cardPayment.toFixed(2)}
                            </Typography>
                          </Grid>
                        )}

                        {!_.isEmpty(otherPayment) &&
                          otherPayment.map((payment: any) => {
                            return (
                              <Grid item xs={12} style={{ display: "flex" }}>
                                <Grid
                                  item
                                  xs={6}
                                  className={classes.topAlign}
                                  style={{ paddingLeft: "20px" }}
                                >
                                  <Typography
                                    align="left"
                                    style={{
                                      textTransform: "capitalize",
                                      fontSize: "12px",
                                      color:
                                        theme.palette.custom.orange
                                          .contrastText,
                                    }}
                                  >
                                    {payment.type}
                                  </Typography>
                                </Grid>

                                <Grid
                                  item
                                  xs={6}
                                  className={classes.gridTopAlign}
                                  style={{ paddingRight: "20px" }}
                                >
                                  <Typography
                                    style={{
                                      fontSize: "12px",
                                      color:
                                        theme.palette.custom.orange
                                          .contrastText,
                                    }}
                                    align="left"
                                  >
                                    {payment.amount.toFixed(2)}
                                  </Typography>
                                </Grid>
                              </Grid>
                            );
                          })}
                      </>
                    </Grid>
                  </CardCommon>
                </Grid>
              ) : (
                <Skeleton
                  style={{
                    marginTop: "16px",
                    borderRadius: "10px",
                    border: `1px solid ${theme.palette.background.entity_border}`,
                  }}
                  variant="rect"
                  width={"100%"}
                  height={280}
                />
              )}
            </Grid>
          </Grid>
          <Grid item md={6} xs={12} className={classes.rightSideTopAlign}>
            {!isLoading ? (
              <Box
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <CardCommon backgroundColor={"entity_background"}>
                      <Grid container>
                        {!_.isEmpty(nodeData.partySize) && (
                          <Grid
                            item
                            xs={12}
                            style={{
                              paddingLeft: "16px",
                              marginBottom: "12px",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "11px",
                                fontWeight: "lighter",
                                color: theme.palette.custom.orange.contrastText,
                              }}
                              align="left"
                            >
                              Party Size
                            </Typography>
                            <Typography
                              style={{
                                fontSize: "17px",
                                fontWeight: "bolder",
                                color: theme.palette.custom.orange.contrastText,
                              }}
                              align="left"
                            >
                              {nodeData.partySize}
                            </Typography>
                          </Grid>
                        )}
                        {!_.isEmpty(nodeData.transactionType) && (
                          <Grid
                            item
                            xs={12}
                            style={{
                              paddingLeft: "16px",
                              marginBottom: "12px",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "11px",
                                fontWeight: "lighter",
                                color: theme.palette.custom.orange.contrastText,
                              }}
                              align="left"
                            >
                              Transaction Type
                            </Typography>
                            <Typography
                              style={{
                                fontSize: "17px",
                                fontWeight: "bolder",
                                color: theme.palette.custom.orange.contrastText,
                              }}
                              align="left"
                            >
                              {nodeData.transactionType}
                            </Typography>
                          </Grid>
                        )}
                        {!_.isEmpty(nodeData.date) && (
                          <>
                            <Grid
                              item
                              xs={10}
                              style={{
                                paddingLeft: "16px",
                                marginBottom: "12px",
                                marginTop: "14px",
                              }}
                            >
                              <Typography
                                style={{
                                  fontSize: "11px",
                                  fontWeight: "lighter",
                                  color:
                                    theme.palette.custom.orange.contrastText,
                                }}
                                align="left"
                              >
                                Void Date
                              </Typography>
                              <Typography
                                style={{
                                  fontSize: "17px",
                                  fontWeight: "bolder",
                                  color:
                                    theme.palette.custom.orange.contrastText,
                                }}
                                align="left"
                              >
                                {convertDateTimeFormatAndConvertTimeBold(
                                  nodeData.date,
                                )}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              style={{
                                display: "flex",
                                justifyContent: "end",
                                marginTop: "14px",
                                paddingRight: "12px",
                              }}
                            >
                              <Typography
                                align="left"
                                style={{
                                  color:
                                    theme.palette.custom.orange.contrastText,
                                }}
                              >
                                <StorefrontIcon fontSize="large" />
                              </Typography>
                            </Grid>
                          </>
                        )}
                        {!_.isEmpty(extraInfo?.data?.date) && (
                          <>
                            <Grid
                              item
                              xs={10}
                              style={{
                                paddingLeft: "16px",
                                marginBottom: "12px",
                                marginTop: "14px",
                              }}
                            >
                              <Typography
                                style={{
                                  fontSize: "11px",
                                  fontWeight: "lighter",
                                  color:
                                    theme.palette.custom.orange.contrastText,
                                }}
                                align="left"
                              >
                                Order Created Date
                              </Typography>
                              <Typography
                                style={{
                                  fontSize: "17px",
                                  fontWeight: "bolder",
                                  color:
                                    theme.palette.custom.orange.contrastText,
                                }}
                                align="left"
                              >
                                {convertDateTimeFormatAndConvertTimeBold(
                                  extraInfo.data.date,
                                )}
                              </Typography>
                            </Grid>
                          </>
                        )}

                        {!_.isEmpty(nodeData.userId) && (
                          <Grid
                            item
                            xs={12}
                            style={{
                              paddingLeft: "16px",
                              marginBottom: "12px",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "11px",
                                fontWeight: "lighter",
                                color: theme.palette.custom.orange.contrastText,
                              }}
                              align="left"
                            >
                              Cashier
                            </Typography>
                            <Typography
                              style={{
                                fontSize: "17px",
                                fontWeight: "bolder",
                                color: theme.palette.custom.orange.contrastText,
                              }}
                              align="left"
                            >
                              {handleUserName(nodeData.userId)}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </CardCommon>
                  </Grid>

                  <Grid item xs={12} className={classes.gridTopAlignRightSide}>
                    <CardCommon backgroundColor={"entity_background"}>
                      <Grid container>
                        <Grid
                          item
                          xs={10}
                          style={{ marginTop: "12px", paddingLeft: "16px" }}
                        >
                          <Typography
                            style={{
                              fontSize: "11px",
                              fontWeight: "lighter",
                              color: theme.palette.custom.orange.contrastText,
                            }}
                            align="left"
                          >
                            Order Ref
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "17px",
                              fontWeight: "bolder",
                              color: theme.palette.custom.orange.contrastText,
                            }}
                            align="left"
                          >
                            {nodeData.queOrderNo}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            marginTop: "14px",
                            paddingRight: "12px",
                          }}
                        >
                          <Typography
                            align="left"
                            style={{
                              color: theme.palette.custom.orange.contrastText,
                            }}
                          >
                            <ReceiptOutlinedIcon fontSize="large" />
                          </Typography>
                        </Grid>
                        <Box className={classes.dividerStyle}></Box>
                        {!_.isEmpty(nodeData.receiptNo) && (
                          <Grid
                            item
                            xs={12}
                            style={{
                              paddingLeft: "16px",
                              marginBottom: "12px",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "11px",
                                fontWeight: "lighter",
                                color: theme.palette.custom.orange.contrastText,
                              }}
                              align="left"
                            >
                              Receipt No
                            </Typography>
                            <Typography
                              style={{
                                fontSize: "17px",
                                fontWeight: "bolder",
                                color: theme.palette.custom.orange.contrastText,
                              }}
                              align="left"
                            >
                              {nodeData.receiptNo}
                            </Typography>
                          </Grid>
                        )}
                        {!_.isEmpty(nodeData.orderNo) && (
                          <Grid
                            item
                            xs={12}
                            style={{
                              paddingLeft: "16px",
                              marginBottom: "12px",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "11px",
                                fontWeight: "lighter",
                                color: theme.palette.custom.orange.contrastText,
                              }}
                              align="left"
                            >
                              Order No
                            </Typography>
                            <Typography
                              style={{
                                fontSize: "17px",
                                fontWeight: "bolder",
                                color: theme.palette.custom.orange.contrastText,
                              }}
                              align="left"
                            >
                              {nodeData.orderNo}
                            </Typography>
                          </Grid>
                        )}
                        {!_.isEmpty(nodeData.extQueOrderNo) && (
                          <Grid
                            item
                            xs={12}
                            style={{
                              paddingLeft: "16px",
                              marginBottom: "12px",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "11px",
                                fontWeight: "lighter",
                                color: theme.palette.custom.orange.contrastText,
                              }}
                              align="left"
                            >
                              Channel Order No
                            </Typography>
                            <Typography
                              style={{
                                fontSize: "17px",
                                fontWeight: "bolder",
                                color: theme.palette.custom.orange.contrastText,
                              }}
                              align="left"
                            >
                              {nodeData.extQueOrderNo}
                            </Typography>
                          </Grid>
                        )}

                        {!_.isEmpty(nodeData.comments) &&
                          nodeData.comments !==
                            "Comments disabled or void amount below the threshold" && (
                            <Grid
                              item
                              xs={12}
                              style={{
                                paddingLeft: "16px",
                                marginBottom: "12px",
                              }}
                            >
                              <Typography
                                style={{
                                  fontSize: "11px",
                                  fontWeight: "lighter",
                                  color:
                                    theme.palette.custom.orange.contrastText,
                                }}
                                align="left"
                              >
                                Comments
                              </Typography>
                              <Typography
                                style={{
                                  fontSize: "17px",
                                  fontWeight: "bolder",
                                  color:
                                    theme.palette.custom.orange.contrastText,
                                }}
                                align="left"
                              >
                                {nodeData.comments}
                              </Typography>
                            </Grid>
                          )}
                      </Grid>
                    </CardCommon>
                  </Grid>
                  {!_.isEmpty(customerDetails) && (
                    <Grid
                      item
                      xs={12}
                      className={classes.gridTopAlignRightSide}
                    >
                      <CardCommon backgroundColor={"entity_background"}>
                        <Grid container>
                          {customerDetails.customerFullName && (
                            <Grid
                              item
                              xs={10}
                              style={{ marginTop: "12px", paddingLeft: "16px" }}
                            >
                              <Typography
                                style={{
                                  fontSize: "11px",
                                  fontWeight: "lighter",
                                  color:
                                    theme.palette.custom.orange.contrastText,
                                }}
                                align="left"
                              >
                                Name
                              </Typography>
                              <Typography
                                style={{
                                  fontSize: "17px",
                                  fontWeight: "bolder",
                                  color:
                                    theme.palette.custom.orange.contrastText,
                                }}
                                align="left"
                              >
                                {customerDetails.customerFullName}
                              </Typography>
                            </Grid>
                          )}
                          <Grid
                            item
                            xs={2}
                            style={{
                              display: "flex",
                              justifyContent: "end",
                              marginTop: "14px",
                              paddingRight: "12px",
                            }}
                          >
                            <Typography align="left">
                              <span
                                className="material-symbols-outlined"
                                style={{
                                  fontSize: "34px",
                                  color:
                                    theme.palette.custom.orange.contrastText,
                                }}
                              >
                                badge
                              </span>
                            </Typography>
                          </Grid>
                          <Box className={classes.dividerStyle}></Box>
                          {!_.isEmpty(customerDetails.tel) && (
                            <Grid
                              item
                              xs={12}
                              style={{
                                paddingLeft: "16px",
                                marginBottom: "12px",
                              }}
                            >
                              <Typography
                                style={{
                                  fontSize: "11px",
                                  fontWeight: "lighter",
                                  color:
                                    theme.palette.custom.orange.contrastText,
                                }}
                                align="left"
                              >
                                Contact
                              </Typography>
                              <Typography
                                style={{
                                  fontSize: "17px",
                                  fontWeight: "bolder",
                                  color:
                                    theme.palette.custom.orange.contrastText,
                                }}
                                align="left"
                              >
                                {customerDetails.tel}
                              </Typography>
                            </Grid>
                          )}
                          {!_.isEmpty(customerDetails.address) && (
                            <Grid
                              item
                              xs={12}
                              style={{
                                paddingLeft: "16px",
                                marginBottom: "12px",
                              }}
                            >
                              <Typography
                                style={{
                                  fontSize: "11px",
                                  fontWeight: "lighter",
                                  color:
                                    theme.palette.custom.orange.contrastText,
                                }}
                                align="left"
                              >
                                Address
                              </Typography>
                              <Typography
                                style={{
                                  fontSize: "17px",
                                  fontWeight: "bolder",
                                  color:
                                    theme.palette.custom.orange.contrastText,
                                }}
                                align="left"
                              >
                                {handleCustomerAddress(customerDetails.address)}
                              </Typography>
                            </Grid>
                          )}
                          {!_.isEmpty(customerDetails.postcode) && (
                            <Grid
                              item
                              xs={12}
                              style={{
                                paddingLeft: "16px",
                                marginBottom: "12px",
                              }}
                            >
                              <Typography
                                style={{
                                  fontSize: "11px",
                                  fontWeight: "lighter",
                                  color:
                                    theme.palette.custom.orange.contrastText,
                                }}
                                align="left"
                              >
                                Postcode
                              </Typography>
                              <Typography
                                style={{
                                  fontSize: "17px",
                                  fontWeight: "bolder",
                                  color:
                                    theme.palette.custom.orange.contrastText,
                                }}
                                align="left"
                              >
                                {customerDetails.postcode}
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      </CardCommon>
                    </Grid>
                  )}
                </Grid>
              </Box>
            ) : (
              <div style={{ marginLeft: "20px", marginTop: "60px" }}>
                <Skeleton
                  variant="text"
                  width={"50%"}
                  style={{
                    marginTop: "8px",
                    borderRadius: "10px",
                    border: `1px solid ${theme.palette.background.entity_border}`,
                  }}
                />
                <Skeleton
                  variant="text"
                  width={"50%"}
                  style={{
                    marginTop: "8px",
                    borderRadius: "10px",
                    border: `1px solid ${theme.palette.background.entity_border}`,
                  }}
                />
                <Skeleton
                  variant="text"
                  width={"50%"}
                  style={{
                    marginTop: "8px",
                    borderRadius: "10px",
                    border: `1px solid ${theme.palette.background.entity_border}`,
                  }}
                />
                <Skeleton
                  variant="text"
                  width={"50%"}
                  style={{
                    marginTop: "8px",
                    borderRadius: "10px",
                    border: `1px solid ${theme.palette.background.entity_border}`,
                  }}
                />
                <Skeleton
                  variant="text"
                  width={"50%"}
                  style={{
                    marginTop: "8px",
                    borderRadius: "10px",
                    border: `1px solid ${theme.palette.background.entity_border}`,
                  }}
                />
                <Skeleton
                  variant="text"
                  width={"50%"}
                  style={{
                    marginTop: "8px",
                    borderRadius: "10px",
                    border: `1px solid ${theme.palette.background.entity_border}`,
                  }}
                />
              </div>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

export default VoidOut;
