import React from "react";
import {
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Typography,
} from "@material-ui/core";

import { CustomTheme } from "../../../../types/customTheme";

export interface StockLevelModalProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  handleRemovePostcode: any;
  isLoadingUpdate: any;
}

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    alertHeader: {
      height: "45px",
      paddingTop: "5px",
      color: "#ffffff",
      backgroundColor: theme.palette.warning.dark,
    },
    titleStyle: {
      color: "white",
    },
    alertDescription: {
      paddingTop: "15px",
    },
    cancelLabelStyle: {
      color: theme.palette.text.primary,
    },
  }),
);

/**
 * ChainRemoveModal - A React functional component that displays a modal dialog for confirming the removal of a postcode.
 *
 */
const ChainRemoveModal: React.FC<StockLevelModalProps> = ({
  isOpen,
  setIsOpen,
  handleRemovePostcode,
  isLoadingUpdate,
}) => {
  const classes = useStyles();
  return (
    <Dialog disableBackdropClick={true} open={isOpen}>
      <DialogTitle className={classes.alertHeader} id="alert-dialog-title-id">
        <Typography variant="h6" className={classes.titleStyle}>
          Confirm removal
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          className={classes.alertDescription}
          id="alert-dialog-description-id"
        >
          Do you want to proceed?{" "}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setIsOpen(false)}
          disabled={isLoadingUpdate}
          className={classes.cancelLabelStyle}
        >
          Cancel
        </Button>
        <Button onClick={handleRemovePostcode} disabled={isLoadingUpdate}>
          {isLoadingUpdate ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            "Ok"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChainRemoveModal;
