import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router";

import withAuthority from "../../../../components/Auth/withAuthority";
import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import { Box } from "@material-ui/core";
import _ from "lodash";
import { fetchAllFilterLocations } from "../../../../services/locationApp/locationFilterService";
import InventoryDetailsDefault from "../../../../components/common/InventoryDetails/InventoryDetailsDefault";
import LoyaltyConfigurationNode from "./LoyaltyConfigurationNode";
import AlertDialog from "../../../../components/alerts/AlertDialog";
import {
  fetchLoyaltyProgramInfo,
  updateLocationProgram,
  updateProgramStatus,
} from "../../../../services/customerApp/customerDetails";

interface Location {
  id: string;
  businessDisplayName: string;
}

/**
 * StockConfiguration Component:
 * This React component manages the configuration of stock departments for a given inventory location.
 * It includes functionality to fetch and display master locations, create or update stock configurations,
 * and handle activation requirements. The component is integrated with authentication checks to ensure
 * the user has the necessary authority.
 *
 * Component Structure:
 * - State variables manage component state, including error messages, activation status, and configuration details.
 * - Functions handle API calls, stock configuration updates, and location-specific actions.
 * - useEffect fetches location information and sets the document title on component mount.
 * - JSX structure includes the main container, inventory details component, and alert dialogs.
 *
 * Note: The component is wrapped with the withAuthority HOC to enforce user authority checks.
 */
const StockConfiguration: React.FunctionComponent = () => {
  const [error, setError] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [isStockEnable, setIsStockEnable] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [programId, setProgramId] = useState("");
  const [
    successfullyChangeStockConfiguration,
    setSuccessfullyChangeStockConfiguration,
  ] = useState(false);
  const [locationSelectorList, setLocationSelectorList] = useState([]);
  const [isOpenSkeletonLoading, setIsOpenSkeletonLoading] = useState(true);
  const [success, setSuccess] = useState("");
  const [isEnableMasterLocation, setIsEnableMasterLocation] = useState(false);
  const [selectedDepartmentObj, setSelectedDepartmentObj] = useState<any>({});
  const [selectedDepartmentObjInitial, setSelectedDepartmentObjInitial] =
    useState<any>({});
  const [stockConfiguration, setStockConfiguration] = useState<any>([]);
  const [isActiveInitial, setIsActiveInitial] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [isDisabledButton, setIsDisabledButton] = useState(false);
  const [owner, setOwner] = useState("");
  const [playStoreUrl, setPlayStoreUrl] = useState("");
  const [appStoreUrl, setAppStoreUrl] = useState("");
  const [playStoreUrlInitial, setPlayStoreUrlInitial] = useState("");
  const [appStoreUrlInitial, setAppStoreUrlInitial] = useState("");
  const [isAvailableOnline, setIsAvailableOnline] = useState(false);
  const [isAvailableOnlineInitial, setIsAvailableOnlineInitial] = useState(false);
  const [isAvailableKiosk, setIsAvailableKiosk] = useState(false);
  const [isAvailableKioskInitial, setIsAvailableKioskInitial] = useState(false);

  const match: any = useRouteMatch();
  const handleGetMasterLocations = async (locationList: any) => {
    try {
      const { data } = await fetchLoyaltyProgramInfo(match.params.locationId);
      if (data && data.data) {
        const status: any = Object.values(data.data)[0];
        const filterLocation = locationList.filter(
          (locationInfo: any) => locationInfo.id === status.owner,
        );
        const { id, businessDisplayName } = filterLocation[0];
        setSelectedDepartmentObj({ id: id, label: businessDisplayName });
        setSelectedDepartmentObjInitial({ id: id, label: businessDisplayName });
        setProgramId(Object.keys(data.data)[0]);
        setIsActive(status?.status);
        setIsActiveInitial(status?.status);
        setPlayStoreUrl(status.play_store_url);
        setIsAvailableOnline(status.isAvailableOnline );
        setIsAvailableOnlineInitial(status.isAvailableOnline );
        setIsAvailableKiosk(status.isAvailableKiosk );
        setIsAvailableKioskInitial(status.isAvailableKiosk );
        setAppStoreUrl(status.app_store_url);
        setPlayStoreUrlInitial(status.play_store_url);
        setAppStoreUrlInitial(status.app_store_url);
        if (status?.owner !== match.params.locationId) {
          setIsDisabledButton(true);
          setIsEnableMasterLocation(true);
        } else {
          setIsDisabledButton(false);
          setIsEnableMasterLocation(false);
        }
      } else {
        setIsActive(false);
        setIsActiveInitial(false);
      }

      setIsOpenSkeletonLoading(false);
    } catch (error) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsOpenSkeletonLoading(false);
    }
  };

  const handleGetLocationProgram = async () => {
    try {
      const { data } = await fetchLoyaltyProgramInfo(selectedDepartmentObj.id);

      return data.data;
    } catch (error) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsOpenSkeletonLoading(false);
    }
  };

  const handleUpdateProgramStatus = async (id?: any) => {
    const programDetails = await handleGetLocationProgram();

    const formData = {
      status: isActive,
      owner: selectedDepartmentObj.id,
      app_store_url: appStoreUrl,
      play_store_url: playStoreUrl,
      programId: Object.keys(programDetails)[0],
      isAvailableOnline,
      isAvailableKiosk
    };

    try {
      const res = await updateProgramStatus(
        match.params.locationId,
        id || programId,
        formData,
      );
      const status: any = Object.values(res.data.data)[0];
      setIsActive(status.status);
      setIsActiveInitial(status.status);
      setPlayStoreUrl(status.play_store_url);
      setPlayStoreUrlInitial(status.play_store_url);
      setIsAvailableOnline(status.isAvailableOnline );
      setIsAvailableOnlineInitial(status.isAvailableOnline );
      setIsAvailableKiosk(status.isAvailableKiosk );
      setIsAvailableKioskInitial(status.isAvailableKiosk );
      setAppStoreUrl(status.app_store_url);
      setAppStoreUrlInitial(status.app_store_url);
      setSuccessfullyChangeStockConfiguration(true);
      setIsLoadingButton(false);
    } catch (err) {
      setIsLoadingButton(false);
      setSuccessfullyChangeStockConfiguration(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  const handleUpdateLocationProgramStatus = async () => {
    const programDetails = await handleGetLocationProgram();

    const formData = {
      programId: Object.keys(programDetails)[0],
    };

    try {
      const res = await updateLocationProgram(
        match.params.locationId,
        formData,
      );

      setSuccessfullyChangeStockConfiguration(true);
      setIsLoadingButton(false);
      const status: any = Object.values(programDetails)[0];
      setOwner(status.owner);
      setIsActive(status.status);
      setIsActiveInitial(status.status);
      if (status?.owner !== match.params.locationId) {
        setIsDisabledButton(true);
        setIsEnableMasterLocation(true);
        setSelectedDepartmentObjInitial(selectedDepartmentObj);
      } else {
        setIsDisabledButton(false);
        setIsEnableMasterLocation(false);
      }

      setPlayStoreUrl(status.play_store_url);
      setAppStoreUrl(status.app_store_url);
      setPlayStoreUrlInitial(status.play_store_url);
      setAppStoreUrlInitial(status.app_store_url);

      setProgramId(Object.keys(programDetails)[0]);
      setIsAvailableOnline(status.isAvailableOnline );
      setIsAvailableOnlineInitial(status.isAvailableOnline );
      setIsAvailableKiosk(status.isAvailableKiosk );
      setIsAvailableKioskInitial(status.isAvailableKiosk );
      setAppStoreUrl(status.app_store_url);

      if (isActive !== isActiveInitial) {
        handleUpdateProgramStatus(Object.keys(programDetails)[0]);
      }
    } catch (err) {
      setIsLoadingButton(false);
      setSuccessfullyChangeStockConfiguration(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  const handleGetMasterLocationSpecificLocation = async (locationId: any) => {
    try {
      setIsLoadingButton(true);
      if (selectedDepartmentObj.id !== selectedDepartmentObjInitial.id) {
        handleUpdateLocationProgramStatus();
      } else {
        handleUpdateProgramStatus();
      }
      // if (isActive !== isActiveInitial) {
      //   handleUpdateProgramStatus();
      // }
    } catch (error) {
      // Handle errors during the API call
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  const getAllFilterLocation = async () => {
    try {
      // Attempt to fetch location information using the 'fetchAllFilterLocations' API
      const res = await fetchAllFilterLocations("");

      // Initialize an array to hold location data
      let locationList: any = [];

      // Check if location data is not empty in the response
      if (!_.isEmpty(res.data.data)) {
        // Iterate through each location in the response data
        res.data.data.forEach((locationData: Location) => {
          // Push an object with correct property name and label properties to the locationList array
          locationList.push({
            id: locationData.id, // Use the correct property name 'locationId'
            label: locationData.businessDisplayName,
          });
        });
      }

      setLocationSelectorList(locationList);
      handleGetMasterLocations(res.data.data);
      // Update the 'locationSelectorList' state with the fetched location list
      setLocationSelectorList(locationList);
    } catch (error) {
      // If an error occurs during the API call, set the 'error' state
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsOpenSkeletonLoading(false);
    }
  };

  useEffect(() => {
    document.title = "Inventory - Loyalty Configuration";
    getAllFilterLocation();
  }, []);

  return (
    <>
      {/* Main container */}
      <Box>
        <InventoryDetailsDefault
          locationSelectorList={locationSelectorList}
          isOpenSkeletonLoading={isOpenSkeletonLoading}
          topic="Loyalty Configuration"
          isNeedAddButton={false}
          nodeList={[1]}
          isActiveLocationId={true}
        >
          <LoyaltyConfigurationNode
            locationSelectorList={locationSelectorList}
            setSelectedDepartmentObj={setSelectedDepartmentObj}
            selectedDepartmentObj={selectedDepartmentObj}
            setIsActive={setIsActive}
            isActive={isActive}
            stockConfiguration={stockConfiguration}
            handleGetMasterLocationSpecificLocation={
              handleGetMasterLocationSpecificLocation
            }
            selectedDepartmentObjInitial={selectedDepartmentObjInitial}
            isActiveInitial={isActiveInitial}
            setIsEnableMasterLocation={setIsEnableMasterLocation}
            isEnableMasterLocation={isEnableMasterLocation}
            isLoadingButton={isLoadingButton}
            isDisabledButton={isDisabledButton}
            playStoreUrl={playStoreUrl}
            appStoreUrl={appStoreUrl}
            setPlayStoreUrl={setPlayStoreUrl}
            setAppStoreUrl={setAppStoreUrl}
            playStoreUrlInitial={playStoreUrlInitial}
            appStoreUrlInitial={appStoreUrlInitial}
            isAvailableOnline={isAvailableOnline}
            isAvailableOnlineInitial={isAvailableOnlineInitial}
            setIsAvailableOnline={setIsAvailableOnline}
            isAvailableKiosk={isAvailableKiosk}
            isAvailableKioskInitial={isAvailableKioskInitial}
            setIsAvailableKiosk={setIsAvailableKiosk}
          />
        </InventoryDetailsDefault>
        {/* DefaultAlert components for success and error messages */}
        <DefaultAlert
          open={!!success}
          handleClose={() => setSuccess("")}
          message={success}
          severity={"success"}
        />
        <DefaultAlert
          open={!!error}
          handleClose={() => setError("")}
          message={error}
          severity="error"
        />
      </Box>

      {!isStockEnable && (
        <AlertDialog
          open={!isStockEnable}
          title="Activation Required"
          desc={errorMessage}
          severity="error"
          confirmLabel="Ok"
          disableBackdropClick
          confirmAction={() => {
            setIsStockEnable(true);
          }}
          color="secondary"
        />
      )}

      {successfullyChangeStockConfiguration && (
        <AlertDialog
          open={successfullyChangeStockConfiguration}
          title="Success"
          desc="Successfully change stock configuration."
          severity="success"
          confirmLabel="Ok"
          disableBackdropClick
          confirmAction={() => {
            setSuccessfullyChangeStockConfiguration(false);
          }}
          color="secondary"
        />
      )}
    </>
  );
};

export default withAuthority(
  StockConfiguration,
  Authorities.STOCK_CONFIGURATION_READ,
);
