import React, { useState } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import _ from "lodash";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { useRouteMatch } from "react-router-dom";
import { AxiosError } from "axios";

import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import { buttonColors } from "../../../../utils/enum";
import CreateNewChain from "./CreateNewChain";
import {
  createNewPostcode,
  removePostcode,
  updatePostcode,
} from "../../../../services/locationApp/chainConfiguration";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import {
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  SUCCESSFULLY_CREATED,
  SUCCESSFULLY_DELETED,
  SUCCESSFULLY_UPDATED,
} from "../../../../utils/consts";
import ChainRemoveModal from "./ChainRemoveModal";
import CardCommon from "../../../../components/card/CardCommon";
import EditPostCode from "./EditPostCode";

export interface ChainConfigurationNodeProps {
  postCodeNode: any;
  locationNode: any;
  setPostCodeNode: any;
  chainId: any;
  locationObj: any;
  primaryLocationId: any;
}

/**
 * ChainConfigurationNode:
 *
 * This React functional component handles the management and configuration of
 * postcodes for a location chain. It allows users to create, update, and delete
 * postcodes while maintaining synchronization with the backend.
 */

const ChainConfigurationNode: React.FunctionComponent<
  ChainConfigurationNodeProps
> = ({
  postCodeNode,
  locationNode,
  setPostCodeNode,
  chainId,
  locationObj,
  primaryLocationId,
}) => {
  const [openChainCreateModal, setOpenChainCreateModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [index, setIndex] = useState(0);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [isLoadingChain, setIsLoadingChain] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [selectedPostcode, setSelectedPostcode] = useState<any>({});
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [editPostCode, setEditPostCode] = useState<any>({});

  const match: any = useRouteMatch();

  /**
   * Opens the modal to create a new chain and resets the selected postcode.
   */
  const handleOpenCreateNewChain = () => {
    // Set the modal's open state to true to display the create chain modal.
    setOpenChainCreateModal(true);

    // Reset the selected postcode to an empty object.
    setSelectedPostcode({});
  };

  /**
   * Asynchronous function to handle the creation of new postcode chains for a specific location.
   * Manages loading state, API requests, state updates, and error handling.
   */
  const handleCreateNewChain = async (postCode: any) => {
    try {
      // Set loading state to true to indicate a process is ongoing.
      setIsLoadingChain(true);

      // Map the postCode array to a new format and prepare for the API request.
      const formattedData = postCode.map((postCodeItem: any) => ({
        postCode: postCodeItem,
        locationId: selectedPostcode.id,
      }));

      // Prepare the data object for the API request.
      const formData = { data: formattedData };
      // Make the API call to create new postcodes.
      await createNewPostcode(match.params.locationId, chainId, formData);
      // Deep clone the existing postCodeNode to avoid direct state mutation.
      const updatedPostCodeNode = _.cloneDeep(postCodeNode);
      // Append the newly created postcodes to the cloned postCodeNode.
      formattedData.forEach((dataItem: any) => {
        updatedPostCodeNode.push({
          postCode: dataItem.postCode,
          locationId: dataItem.locationId,
        });
      });

      // Update the postCodeNode state with the new data.
      setPostCodeNode(updatedPostCodeNode);

      // Set success message to indicate the operation was successful.
      setSuccess(SUCCESSFULLY_CREATED);

      // Reset loading state and close the create modal.
      setIsLoadingChain(false);
      setOpenChainCreateModal(false);
    } catch (error) {
      // Handle any errors that occurred during the process.
      const err: any = error as AxiosError;

      // Reset loading state.
      setIsLoadingChain(false);

      // Set error message with the received error.
      setError(err.response?.data?.message || ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Function to handle updating an existing postcode chain for a specific location.
   * - Manages loading state and updates the postcodeNode in the state upon successful update.
   * - Handles error scenarios by resetting loading state and displaying an error message.
   *
   * @param {string} postCode - The updated postcode value.
   * @param {any} prePostCode - The previous postcode value before the update.
   * @param {any} prevLocationId - The previous location ID before the update.
   */
  const handleUpdate = async (
    postCode: string,
    prePostCode: any,
    prevLocationId: any,
  ) => {
    try {
      // Set loading state to true to indicate a process is ongoing.
      setIsLoadingChain(true);

      // Create a deep copy of the existing postCodeNode to avoid direct state mutation.
      const updatedPostCodeNode = _.cloneDeep(postCodeNode);

      // Find the index of the postcode to be updated and replace its value with the new postcode and location ID.
      updatedPostCodeNode[index] = {
        postCode,
        locationId: selectedPostcode.id,
      };
      // Data object for the update API request.
      const data = {
        postcode: postCode,
        locationId: selectedPostcode.id,
        prevPostcode: prePostCode,
        prevLocationId: prevLocationId,
      };

      // Make the API call to update the postcode.
      await updatePostcode(match.params.locationId, chainId, data);

      // Update the state with the modified postCodeNode.
      setPostCodeNode(updatedPostCodeNode);

      // Close the edit modal.
      setIsOpenEditModal(false);

      // Reset loading state and set success message.
      setIsLoadingChain(false);
      setSuccess(SUCCESSFULLY_UPDATED);
    } catch (error) {
      // Reset loading state.
      setIsLoadingChain(false);

      // Set error message with a default message for unexpected errors.
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Handles the change event for selecting a postcode location.
   *
   * @param {any} event - The change event triggered by user interaction (e.g., selecting a postcode).
   * @param {any} chain - The chain object associated with the selected postcode.
   *
   * This function sets the `selectedPostcode` state with the provided chain,
   * indicating the currently selected postcode location.
   */
  const handleChangePostCodeLocation = (event: any, chain: any) => {
    setSelectedPostcode(chain);
  };

  /**
   * Opens the delete modal for a specific postcode.
   *
   * @param {any} data - The data object representing the postcode to be deleted.
   * @param {any} index - The index of the postcode in the list.
   *
   * This function sets the `isOpenDeleteModal` state to `true` to open the delete modal,
   * and also updates the `editPostCode` and `index` states with the provided data and index.
   */
  const handleOpenDeleteModal = async (data: any, index: any) => {
    setIsOpenDeleteModal(true); // Open the delete modal
    setEditPostCode(data); // Set the data to be deleted
    setIndex(index); // Set the index of the postcode to be deleted
  };

  /**
   * Opens the edit modal for a specific postcode.
   *
   * @param {any} data - The data object representing the postcode to be edited.
   * @param {any} index - The index of the postcode in the list.
   *
   * This function sets the `isOpenEditModal` state to `true` to open the edit modal,
   * and updates the `editPostCode` and `index` states with the provided data and index.
   * Additionally, it sets the `selectedPostcode` state to reflect the location details of the postcode to be edited.
   * The `locationObj` is used to fetch the label associated with the `locationId` of the postcode.
   */
  const handleOpenEditModal = async (data: any, index: any) => {
    setIsOpenEditModal(true); // Open the edit modal
    setEditPostCode(data); // Set the data to be edited
    setIndex(index); // Set the index of the postcode to be edited
    setSelectedPostcode({
      id: data.locationId, // Set the location ID
      label: locationObj[data.locationId].label, // Set the location label from locationObj based on the location ID
    });
  };

  /**
   * handleRemovePostcode - Asynchronously removes a specified postcode from the postCodeNode array
   * and updates the state accordingly.
   *
   * This function performs an API request to remove the postcode and, upon success, removes the postcode
   * from the `postCodeNode` state. If an error occurs during the process, an appropriate error message is set.
   *
   * @async
   * @function handleRemovePostcode
   */
  const handleRemovePostcode = async () => {
    try {
      // Set loading state to true indicating a process is ongoing.
      setIsLoadingUpdate(true);

      // Prepare the data object to be sent for the removal request.
      const data = { postcode: editPostCode.postCode };

      // Perform the API call to remove the postcode.
      await removePostcode(match.params.locationId, chainId, data);

      // Update the `postCodeNode` state by removing the specified postcode.
      setPostCodeNode((postCodeNode: any) =>
        postCodeNode.filter((_: any, idx: any) => idx !== index),
      );

      // Set success message indicating the removal was successful.
      setSuccess(SUCCESSFULLY_DELETED);

      // Close the delete modal and reset loading state.
      setIsOpenDeleteModal(false);
      setIsLoadingUpdate(false);
    } catch (error) {
      // Handle any errors that occur during the process.
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingUpdate(false);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4} style={{ display: "flex", justifyContent: "end" }}>
          <ButtonCommon
            onClick={handleOpenCreateNewChain}
            variant="contained"
            style={{
              fontSize: 11,
              height: "50px",
              width: "100%",
              textTransform: "none",
              marginRight: "12px",
            }}
            disabled={primaryLocationId !== match.params.locationId}
            color={buttonColors.CREATE_BUTTON_COLOR}
          >
            <Typography variant="h6" style={{ color: "white" }}>
              Add a new postcode
            </Typography>
          </ButtonCommon>
        </Grid>
      </Grid>
      {postCodeNode.length > 0 ? (
        <Grid container style={{ marginTop: "28px" }}>
          <Grid item xs={12}>
            <CardCommon
              backgroundColor={"table_header_background"}
              style={{ height: "40px" }}
            >
              <Grid container>
                <Grid item xs={6} style={{ paddingLeft: "12px" }}>
                  <Typography align="left" style={{ marginTop: "6px" }}>
                    Postcode
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ paddingRight: "12px" }}>
                  <Typography align="right" style={{ marginTop: "6px" }}>
                    Location
                  </Typography>
                </Grid>
              </Grid>
            </CardCommon>
          </Grid>
          {postCodeNode.map((data: any, index: number) => (
            <Grid item xs={12} style={{ marginTop: "4px" }}>
              <CardCommon
                backgroundColor={
                  index % 2 === 0
                    ? "entity_background"
                    : "entity_highlight_background"
                }
                style={{ height: "40px" }}
              >
                <Grid container>
                  <Grid item xs={6} style={{ paddingLeft: "12px" }}>
                    <Typography align="left" style={{ marginTop: "6px" }}>
                      {data.postCode}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      paddingRight: "12px",
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <Typography align="right" style={{ marginTop: "6px" }}>
                      {locationObj[data?.locationId]?.label || data?.locationId}
                    </Typography>
                    <Button
                      size="small"
                      onClick={() => handleOpenEditModal(data, index)}
                      style={{
                        marginLeft: "12px",
                        minWidth: "32px",
                        maxWidth: "32px",
                      }}
                      disabled={primaryLocationId !== match.params.locationId}
                    >
                      <EditIcon />
                    </Button>
                    <Button
                      onClick={() => handleOpenDeleteModal(data, index)}
                      size="small"
                      style={{
                        marginLeft: "12px",
                        minWidth: "32px",
                        maxWidth: "32px",
                      }}
                      disabled={primaryLocationId !== match.params.locationId}
                    >
                      <DeleteIcon />
                    </Button>
                  </Grid>
                </Grid>
              </CardCommon>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid item xs={12} style={{ marginTop: "10%" }}>
          <Typography
            variant="h4"
            style={{ display: "flex", justifyContent: "center" }}
          >
            No postcodes available
          </Typography>
        </Grid>
      )}
      <ChainRemoveModal
        isOpen={isOpenDeleteModal}
        setIsOpen={setIsOpenDeleteModal}
        handleRemovePostcode={handleRemovePostcode}
        isLoadingUpdate={isLoadingUpdate}
      />
      <CreateNewChain
        isOpen={openChainCreateModal}
        setIsOpen={setOpenChainCreateModal}
        handleCreate={handleCreateNewChain}
        isLoading={isLoadingChain}
        locationNode={locationNode}
        handleChangePostCodeLocation={handleChangePostCodeLocation}
        selectedPostcode={selectedPostcode}
        postCodeNode={postCodeNode}
      />
      <EditPostCode
        isOpen={isOpenEditModal}
        setIsOpen={setIsOpenEditModal}
        handleUpdate={handleUpdate}
        isLoading={isLoadingChain}
        locationNode={locationNode}
        handleChangePostCodeLocation={handleChangePostCodeLocation}
        selectedPostcode={selectedPostcode}
        postCodeNode={postCodeNode}
        editPostCode={editPostCode}
      />
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
      <DefaultAlert
        open={!!success}
        handleClose={() => setSuccess("")}
        message={success}
        severity={"success"}
      />
    </>
  );
};

export default ChainConfigurationNode;
