import { Button, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import _ from "lodash";

import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import withAuthority from "../../../../components/Auth/withAuthority";
import Authorities from "../../../../auth/authorities";
import CardCommon from "../../../../components/card/CardCommon";
import PermissionAddModal from "./PermissionAddModal";
import { useForm } from "react-hook-form";
import RolesDetailsHeader from "./RolesDetailsHeader";
import { useRouteMatch } from "react-router-dom";
import {
  fetchAllRoles,
  getUserDetailsUsingAccountId,
} from "../../../../services/locationApp/locationService";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import AlertDialog from "../../../../components/alerts/AlertDialog";
import firebase from "firebase";
import { AxiosError } from "axios";
import PasswordResetModal from "../../../../components/common/PasswordResetModal";
import InventoryDetailsDefault from "../../../../components/common/InventoryDetails/InventoryDetailsDefault";

export interface HomeProps {}
interface FormData {
  name: string;
  email: string;
  password: string;
}

/**
 * UsersAndPermission Component:
 * This React component represents the user interface for managing users and permissions
 * within a specific location. It integrates with various services, such as fetching role
 * information and handling user interactions. The component includes features like adding
 * users, paginating through role data, and displaying alerts for errors.
 *
 * Key Features:
 * - Add User button to open a modal for adding new users.
 * - Display role details header based on fetched data.
 * - Pagination controls for navigating through role data.
 * - Handling of alerts for unexpected errors.
 *
 * Components and Utilities Used:
 * - Material-UI components: Box, Button, Grid, Typography.
 * - Custom components: DefaultAlert, CardCommon, PermissionAddModal, RolesDetailsHeader,
 *   Pagination, AlertDialog.
 * - External libraries: lodash, react-hook-form, react-router-dom.
 *
 * State Variables:
 * - filteredRolesInfoNode: State to store role information fetched from the server.
 * - index, newPassword, isLoading, isOpenPermissionAddModal, isShowPasswordInput,
 *   isOpenErrorModal, totalPages, pageSize, errorMessage, currentPage: State variables
 *   used for managing various aspects of the component's behavior.
 *
 * Functions:
 * - getAllRoleInfo: Function to fetch role information based on the specified page size
 *   and current page number.
 * - handleOpenAddUserModal: Function to handle the opening of the add user modal.
 * - handleChangePaginationUsingSelector: Function to handle changes in pagination using
 *   the page size selector.
 * - handleChangePaginationUsingPageNumber: Function to handle changes in pagination using
 *   the page number.
 * - handleClose: Function to handle the closing of the error modal.
 * - handleGetUserDetails: Function to fetch user details using the account ID.
 * - handleConfirmPasswordReset: Function to handle the confirmation of password reset.
 * - handleResetPassword: Function to handle the reset of the user's password.
 *
 * Note: This component is wrapped with the 'withAuthority' higher-order component to check
 * for the necessary user authority before rendering.
 */
const UsersAndPermission: React.FunctionComponent<HomeProps> = () => {
  const [filteredRolesInfoNode, setFilteredRolesInfoNode] = useState<any>([]);
  const [index, setIndex] = useState(0);
  const [error, setError] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenPermissionAddModal, setIsOpenPermissionAddModal] =
    useState(false);
  const [isOpenPasswordResetModal, setIsOpenPasswordResetModal] =
    useState(false);
  const [isLoadingPasswordReset, setIsLoadingPasswordReset] = useState(false);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState("");
  const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false);
  const [updatedUserList, setUpdatedUserList] = useState<any>();
  const { register, handleSubmit, errors } = useForm<FormData>({
    defaultValues: {
      name: "",
      email: "",
      password: "",
    },
  });

  const match: any = useRouteMatch();

  /**
   * Function to fetch role information based on the specified page size and current page number.
   */
  const getAllRoleInfo = async (pageSize: any, currentPage: any) => {
    try {
      // Fetch roles using the 'fetchAllRoles' API
      const res = await fetchAllRoles(
        match.params.locationId,
        pageSize,
        currentPage,
      );

      // Filter out roles with the "ROLE_PREX" authority
      const filteredRoleList = res.data.data.filter(
        (data: any) => !data.authorities.includes("ROLE_PREX"),
      );

      if(_.isEmpty(filteredRoleList)) {
        setIsLoading(false);
      }

      // Update the state with the filtered role list
      setFilteredRolesInfoNode(filteredRoleList);
    } catch (error) {
      // Set error state if there's an issue with the API call
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
    }
  };

  /**
   * Function to fetch user details using the account ID.
   */
  const handleGetUserDetails = async (data: any): Promise<any> => {
    try {
      // Fetch user details using the 'getUserDetailsUsingAccountId' API
      const res = await getUserDetailsUsingAccountId(data.accountId);

      // Extract user details from the API response
      const userDetails = res.data.data;

      // Return the user details
      return userDetails;
    } catch (error) {}
  };

  /**
   * useEffect to Fetch User Details:
   *
   * This useEffect is responsible for fetching additional details for each user
   * in the filteredRolesInfoNode array. It utilizes the handleGetUserDetails function
   * to fetch user details asynchronously and updates the component's state accordingly.
   * The useEffect includes error handling and ensures that the component is still mounted
   * before updating the state to prevent memory leaks.
   *
   * Key Steps:
   * 1. Initialize a boolean flag 'isMounted' to track whether the component is still mounted.
   * 2. Define an asynchronous fetchData function to map through filteredRolesInfoNode,
   *    fetch user details, and update the state.
   * 3. Use Promise.all to wait for all promises to resolve and handle errors.
   * 4. Filter out null values in case fetching details failed for some users.
   * 5. Update the component's state with the new user details, ensuring the component is
   *    still mounted.
   * 6. Set isLoading to false after fetching and updating are completed.
   */
  useEffect(() => {
    // Flag to check if the component is still mounted
    let isMounted = true;

    // Function to fetch user details and update state
    const fetchData = async () => {
      // Map through filteredRolesInfoNode and create an array of promises
      const promises = filteredRolesInfoNode.map(async (data: any) => {
        try {
          // Fetch user details for each user
          const userDetails = await handleGetUserDetails(data);
          return { ...data, ...userDetails };
        } catch (error) {
          // Handle errors if fetching user details fails
          return null;
        }
      });

      try {
        // Wait for all promises to resolve
        const updatedUsers = await Promise.all(promises);

        // Filter out null values (for cases where fetching details failed)
        const filteredUsers = updatedUsers.filter(
          (user: any) => user !== null && user.email,
        );

        // Update the state only if the component is still mounted
        if (isMounted) {
          setUpdatedUserList((prevUserList: any) => [
            ...(prevUserList || []), // Initialize as empty array if undefined
            ...filteredUsers,
          ]);
        }
      } catch (error) {
      } finally {
        // Set isLoading to false after fetching and updating are completed
        setIsLoading(false);
      }
    };

    // Check if filteredRolesInfoNode is not empty, then fetch data
    if (!_.isEmpty(filteredRolesInfoNode)) {
      fetchData();
    }

    // Cleanup: Set isMounted to false when the component is unmounted
    return () => {
      isMounted = false;
    };
  }, [filteredRolesInfoNode]);

  /**
   * Initial Fetch of Role Information:
   *
   * This useEffect is responsible for fetching the initial set of role information
   * when the component mounts. It calls the getAllRoleInfo function with specified
   * parameters (pageSize: 20000, currentPage: 1) to retrieve the roles from the server.
   */
  useEffect(() => {
    // Call getAllRoleInfo with specified parameters to fetch initial role information
    getAllRoleInfo(20000, 1);
  }, []);

  /**
   * Open Add User Modal:
   *
   * This function is responsible for setting the state to open the modal for adding new users.
   * It sets the 'isOpenPermissionAddModal' state to 'true', triggering the display of the modal.
   */
  const handleOpenAddUserModal = () => {
    // Set the state to open the modal for adding new users
    setIsOpenPermissionAddModal(true);
  };

  /**
   * Get All Role Information:
   *
   * This asynchronous function is responsible for fetching user details using the 'handleGetUserDetails'
   * function and updating the state with the combined data. It sets the 'updatedUserList' state with the
   * new user information and displays a success message.
   */
  const getAllRoleInfos = async (data: any) => {
    // Fetch user details using 'handleGetUserDetails'
    const userDetails = await handleGetUserDetails(data);

    // Combine role data with user details
    const combine = { ...data, ...userDetails };

    // Filter out null values and ensure the user has an email
    const filteredUsers = [combine].filter(
      (user: any) => user !== null && user.email,
    );

    // Update the 'updatedUserList' state with the new user information

    setUpdatedUserList((prevUserList: any) => [
      ...(prevUserList || []), // Initialize as empty array if undefined
      ...filteredUsers,
    ]);

    // Display a success message
    setSuccess("Successfully Role added.");
  };

  /**
   * Handle Close:
   *
   * This function is responsible for closing the error modal by updating the state.
   * It sets the 'isOpenErrorModal' state to false.
   */
  const handleClose = () => {
    // Set the 'isOpenErrorModal' state to false, closing the error modal
    setIsOpenErrorModal(false);
  };

  /**
   * Handle Close Success Modal:
   *
   * This function is responsible for closing the success modal by updating the state.
   * It sets the 'isOpenSuccessModal' state to false.
   */
  const handleCloseSuccessModal = () => {
    // Set the 'isOpenSuccessModal' state to false, closing the success modal
    setIsOpenSuccessModal(false);
  };

  /**
   * Handle Confirm Password Reset:
   *
   * This function is responsible for initiating the password reset process using
   * Firebase authentication. It updates the loading state, sends a password reset
   * email, and manages modal states based on success or error.
   */
  const handleConfirmPasswordReset = async () => {
    // Set 'isLoadingPasswordReset' state to true, indicating the start of the process
    setIsLoadingPasswordReset(true);
    try {
      // Attempt to send a password reset email using Firebase authentication
      await firebase.auth().sendPasswordResetEmail(email);

      // If successful, close the password reset modal, open the success modal, and update states
      setIsOpenPasswordResetModal(false);
      setIsOpenSuccessModal(true);
      setIsLoadingPasswordReset(false);
    } catch (error) {
      // If an error occurs, set the 'error' state with the error message
      const err: any = error as AxiosError;
      setError(err.message);
    }
  };

  /**
   * Handle Reset Password:
   *
   * This function is responsible for initiating the process of resetting a user's password.
   * It opens the password reset modal, sets the provided email, and prepares for the user to
   * confirm the password reset.
   */
  const handleResetPassword = (email: any) => {
    // Open the password reset modal
    setIsOpenPasswordResetModal(true);

    // Set the provided email for the password reset process
    setEmail(email);
  };

  return (
    <>
      <InventoryDetailsDefault
          locationSelectorList={[]}
          isOpenSkeletonLoading={isLoading}
          topic=""
          isNeedAddButton={false}
          nodeList={[1]}
          isActiveLocationId={true}
        >
      <Grid container style={{  width: "340px" }} spacing={2}>
        <Grid item xs={12} style={{ maxWidth: "160px" }}>
          <CardCommon color={"green"}>
            <Button onClick={handleOpenAddUserModal} style={{ width: "100%" }}>
              <Typography style={{ margin: "4px 0px", color: "white" }}>
                Add User
              </Typography>
            </Button>
          </CardCommon>
        </Grid>
      </Grid>
      <RolesDetailsHeader
        nodeData={updatedUserList}
        isLoading={isLoading}
        handleResetPassword={handleResetPassword}
        filteredRolesInfoNode={filteredRolesInfoNode}
      />
      </InventoryDetailsDefault>
      <PermissionAddModal
        isOpenPermissionAddModal={isOpenPermissionAddModal}
        setIsOpenPermissionAddModal={setIsOpenPermissionAddModal}
        handleSubmit={handleSubmit}
        errors={errors}
        register={register}
        index={index}
        setIndex={setIndex}
        setIsOpenErrorModal={setIsOpenErrorModal}
        setErrorMessage={setErrorMessage}
        setNewPassword={setNewPassword}
        getAllRoleInfo={getAllRoleInfos}
        newPassword={newPassword}
        updatedUserList={updatedUserList}
        setSuccess={setSuccess}
        setError={setError}
      />
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
      <DefaultAlert
        open={!!success}
        handleClose={() => setSuccess("")}
        message={success}
        severity="success"
      />
      <AlertDialog
        open={isOpenErrorModal}
        confirmAction={handleClose}
        title="Error"
        desc={errorMessage}
        severity="error"
        confirmLabel="Ok"
        disableBackdropClick
        color={"secondary"}
      />
      <AlertDialog
        open={isOpenSuccessModal}
        confirmAction={handleCloseSuccessModal}
        title="Success"
        desc={"A password reset link has been sent to your email address."}
        severity="success"
        confirmLabel="Ok"
        disableBackdropClick
        color={"secondary"}
      />
      <PasswordResetModal
        open={isOpenPasswordResetModal}
        email={email}
        setOpen={setIsOpenPasswordResetModal}
        isLoading={isLoadingPasswordReset}
        handleConfirmPasswordReset={handleConfirmPasswordReset}
      />
    </>
  );
};

export default withAuthority(
  UsersAndPermission,
  Authorities.USERS_PERMISSION_READ,
);
