import Axios from "axios";
import { axiosClient } from "../client";
const queryString = require("query-string");

const API_CHAIN_CREATE = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_CHAIN}/location/${locationId}/addChain`;

const API_POSTCODE_CREATE = (locationId, chainId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_CHAIN}/postcode-lookup/location/${locationId}/chain/${chainId}/add`;

const API_POSTCODE_UPDATE = (locationId, chainId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_CHAIN}/postcode-lookup/location/${locationId}/chain/${chainId}/update`;

const API_CHAIN_CONNECT = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_CHAIN}/location/${locationId}/connectChain`;

const API_CHAIN_UPDATE = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_CHAIN}/location/${locationId}/updateChain`;

const API_CHAIN_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_CHAIN}/location/${locationId}/getAllChain`;

const API_CHAIN_LOCATION_GET = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_CHAIN}/location/${locationId}/getChainLocationFromLocationId`;

const API_CHAIN_GET_CURRENT_LOCATION = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_CHAIN}/location/${locationId}/getChainCurrentLocation`;

const API_POSTCODE_GET = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_CHAIN}/location/${locationId}/postCodeLookUp`;

const API_CHAIN_DELETE = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_CHAIN}/location/${locationId}/removeChain`;

const API_POSTCODE_DELETE = (locationId, chainId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_CHAIN}/postcode-lookup/location/${locationId}/chain/${chainId}/postcode`;

export const removePostcode = (locationId, chainId,data) =>
  axiosClient({
    method: "DELETE",
    url: queryString.stringifyUrl({
      url: API_POSTCODE_DELETE(locationId, chainId),
    }),
    data
  });

export const removeChain = (locationId) =>
  axiosClient({
    method: "DELETE",
    url: queryString.stringifyUrl({
      url: API_CHAIN_DELETE(locationId),
    }),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const fetchAllChain = (locationId) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl({
      url: API_CHAIN_GET_ALL(locationId),
    }),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const fetchChainLocation = (locationId) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl({
      url: API_CHAIN_LOCATION_GET(locationId),
    }),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const fetchChainCurrentLocation = (locationId) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl({
      url: API_CHAIN_GET_CURRENT_LOCATION(locationId),
    }),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const fetchPostCodeLookUp = (locationId) =>
  axiosClient({
    method: "GET",
    url: queryString.stringifyUrl({
      url: API_POSTCODE_GET(locationId),
    }),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });

export const createNewChain = (locationId, data) => {
  return axiosClient({
    method: "POST",
    url: API_CHAIN_CREATE(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
    data,
  });
};

export const createNewPostcode = (locationId, chainId, data) => {
  return axiosClient({
    method: "POST",
    url: API_POSTCODE_CREATE(locationId, chainId),
    data,
  });
};

export const updatePostcode = (locationId, chainId, data) => {
  return axiosClient({
    method: "POST",
    url: API_POSTCODE_UPDATE(locationId, chainId),
    data,
  });
};

export const connectChain = (locationId, data) => {
  return axiosClient({
    method: "POST",
    url: API_CHAIN_CONNECT(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
    data,
  });
};

export const updateChain = (locationId, data) => {
  return axiosClient({
    method: "PUT",
    url: API_CHAIN_UPDATE(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
    data,
  });
};
