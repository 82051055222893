import React, { useState } from "react";
import {
  createStyles,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import _ from "lodash";

import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import { buttonColors } from "../../../../utils/enum";
import { useRouteMatch } from "react-router-dom";
import CreateNewChain from "./CreateNewChain";
import ConnectExistingChainModal from "./ConnectExistingChainModal";
import {
  connectChain,
  createNewChain,
  removeChain,
  updateChain,
} from "../../../../services/locationApp/chainConfiguration";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import {
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  SUCCESSFULLY_CREATED,
  SUCCESSFULLY_DELETED,
  SUCCESSFULLY_UPDATED,
} from "../../../../utils/consts";
import ChainDetails from "./ChainDetails";
import ChainRemoveModal from "./ChainRemoveModal";

export interface StockConfigurationNodeProps {
  chainNode: any;
  currentChain: any;
  setCurrentChain: any;
}

/**
 * Component: ChainConfigurationNode
 *
 * Description:
 * This component represents a node in the Stock Configuration page.
 * It provides controls for managing stock-related configurations, such as enabling/disabling stock,
 * enabling/disabling master location, selecting the department, and saving changes.
 **/

const ChainConfigurationNode: React.FunctionComponent<
  StockConfigurationNodeProps
> = ({ chainNode, currentChain, setCurrentChain }) => {
  const [openChainCreateModal, setOpenChainCreateModal] = useState(false);
  const [isOpenConnectExistingChainModal, setIsOpenConnectExistingChainModal] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [isLoadingChain, setIsLoadingChain] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [selectedChain, setSelectedChain] = useState<any>({});
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const match: any = useRouteMatch();

  const handleOpenCreateNewChain = () => {
    setOpenChainCreateModal(true);
  };

  const handleCreateNewChain = async (name: string) => {
    try {
      setIsLoadingChain(true);
      const data = { name, primary_location: match.params.locationId };
      const res = await createNewChain(match.params.locationId, data);
      setCurrentChain(res.data.data);
      setSuccess(SUCCESSFULLY_CREATED);
      setIsLoadingChain(false);
      setOpenChainCreateModal(false);
    } catch (error) {
      setIsLoadingChain(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  const handleOpenConnectExistingChainModal = () => {
    setIsOpenConnectExistingChainModal(true);
  };

  const handleChangeMasterChain = (event: any, chain: any) => {
    setSelectedChain(chain);
  };

  const handleConnectExistingChain = async () => {
    try {
      setIsLoading(true);

      const data = { chainId: selectedChain.id };
      const res = await connectChain(match.params.locationId, data);
      setCurrentChain(res.data.data);
      setSuccess(SUCCESSFULLY_CREATED);
      setSelectedChain({});
      setIsOpenConnectExistingChainModal(false);
      setIsLoading(false);
    } catch (error) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
    }
  };

  const handleUpdate = async (data: any) => {
    try {
      setIsLoadingUpdate(true);

      const res = await updateChain(match.params.locationId, data);
      setSuccess(SUCCESSFULLY_UPDATED);
      setCurrentChain(res.data.data);
      setIsLoadingUpdate(false);
    } catch (error) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingUpdate(false);
    }
  };

  const handleRemoveChain = async (data: any) => {
    try {
      setIsLoadingUpdate(true);

      const res = await removeChain(match.params.locationId);
      setSuccess(SUCCESSFULLY_DELETED);
      setCurrentChain({});
      setIsOpenAlert(false);
      setIsLoadingUpdate(false);
    } catch (error) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingUpdate(false);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          {Object.values(currentChain)?.length === 0 ? (
            <ButtonCommon
              onClick={handleOpenCreateNewChain}
              variant="contained"
              style={{
                fontSize: 11,
                height: "60px",
                width: "100%",
                textTransform: "none",
                marginRight: "12px",
              }}
              color={buttonColors.CREATE_BUTTON_COLOR}
            >
              <Typography variant="h6" style={{ color: "white" }}>
                Create a new chain{" "}
              </Typography>
            </ButtonCommon>
          ) : (
            <ChainDetails
              handleUpdate={handleUpdate}
              isLoading={isLoadingUpdate}
              currentChain={currentChain}
              setIsOpenAlert={setIsOpenAlert}
            />
          )}
        </Grid>
        <Grid item xs={4}>
          <ButtonCommon
            onClick={handleOpenConnectExistingChainModal}
            variant="contained"
            style={{
              fontSize: 11,
              height: "60px",
              width: "100%",
              textTransform: "none",
            }}
            disabled={
              currentChain?.["primary_location"] === match.params.locationId ||
              Object.values(currentChain)?.length >0
            }
            color={buttonColors.CREATE_BUTTON_COLOR}
          >
            <Typography variant="h6" style={{ color: "white" }}>
              Link to an existing chain
            </Typography>
          </ButtonCommon>
        </Grid>
      </Grid>

      <ChainRemoveModal
        isOpen={isOpenAlert}
        setIsOpen={setIsOpenAlert}
        handleRemoveChain={handleRemoveChain}
        isLoadingUpdate={isLoadingUpdate}
      />

      <CreateNewChain
        isOpen={openChainCreateModal}
        setIsOpen={setOpenChainCreateModal}
        handleCreate={handleCreateNewChain}
        isLoading={isLoadingChain}
      />

      <ConnectExistingChainModal
        isOpen={isOpenConnectExistingChainModal}
        setIsOpen={setIsOpenConnectExistingChainModal}
        handleCreate={handleConnectExistingChain}
        chainNode={chainNode}
        selectedChain={selectedChain}
        handleChangeMasterChain={handleChangeMasterChain}
        isLoading={isLoading}
        setSelectedChain={setSelectedChain}
      />

      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />

      <DefaultAlert
        open={!!success}
        handleClose={() => setSuccess("")}
        message={success}
        severity={"success"}
      />
    </>
  );
};

// Apply the WithLoading higher-order component to the ChainConfigurationNode component
export default ChainConfigurationNode;
