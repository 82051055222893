import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import { Box } from "@material-ui/core";
import _ from "lodash";

import withAuthority from "../../../../components/Auth/withAuthority";
import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import { fetchAllFilterLocations } from "../../../../services/locationApp/locationFilterService";
import InventoryDetailsDefault from "../../../../components/common/InventoryDetails/InventoryDetailsDefault";
import AlertDialog from "../../../../components/alerts/AlertDialog";
import { fetchAllScheduledTask } from "../../../../services/locationApp/scheduledTaskConfiguration";
import ScheduleTaskConfigurationNode from "./ScheduleTaskConfigurationNode";

interface Location {
  id: string;
  businessDisplayName: string;
}

/**
 * ScheduledTaskConfiguration Component
 *
 * This component is responsible for managing and displaying scheduled task configurations for different business locations.
 * It uses Material-UI components for UI elements and hooks for state management. The component fetches location filters and scheduled tasks from
 * respective services, and it displays them along with appropriate success and error alerts.
 */
const ScheduledTaskConfiguration: React.FunctionComponent = () => {
  const [error, setError] = useState("");
  const [scheduleTaskNode, setScheduleTaskNode] = useState<any>([]);
  const [
    successfullyChangeStockConfiguration,
    setSuccessfullyChangeStockConfiguration,
  ] = useState(false);
  const [locationSelectorList, setLocationSelectorList] = useState([]);
  const [isOpenSkeletonLoading, setIsOpenSkeletonLoading] = useState(true);
  const [success, setSuccess] = useState("");

  const match: any = useRouteMatch();

  useEffect(() => {
    // Set the document title when the component mounts
    document.title = "Location - Scheduled Task Configuration";

    // Fetch all filter locations when the component mounts
    getAllFilterLocation();
  }, []); // Empty dependency array ensures this effect runs only once on component mount

  /**
   * Function to fetch all scheduled tasks for the current location from the server.
   *
   * This function performs an API request to fetch scheduled tasks based on the location ID retrieved from the route parameters.
   * It updates the component state with the fetched task data or sets an error message if the request fails.
   * The function also manages the loading state for a skeleton UI to indicate data is being loaded.
   */
  const handleGetAllScheduledTask = async () => {
    try {
      // Fetch scheduled tasks from the server for the specified location ID
      const res = await fetchAllScheduledTask(match.params.locationId);

      // Check the status code from the response
      if (res.data?.status === 404) {
        // If status code is not 200, set the task list to an empty array
        setScheduleTaskNode([]);
      } else {
        // If status code is 200, set the task list with the fetched data
        setScheduleTaskNode(res.data);
      }

      // Disable skeleton loading indicator after data is loaded
      setIsOpenSkeletonLoading(false);
    } catch (error) {
      // If an error occurs during the fetch, set an error message
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);

      // Disable skeleton loading indicator
      setIsOpenSkeletonLoading(false);
    }
  };

  /**
   * Function to fetch all filter locations.
   *
   * This function performs an API request to fetch all available filter locations.
   * It constructs a list of location options based on the fetched data, including the master location and other business locations.
   * The function then triggers another action to fetch scheduled tasks and updates the component's state with the list of locations.
   *
   * If the request fails, an error message is set and the skeleton loading indicator is disabled.
   *
   * @async
   * @function getAllFilterLocation
   */
  const getAllFilterLocation = async () => {
    try {
      // Fetch all filter locations from the server
      const res = await fetchAllFilterLocations("");

      let locationList: any = [];

      // Check if there are any locations returned in the response
      if (!_.isEmpty(res.data.data)) {
        // Add the master location to the list
        locationList.push({
          id: match.params.locationId,
          label: "Master Location Not Required",
        });

        // Iterate over each location data and add them to the list
        res.data.data.forEach((locationData: Location) => {
          locationList.push({
            id: locationData.id,
            label: locationData.businessDisplayName,
          });
        });
      }

      // Fetch all scheduled tasks for the current location
      handleGetAllScheduledTask();

      // Update the state with the constructed list of locations
      setLocationSelectorList(locationList);
    } catch (error) {
      // If an error occurs during the fetch, set an error message
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);

      // Disable skeleton loading indicator
      setIsOpenSkeletonLoading(false);
    }
  };

  return (
    <>
      <Box>
        <InventoryDetailsDefault
          locationSelectorList={locationSelectorList}
          isOpenSkeletonLoading={isOpenSkeletonLoading}
          topic=""
          isNeedAddButton={false}
          nodeList={[1]}
          isActiveLocationId={true}
        >
          <ScheduleTaskConfigurationNode
            scheduleTaskNode={scheduleTaskNode}
            setScheduleTaskNode={setScheduleTaskNode}
          />
        </InventoryDetailsDefault>
        <DefaultAlert
          open={!!success}
          handleClose={() => setSuccess("")}
          message={success}
          severity={"success"}
        />
        <DefaultAlert
          open={!!error}
          handleClose={() => setError("")}
          message={error}
          severity="error"
        />
      </Box>

      {successfullyChangeStockConfiguration && (
        <AlertDialog
          open={successfullyChangeStockConfiguration}
          title="Success"
          desc="Successfully change stock configuration."
          severity="success"
          confirmLabel="Ok"
          disableBackdropClick
          confirmAction={() => {
            setSuccessfullyChangeStockConfiguration(false);
          }}
          color="secondary"
        />
      )}
    </>
  );
};

export default withAuthority(
  ScheduledTaskConfiguration,
  Authorities.STOCK_CONFIGURATION_READ,
);
