import React, {  useState } from "react";
import {
  CircularProgress,
  createStyles,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import _ from "lodash";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import DialogCommonDefault from "../../../../components/dialogs/DialogCommonDefault";
import { buttonColors } from "../../../../utils/enum";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    autoComplete: {
      marginTop: "4px",
      "& .MuiAutocomplete-input": {
        color: theme.palette.custom.orange.contrastText,
      },
      "& .MuiInputBase-root": {
        color: "inherit",
        backgroundColor: theme.palette.background.entity_background,
        borderRadius: 10,
      },
      [`& fieldset`]: {
        borderRadius: 10,
        border: "none",
        color: theme.palette.custom.orange.contrastText,
      },
    },
    titleColor: {
      color: theme.palette.custom.orange.contrastText,
    },
    textField: {
      overflowWrap: "break-word",
      wordWrap: "break-word",
      backgroundColor: theme.palette.background.entity_highlight_background,
      borderRadius: "10px",
      border: "none",
      [`& fieldset`]: {
        borderRadius: "10px",
        border: "none",
        cursor: "pointer",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
  }),
);

export interface StockLevelModalProps {
  isOpen: any;
  setIsOpen: any;
  handleCreate: any;
  chainNode: any;
  selectedChain: any;
  handleChangeMasterChain: any;
  isLoading: any;
  setSelectedChain: any
}

const ConnectExistingChainModal: React.FC<StockLevelModalProps> = ({
  isOpen,
  setIsOpen,
  handleCreate,
  chainNode,
  selectedChain,
  handleChangeMasterChain,
  isLoading,
  setSelectedChain
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  return (
    <DialogCommonDefault
      open={isOpen}
      setOpen={setIsOpen}
      isNeedFixedHeight={false}
      backgroundColor="entity_background"
      maxWidth="xs"
    >
      <DialogTitle>Link to an existing chain </DialogTitle>
      <DialogContent>
        <Autocomplete
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          size={"small"}
          id="locationSelectGlobal"
          color="inherit"
          options={chainNode}
          value={selectedChain}
          getOptionLabel={(option: any) => option.name || ""}
          disableClearable
          onChange={handleChangeMasterChain}
          classes={{ root: classes.autoComplete }}
          popupIcon={<ArrowDropDownIcon color={"inherit"} />}
          renderOption={(props: any) => {
            return (
              <Grid
                container
                {...props}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  zIndex: 0,
                  height: "20px",
                  marginBottom: "4px",
                }}
              >
                <Grid item xs={12}>
                  <Typography variant="body2" className={classes.titleColor}>
                    {props.name}
                  </Typography>
                </Grid>
              </Grid>
            );
          }}
          renderInput={(params: any) => (
            <TextField
              color="inherit"
              {...params}
              label=""
              placeholder="Select Department"
              variant="outlined"
            />
          )}
        />
      </DialogContent>
      <DialogActions style={{ marginBottom: "12px" }}>
        <ButtonCommon
          onClick={() => {setIsOpen(false); setSelectedChain({})}}
          variant="contained"
          disabled={isLoading}
          style={{ fontSize: 11, width: "120px", marginRight: "12px" }}
          color={buttonColors.CANCEL_BUTTON_COLOR}
        >
          Cancel
        </ButtonCommon>

        <ButtonCommon
          onClick={handleCreate}
          variant="contained"
          disabled={Object.values(selectedChain).length===0 || isLoading}
          style={{ fontSize: 11, width: "120px", marginRight: "14px", color: "white" }}
          color={buttonColors.CREATE_BUTTON_COLOR}
        >
          {isLoading ? (
            <CircularProgress size={20} color="secondary" />
          ) : (
            "Link"
          )}
        </ButtonCommon>
      </DialogActions>
    </DialogCommonDefault>
  );
};

export default ConnectExistingChainModal;
