import React, { useEffect, useRef, useState } from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Hidden,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useRouteMatch } from "react-router-dom";
import _ from "lodash";

import AddStockItems from "./AddStockItems";
import {
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  SUCCESSFULLY_CREATED,
  SUCCESSFULLY_DELETED,
  SUCCESSFULLY_UPDATED,
} from "../../utils/consts";
import {
  createStockDepartments,
  createStockGroups,
  createStockItemsInfo,
  createSupplier,
  createSupplierForStockItem,
  fetchGetAllStockDepartments,
  fetchStockItemInfoGetSingle,
  supplierMap,
  updateStockItemsInfo,
} from "../../services/inventory/stockItems";
import AddSupplierModal from "./AddSupplierModal";
import { deleteStockSupplierMap } from "../../services/inventory/supplier";
import { areArraysEqual, areObjectsEqual } from "../../utils/commonArrayMap";
import DialogCommonOverflow from "../dialogs/DialogOverflowContainer";
import { HttpStatus, buttonColors } from "../../utils/enum";
import ButtonCommon from "../buttons/ButtonCommon";
import StockGroupAddModal from "./StockGroupAddModal";
import StockDepartmentAddModal from "./StockDepartmentAddModal";
import ConditionalModal from "./ConditionalModal";

export interface AddStockItemsModalProps {
  isOpenCreateStockItemsModal: any;
  setIsOpenCreateStockItemsModal: any;
  setError: any;
  setIsCreated: any;
  isCreated: any;
  handleGetStockItemDetails: any;
  isOpenEditStockItemsModal: any;
  selectedNodeData: any;
  id: any;
  setStockMappingDetailsNode: any;
  handleOpenEditMapProductsItemsModal: any;
  setSelectedNodeData: any;
  supplierDetailsNode: any;
  initialSelectedSupplierList: any;
  getAllStockDepartmentForGroupNotLoad: any;
  setGroupId: any;
  getAllStockGroup: any;
  selectedGroupObj: any;
  setSelectedGroupObj: any;
  stockGroupSelectedNode: any;
  selectedDepartmentIdInMenu: any;
  handleGetSupplierInfo: any;
  handleGetSelectedSupplier?: any;
  setCreatedId?: any;
  setIsLoadingToast: any;
  setToastType: any;
  setToastMessage: any;
  isLoadingToast: any;
  handleChangeSearchSupplier: any;
  handleClearSearchSupplier: any;
  searchName: any;
  isSearch: any;
  setSearchName: any;
  setSearchNameTab: any;
  setIsSearch: any;
  displaySuffix: any;
  isGetApi: any;
  isOpenNewModal: any;
  setIsOpenNewModal: any;
  setDisplaySuffix: any;
  setIsOpenMapProductsEditModal: any;
  setNameMap: any;
  isEditLoading: any;
  setIsEditLoading: any;
}

interface FormData {
  showRemainderSep: any;
  autoUnitConvert: boolean;
  id: string;
  barcode: string;
  criticalThreshold: any;
  displaySuffix: string;
  inputMultiplier: any;
  inputSuffix: string;
  name: string;
  sku: string;
  warningThreshold: any;
  active: Boolean;
  primaryLocationId: string;
  version: number;
  stockDepartmentId: any;
}

/**
 * This is a React functional component called 'AddStockItemsModal' that is part of a larger
 * application. It provides a user interface for adding or updating stock items, departments, and groups.
 * The component includes functionality for handling user inputs, making API requests, and rendering
 * different modals based on conditions.
 *
 * The code is organized into various sections, including state management, event handlers, API calls,
 * and JSX rendering. It makes use of React hooks and components to create a dynamic and interactive
 * user interface.
 *
 * Please note that this code represents a portion of a larger application and may require additional
 * context and dependencies to function properly in a real-world application.
 */
const AddStockItemsModal: React.FunctionComponent<AddStockItemsModalProps> = ({
  isOpenCreateStockItemsModal,
  setIsOpenCreateStockItemsModal,
  setError,
  setIsCreated,
  isCreated,
  handleGetStockItemDetails,
  isOpenEditStockItemsModal,
  selectedNodeData,
  id,
  setStockMappingDetailsNode,
  handleOpenEditMapProductsItemsModal,
  setSelectedNodeData,
  supplierDetailsNode,
  initialSelectedSupplierList,
  getAllStockDepartmentForGroupNotLoad,
  setGroupId,
  getAllStockGroup,
  selectedGroupObj,
  setSelectedGroupObj,
  stockGroupSelectedNode,
  selectedDepartmentIdInMenu,
  handleGetSupplierInfo,
  handleGetSelectedSupplier,
  setCreatedId,
  setIsLoadingToast,
  setToastType,
  setToastMessage,
  isLoadingToast,
  handleChangeSearchSupplier,
  handleClearSearchSupplier,
  searchName,
  isSearch,
  setSearchName,
  setSearchNameTab,
  setIsSearch,
  displaySuffix,
  isGetApi,
  isOpenNewModal,
  setIsOpenNewModal,
  setDisplaySuffix,
  setIsOpenMapProductsEditModal,
  setNameMap,
  isEditLoading,
  setIsEditLoading,
}) => {
  const [open, setOpen] = useState(false);
  const [outputMultiplierObj, setOutputMultiplierObj] = useState<any>({});
  const [outputMultiplierObjInitial, setOutputMultiplierObjInitial] =
    useState<any>({});
  const [stockItemId, setStockItemId] = useState("");
  const [checkedProductIdList, setCheckedProductIdList] = useState<any>([]);
  const [checkedProductIdListInitial, setCheckedProductIdListInitial] =
    useState<any>([]);
  const [selectedSupplierList, setSelectedSupplierList] = useState<any>([]);
  const [selectedSupplierListInitial, setSelectedSupplierListInitial] =
    useState<any>([]);
  const [stockDepartmentSelectedNode, setStockDepartmentSelectedNode] =
    useState<any>([]);
  const [selectedDepartmentObj, setSelectedDepartmentObj] = useState<any>({});
  const [selectedDepartmentIdInitial, setSelectedDepartmentIdInitial] =
    useState<any>({});
  const [isOpenDepartmentAddModal, setIsOpenDepartmentAddModal] =
    useState(false);
  const [isOpenSupplierAddModals, setIsOpenSupplierAddModals] = useState(false);
  const [name, setName] = useState("");
  const [departmentName, setDepartmentName] = useState("");
  const [isOpenGroupAddModal, setIsOpenGroupAddModal] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [initialName, setInitialName] = useState("");
  const [isAutoConversion, setIsAutoConversion] = useState(true);
  const [isShowSeparate, setIsShowSeparate] = useState(false);
  const [isAutoConversionInitial, setIsAutoConversionInitial] = useState(true);
  const [isShowSeparateInitial, setIsShowSeparateInitial] = useState(true);
  const [isLoad, setIsLoad] = useState(true);
  const match: any = useRouteMatch();

  const {
    register,
    handleSubmit,
    errors,
    reset,
    formState: { isDirty },
  } = useForm<FormData>({
    defaultValues: {
      barcode: "",
      criticalThreshold: "",
      displaySuffix: "",
      inputMultiplier: "",
      inputSuffix: "",
      name: "",
      sku: "",
      warningThreshold: "",
      stockDepartmentId: "",
    },
  });

  /**
   * useEffect to set the initial name when the 'isOpenCreateStockItemsModal' prop changes.
   * This ensures the initial name is updated when the modal opens.
   */
  useEffect(() => {
    setInitialName(selectedNodeData.name);
  }, [isOpenCreateStockItemsModal]);

  /**
   * This function, 'getAllStockDepartments', is responsible for fetching a list of stock departments
   * from an API endpoint and updating the component's state based on the response.
   *
   * @param {any} id - An optional parameter representing the ID of a specific department to retrieve.
   * @returns {Promise<void>} - This function is asynchronous and does not return a value.
   */
  const getAllStockDepartments = async (id?: any): Promise<void> => {
    try {
      // Attempt to fetch stock department information using the 'fetchGetAllStockDepartments' API function
      const res = await fetchGetAllStockDepartments(match.params.locationId);
      // Iterate through the department data to find the one with the matching 'id' (if provided)

      // Check if the response contains data and update the component's state accordingly
      if (res?.data?.data) {
        res.data.data.map((data: any) => {
          if (data.id === id) {
            setSelectedDepartmentObj(data);
          }
        });
        setStockDepartmentSelectedNode(res.data.data);
      } else {
        // If no data is available, set an empty array for 'stockDepartmentSelectedNode'
        setStockDepartmentSelectedNode([]);
      }
    } catch (err) {
      // If an error occurs during the API call, set the 'error' state with an unexpected error message
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * The 'useEffect' hook is used to execute code after the component has rendered.
   * In this case, it's used to fetch stock department information when the component mounts.
   * The empty dependency array '[]' ensures that this effect runs only once, equivalent to componentDidMount.
   */
  useEffect(() => {
    // Call the 'getAllStockDepartments' function to fetch stock department information
    // using the 'selectedDepartmentIdInMenu' as an optional parameter to select a specific department.
    if (isGetApi) {
      getAllStockDepartments(selectedDepartmentIdInMenu);
    }
  }, [isGetApi]);

  /**
   * This useEffect hook is responsible for synchronizing the selected supplier list
   * and the initial selected supplier list based on a specified 'id'. It clones the
   * selected supplier list to avoid mutations, iterates through the initial list,
   * and adds suppliers to the cloned list if they match the provided 'id'. The
   * selected supplier list and initial selected supplier list are updated accordingly.
   * This effect runs whenever the 'initialSelectedSupplierList' prop changes.
   */
  useEffect(() => {
    // Check if the initialSelectedSupplierList prop exists and is not undefined
    if (initialSelectedSupplierList && isEditLoading && isLoad) {
      // Clone the selected supplier list to avoid mutating the original array.
      const cloneSelectedSupplierList = _.cloneDeep(selectedSupplierList);

      // Iterate through the initial selected supplier list and add suppliers to the cloned list if they match the 'id'.
      initialSelectedSupplierList.forEach((data: any) => {
        // Check if the stockItemId's id matches the provided 'id'
        if (data.stockItemId.id === id) {
          // If there's a match, add the supplierId's id to the cloned list
          cloneSelectedSupplierList.push(data.supplierId.id);
        }
      });
      // Update the selected supplier list with the cloned and modified list.
      setSelectedSupplierList(cloneSelectedSupplierList);

      // Update the initial selected supplier list as well, if needed.
      setSelectedSupplierListInitial(cloneSelectedSupplierList);
    }
  }, [initialSelectedSupplierList, isEditLoading]);

  const handleCreateStockItems = async (updatedStockItem: any) => {
    setError("");
    try {
      // Make an API call to create the stock item
      const res = await createStockItemsInfo(
        match.params.locationId,
        updatedStockItem,
      );
      if (res.data.data) {
        setIsOpenNewModal(true);
        // Extract and store the name of the created stock item
        const createdStockItemName = res.data.data.name;
        setName(createdStockItemName);
        setNameMap(createdStockItemName);
        setDisplaySuffix(res.data.data.displaySuffix);
        // Handle creating suppliers for the created stock item
        handleCreateSuppliersForStockItem(res.data.data.id);

        // Store the ID of the created stock item
        setCreatedId(res.data.data);

        // Store the stock item ID
        setStockItemId(res.data.data.id);

        // Extract and store the group ID
        const stockGroupId = res.data.data.stockDepartmentId.stockGroupId.id;
        setGroupId(stockGroupId);

        // Fetch all stock departments for the group without loading
        getAllStockDepartmentForGroupNotLoad(
          res.data.data.stockDepartmentId.stockGroupId.id,
          "",
          "",
        );

        // Set a flag to indicate that the stock item was successfully created
        setIsCreated(true);
      } else {
        // If an error occurs during the API call, set the 'error' state with an unexpected error message
        // setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
        setIsLoadingToast(false);
        setToastMessage(res?.data?.error);
        setToastType("error");
      }
    } catch (error) {
      // If an error occurs during the API call, set the 'error' state with an unexpected error message
      // setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingToast(false);
      setToastMessage(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setToastType("error");
    }
  };

  /**
   * Delete supplier details. This function is responsible for deleting a supplier's details
   * associated with a specific stock item.
   *
   * @param {string} supplierId - The ID of the supplier to be deleted.
   * @param {string} stockItemId - The ID of the stock item associated with the supplier.
   */
  const deleteSupplierDetails = async (supplierId: any) => {
    setIsLoadingToast(true);
    setToastMessage("Loading...");
    setToastType("info");
    // Create a form with the required data
    const deleteFormData = {
      locationId: match.params.locationId,
      supplierId: supplierId,
      stockItemId: id,
    };
    try {
      // Make an API call to delete the supplier details
      await deleteStockSupplierMap(match.params.locationId, deleteFormData);

      setIsLoadingToast(false);
      setToastMessage(SUCCESSFULLY_DELETED);
      setToastType("success");
    } catch (error) {
      // If an error occurs during the API call, set the 'error' state with an unexpected error message
      // setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingToast(false);
      setToastMessage(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setToastType("error");
    }
  };

  const getSingleStockItemGivenId = async (updatedStockItem: any) => {
    const res = await fetchStockItemInfoGetSingle(
      match.params.locationId,
      updatedStockItem.id,
    );

    updatedStockItem.version = res.data.data.version;
    handleUpdateStockItems(updatedStockItem);
  };

  /**
   * Handle the update of stock items. This function is responsible for updating stock item information.
   *
   * @param {object} updatedStockItem - The updated stock item data.
   */
  const handleUpdateStockItems = async (updatedStockItem: any) => {
    // Clear any previous errors.
    setError("");

    try {
      // Send a request to update stock item information.
      const res = await updateStockItemsInfo(
        match.params.locationId,
        updatedStockItem,
      );

      if (res.data.status === HttpStatus.CONFLICT_409) {
        getSingleStockItemGivenId(updatedStockItem);
      } else {
        // Handle creating suppliers for the created stock item
        handleCreateSuppliersForStockItem(res.data.data.id);

        // Set the name and ID of the updated stock item.
        setName(res.data.data.name);
        setStockItemId(res.data.data.id);

        // Clear selected department and group objects.
        setSelectedDepartmentObj({});
        setSelectedGroupObj({});



        // Refresh stock item details.
        handleGetStockItemDetails();
      }
    } catch (error) {
      // If an error occurs during the API call, set the 'error' state with an unexpected error message
      // setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingToast(false);
      setToastMessage(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setToastType("error");
    }
  };

  /**
   * Reset various state variables to their initial values.
   */
  const handleReset = () => {
    // Reset form fields (assuming 'reset' is a function that resets form data).
    reset();
    setIsCreated(false);
    setIsOpenCreateStockItemsModal(false);
    setIsAutoConversion(true);
    setIsShowSeparate(true);
    setOutputMultiplierObj({});
    setCheckedProductIdList([]);
    setOutputMultiplierObj({});
    setStockMappingDetailsNode([]);
    setCheckedProductIdListInitial([]);
    setOutputMultiplierObjInitial({});

    setSelectedSupplierList([]);
    setSelectedSupplierListInitial([]);
  };

  /**
   * Reset various state variables to their initial values after creating data.
   */
  const handleResetCreatedData = () => {
    // Reset the selected node data to an empty array
    setSelectedNodeData([]);
    setSelectedSupplierList([]);
    setSelectedSupplierListInitial([]);

    // Reset the 'isCreated' flag to false
    setIsCreated(false);

    // Reset the form fields to their default values (assuming 'reset' is a function that resets form data).
    reset();
  };

  /**
   * Handle the form submission by preparing and updating or creating stock item data.
   *
   * @param {Object} data - The form data submitted.
   */
  const handleSubmitData = handleSubmit((data) => {
    setIsEditLoading(false);
    setIsLoadingToast(true);
    setToastMessage("Loading...");
    setToastType("info");

    // Create a deep clone of the submitted data
    const updatedStockItem = _.cloneDeep(data);

    // Set properties for the updated stock item
    updatedStockItem.active = true;
    updatedStockItem.primaryLocationId = match.params.locationId;
    updatedStockItem.criticalThreshold = parseFloat(data.criticalThreshold);
    updatedStockItem.inputMultiplier = parseFloat(data.inputMultiplier);
    updatedStockItem.warningThreshold = parseFloat(data.warningThreshold);
    updatedStockItem.stockDepartmentId = selectedDepartmentObj.id;
    updatedStockItem.autoUnitConvert = isAutoConversion;
    updatedStockItem.showRemainderSep = isAutoConversion
      ? isShowSeparate
      : false;
    updatedStockItem.version = 0;
    if (isOpenEditStockItemsModal) {
      // If editing an existing item, update its ID and version
      const { id, version } = selectedNodeData;
      updatedStockItem.id = id;
      updatedStockItem.version = version;
      handleUpdateStockItems(updatedStockItem);
    } else {
      // If creating a new item, call the create function
      handleCreateStockItems(updatedStockItem);
    }
    setIsLoad(true);
  });

  /**
   * Handles the creation of suppliers for a stock item, and optionally updates
   * selected suppliers if it's a delete operation.
   *
   * @param {any} stockItemId - The ID of the stock item.
   * @param {boolean} isDelete - Indicates whether this is a delete operation.
   */
  const handleCreateSuppliersForStockItem = async (stockItemId: any) => {
    setError("");
    // Prepare the data for the supplier creation
    const data = {
      stockItemId: stockItemId,
      suppliers: selectedSupplierList,
    };

    try {
      // Make an API call to create suppliers for the stock item
      await createSupplierForStockItem(match.params.locationId, data);
      handleGetSelectedSupplier().then(() => {
        setSearchName("");
        setSearchNameTab("");
        // Check if this is an edit or create operation
        if (isOpenEditStockItemsModal) {
          setIsOpenCreateStockItemsModal(false);
          setIsLoadingToast(false);
          setToastMessage(SUCCESSFULLY_UPDATED);
          setToastType("success");
        } else {
          setIsLoadingToast(false);
          setToastMessage(SUCCESSFULLY_CREATED);
          setToastType("success");
        }
      });
    } catch (error) {
      // Handle errors by setting an error message
      setIsLoadingToast(false);
      setToastMessage(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setToastType("error");
    }
  };

  /**
   * Handle department name change.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event - The input change event.
   */
  const handleChangeDepartmentName = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    // Set the department name based on the input value
    const newDepartmentName: string = event.target.value;
    setDepartmentName(newDepartmentName);
  };

  /**
   * Handle group name change.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event - The input change event.
   */
  const handleChangeGroupName = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    // Set the group name based on the input value
    const newGroupName: string = event.target.value;
    setGroupName(newGroupName);
  };

  /**
   * Compare two arrays for equality.
   *
   * @param {Array} array1 - The first array to compare.
   * @param {Array} array2 - The second array to compare.
   * @returns {boolean} - `true` if the arrays are equal, `false` otherwise.
   */
  const result = areArraysEqual(
    checkedProductIdList,
    checkedProductIdListInitial,
  );

  /**
   * Compare two arrays for equality.
   *
   * @param {Array} array1 - The first array to compare.
   * @param {Array} array2 - The second array to compare.
   * @returns {boolean} - `true` if the arrays are equal, `false` otherwise.
   */
  const results = areObjectsEqual(
    outputMultiplierObj,
    outputMultiplierObjInitial,
  );

  /**
   * Handle the opening of the department add modal.
   */
  const handleOpenDepartmentAddModal = () => {
    // Clear the department name input and set the modal state to open
    setDepartmentName("");
    setIsOpenDepartmentAddModal(true);
  };

  /**
   * Handle the opening of the supplier add modal.
   */
  const handleOpenSupplierAddModal = () => {
    setSearchName("");
    setIsSearch(false);
    setIsOpenSupplierAddModals(true);
  };

  /**
   * Handle the opening of the group add modal.
   */
  const handleOpenGroupAddModal = () => {
    setGroupName("");
    setIsOpenGroupAddModal(true);
  };

  /**
   * Handles the creation of a new department by making an API call to create the department
   * with the provided data. If successful, it sets the group ID and fetches all stock departments
   * for the newly created department. Closes the department add modal upon completion.
   */
  const handleCreateNewDepartment = async () => {
    setIsLoadingToast(true);
    setToastMessage("Loading...");
    setToastType("info");

    const departmentData = {
      name: departmentName,
      stockGroupId: selectedGroupObj.id,
      locationId: match.params.locationId,
    };

    try {
      // Attempt to create a new department via an API call
      const response = await createStockDepartments(
        match.params.locationId,
        departmentData,
      );
      // Set the group ID based on the API response
      setGroupId(response.data.data.stockGroupId.id);

      // Fetch all stock departments for the newly created department
      getAllStockDepartments(response.data.data.id);

      // Close the department add modal upon successful creation
      setIsOpenDepartmentAddModal(false);

      setIsLoadingToast(false);
      setToastMessage(SUCCESSFULLY_CREATED);
      setToastType("success");
    } catch (err) {
      // If an error occurs during the API call, set the 'error' state with an unexpected error message
      // setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingToast(false);
      setToastMessage(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setToastType("error");
    }
  };

  /**
   * Handles the creation of a new group by making an API call to create the group
   * with the provided data. If successful, it sets the group ID and fetches all stock groups
   * for the newly created group. Closes the group add modal upon completion.
   */
  const handleCreateNewGroup = async () => {
    setIsLoadingToast(true);
    setToastMessage("Loading...");
    setToastType("info");

    // Prepare the data for creating a new group
    const groupData = {
      name: groupName,
      locationId: match.params.locationId,
    };
    try {
      // Attempt to create a new group via an API call
      const response = await createStockGroups(
        match.params.locationId,
        groupData,
      );
      // Set the group ID based on the API response
      setGroupId(response.data.data.id);

      // Fetch all stock groups for the newly created group
      getAllStockGroup(response.data.data.id, true);

      // Close the group add modal upon successful creation
      setIsOpenGroupAddModal(false);

      setIsLoadingToast(false);
      setToastMessage(SUCCESSFULLY_CREATED);
      setToastType("success");
    } catch (err) {
      // If an error occurs during the API call, set the 'error' state with an unexpected error message
      // setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingToast(false);
      setToastMessage(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setToastType("error");
    }
  };

  /**
   * Handles the change of selected group and updates the selected group object and ID.
   *
   * @param {Object} e - The event object from the change event.
   * @param {Object} groupData - The data of the selected group.
   */
  const handleGroupChange = (e: any, groupData: any) => {
    // Update the selected group object
    setSelectedGroupObj(groupData);

    // Set the selected group ID
    setGroupId(groupData.id);
  };

  const handleDisable = () => {
    if (!_.isEmpty(selectedDepartmentObj)) {
      const selectedIdList: any = [];

      selectedSupplierListInitial.map((data: any) => {
        if (!selectedSupplierList.includes(data)) {
          selectedIdList.push(data);
        }
      });

      if (isDirty) {
        return false;
      } else if (selectedDepartmentObj.id !== selectedDepartmentIdInitial) {
        return false;
      } else if (
        !_.isEmpty(selectedIdList) ||
        selectedSupplierListInitial.length !== selectedSupplierList.length
      ) {
        return false;
      } else if (isAutoConversion !== isAutoConversionInitial) {
        return false;
      } else if (isShowSeparate !== isShowSeparateInitial) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  /**
   * Handles the mapping of a supplier to the location.
   *
   * @param {any} supplierId - The ID of the supplier to be mapped.
   */
  const handleSupplierMapping = async (supplierId: any) => {
    const data = {
      locationId: match.params.locationId,
      supplierId: supplierId,
    };

    try {
      await supplierMap(match.params.locationId, data);

      // Update supplier information and set success message
      handleGetSupplierInfo();
      setIsLoadingToast(false);
      setToastMessage(SUCCESSFULLY_CREATED);
      setToastType("success");
      setIsOpenSupplierAddModals(false);
    } catch (err) {
      // If an error occurs during the API call, set the 'error' state with an unexpected error message
      // setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingToast(false);
      setToastMessage(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setToastType("error");
    }
  };

  /**
   * Handles the creation of a new supplier and maps it to the location.
   *
   * @param {any} supplierData - The data for creating the new supplier.
   */
  const handleCreateNewSupplier = async (supplierData: any) => {
    setIsLoadingToast(true);
    setToastMessage("Loading...");
    setToastType("info");

    try {
      // Attempt to create a new supplier using the 'createSupplier' API
      const response = await createSupplier(
        match.params.locationId,
        supplierData,
      );

      // Map the newly created supplier to the location
      handleSupplierMapping(response.data.data.id);
    } catch (error) {
      // If an error occurs during the API call, set the 'error' state with an unexpected error message
      // setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingToast(false);
      setToastMessage(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setToastType("error");
    }
  };

  const max = useMediaQuery("(max-width: 600px)");
  const dialogRef = useRef<HTMLDivElement>(null);
  return (
    <>
      {
        <DialogCommonOverflow
          open={isOpenCreateStockItemsModal}
          setOpen={setIsOpenCreateStockItemsModal}
        >
          <DialogContent>
            <DialogTitle style={{ marginBottom: "8px", padding: "0px" }}>
              <Hidden xsDown>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography variant="h6" style={{ marginLeft: "4px" }}>
                    {isOpenEditStockItemsModal
                      ? `Update ${initialName} Stock Item`
                      : "Add New Stock Item"}
                  </Typography>
                  <div>
                    <ButtonCommon
                      onClick={() => {
                        handleReset();
                        setIsLoad(true);
                        setIsEditLoading(false);
                      }}
                      disabled={isLoadingToast}
                      variant="contained"
                      style={{
                        fontSize: 11,
                        width: "120px",
                        marginRight: "12px",
                      }}
                      color={buttonColors.CANCEL_BUTTON_COLOR}
                    >
                      Cancel
                    </ButtonCommon>
                    <>
                      <ButtonCommon
                        disabled={
                          !isOpenEditStockItemsModal
                            ? !isDirty ||
                              _.isEmpty(selectedDepartmentObj) ||
                              _.isEmpty(selectedSupplierList) ||
                              isLoadingToast
                            : handleDisable() || isLoadingToast
                        }
                        onClick={handleSubmitData}
                        variant="contained"
                        style={{
                          fontSize: 11,
                          width: isCreated ? "200px" : "120px",
                        }}
                        color={
                          isCreated
                            ? buttonColors.CREATE_BUTTON_COLOR
                            : isOpenEditStockItemsModal
                            ? buttonColors.UPDATE_BUTTON_COLOR
                            : buttonColors.CREATE_BUTTON_COLOR
                        }
                      >
                        {`${isOpenEditStockItemsModal ? "Update" : "Create"}`}
                      </ButtonCommon>
                    </>
                  </div>
                </div>
              </Hidden>
              <Hidden smUp>
                <Typography variant="h6" style={{ marginLeft: "4px" }}>
                  {isOpenEditStockItemsModal
                    ? `Update ${initialName} Stock Item`
                    : "Add New Stock Item"}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "12px",
                    marginBottom: "12px",
                  }}
                >
                  <ButtonCommon
                    onClick={() => {
                      handleReset();
                      setIsLoad(true);
                      setIsEditLoading(false);
                    }}
                    disabled={isLoadingToast}
                    variant="contained"
                    style={{
                      fontSize: 11,
                      width: "120px",
                      marginRight: "12px",
                    }}
                    color={buttonColors.CANCEL_BUTTON_COLOR}
                  >
                    Cancel
                  </ButtonCommon>
                  <ButtonCommon
                    disabled={
                      !isOpenEditStockItemsModal
                        ? !isDirty ||
                          _.isEmpty(selectedDepartmentObj) ||
                          _.isEmpty(selectedSupplierList) ||
                          isLoadingToast
                        : handleDisable() || isLoadingToast
                    }
                    onClick={handleSubmitData}
                    variant="contained"
                    style={{
                      fontSize: 11,
                      width: "120px",
                    }}
                    color={
                      isOpenEditStockItemsModal
                        ? buttonColors.UPDATE_BUTTON_COLOR
                        : buttonColors.CREATE_BUTTON_COLOR
                    }
                  >
                    {isOpenEditStockItemsModal ? "Update" : "Create"}
                  </ButtonCommon>
                </div>
              </Hidden>
            </DialogTitle>

            <div ref={dialogRef}>
              <AddStockItems
                register={register}
                errors={errors}
                selectedNodeData={selectedNodeData}
                reset={reset}
                stockDepartmentSelectedNode={stockDepartmentSelectedNode}
                supplierDetailsNode={supplierDetailsNode}
                setSelectedSupplierList={setSelectedSupplierList}
                selectedSupplierList={selectedSupplierList}
                selectedDepartmentObj={selectedDepartmentObj}
                setSelectedDepartmentObj={setSelectedDepartmentObj}
                handleOpenDepartmentAddModal={handleOpenDepartmentAddModal}
                setSelectedDepartmentIdInitial={setSelectedDepartmentIdInitial}
                handleOpenSupplierAddModal={handleOpenSupplierAddModal}
                handleChangeSearchSupplier={handleChangeSearchSupplier}
                handleClearSearchSupplier={handleClearSearchSupplier}
                searchName={searchName}
                isSearch={isSearch}
                deleteSupplierDetails={deleteSupplierDetails}
                selectedSupplierListInitial={selectedSupplierListInitial}
                setIsAutoConversion={setIsAutoConversion}
                isAutoConversion={isAutoConversion}
                setIsShowSeparate={setIsShowSeparate}
                isShowSeparate={isShowSeparate}
                setIsAutoConversionInitial={setIsAutoConversionInitial}
                setIsShowSeparateInitial={setIsShowSeparateInitial}
                setIsLoad={setIsLoad}
              />
            </div>
          </DialogContent>
          <DialogActions style={{ marginBottom: "12px" }}>
            <div style={{ display: "block", width: "100%" }}>
              <div
                style={
                  max
                    ? { display: "block" }
                    : {
                        marginRight: "16px",
                        display: "flex",
                        justifyContent: "end",
                      }
                }
              >
                <div
                  style={
                    max
                      ? {
                          marginTop: "12px",
                          marginRight: "12px",
                          marginLeft: "12px",
                        }
                      : { display: "flex", alignItems: "center" }
                  }
                >
                  <Hidden xsDown>
                    <ButtonCommon
                      onClick={() => {
                        handleReset();
                        setIsLoad(true);
                        setIsEditLoading(false);
                      }}
                      disabled={isLoadingToast}
                      variant="contained"
                      style={{
                        fontSize: 11,
                        width: "120px",
                        marginRight: "12px",
                      }}
                      color={buttonColors.CANCEL_BUTTON_COLOR}
                    >
                      Cancel
                    </ButtonCommon>
                    <ButtonCommon
                      disabled={
                        !isOpenEditStockItemsModal
                          ? !isDirty ||
                            _.isEmpty(selectedDepartmentObj) ||
                            _.isEmpty(selectedSupplierList) ||
                            isLoadingToast
                          : handleDisable() || isLoadingToast
                      }
                      onClick={
                        isCreated ? handleResetCreatedData : handleSubmitData
                      }
                      variant="contained"
                      style={{
                        fontSize: 11,
                        width: isCreated ? "200px" : "120px",
                        marginRight: "4px",
                      }}
                      color={
                        isCreated
                          ? buttonColors.CREATE_BUTTON_COLOR
                          : isOpenEditStockItemsModal
                          ? buttonColors.UPDATE_BUTTON_COLOR
                          : buttonColors.CREATE_BUTTON_COLOR
                      }
                    >
                      {isOpenEditStockItemsModal ? "Update" : "Create"}
                    </ButtonCommon>
                  </Hidden>
                  <Hidden smUp>
                    <Grid container>
                      <Grid item xs={6}>
                        <ButtonCommon
                          onClick={() => {
                            handleReset();
                            setIsLoad(true);
                            setIsEditLoading(false);
                          }}
                          disabled={isLoadingToast}
                          variant="contained"
                          style={{
                            fontSize: 11,
                            width: "120px",
                            marginRight: "12px",
                          }}
                          color={buttonColors.CANCEL_BUTTON_COLOR}
                        >
                          Cancel
                        </ButtonCommon>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        <ButtonCommon
                          disabled={
                            !isOpenEditStockItemsModal
                              ? !isDirty ||
                                _.isEmpty(selectedDepartmentObj) ||
                                _.isEmpty(selectedSupplierList) ||
                                isLoadingToast
                              : handleDisable() || isLoadingToast
                          }
                          onClick={handleSubmitData}
                          variant="contained"
                          style={{
                            fontSize: 11,
                            width: "120px",
                            marginRight: "4px",
                          }}
                          color={
                            isOpenEditStockItemsModal
                              ? buttonColors.UPDATE_BUTTON_COLOR
                              : buttonColors.CREATE_BUTTON_COLOR
                          }
                        >
                          {isOpenEditStockItemsModal ? "Update" : "Create"}
                        </ButtonCommon>
                      </Grid>
                    </Grid>
                  </Hidden>
                </div>
              </div>
            </div>
          </DialogActions>
        </DialogCommonOverflow>
      }

      {isOpenNewModal && (
        <ConditionalModal
          isOpenNewModal={isOpenNewModal}
          setIsOpenNewModal={setIsOpenNewModal}
          isLoadingToast={isLoadingToast}
          handleReset={handleReset}
          handleOpenEditMapProductsItemsModal={
            handleOpenEditMapProductsItemsModal
          }
          stockItemId={stockItemId}
          displaySuffix={displaySuffix}
          setIsOpenMapProducts={setIsOpenMapProductsEditModal}
          setIsOpenCreateStockItemsModal={setIsOpenCreateStockItemsModal}
          handleResetCreatedData={handleResetCreatedData}
          name={name}
        />
      )}
      {isOpenSupplierAddModals && (
        <AddSupplierModal
          isOpenSupplierAddModals={isOpenSupplierAddModals}
          setIsOpenSupplierAddModals={setIsOpenSupplierAddModals}
          handleCreateNewSupplier={handleCreateNewSupplier}
          isLoadingToast={isLoadingToast}
        />
      )}
      {isOpenDepartmentAddModal && (
        <StockDepartmentAddModal
          isOpenDepartmentAddModal={isOpenDepartmentAddModal}
          setIsOpenDepartmentAddModal={setIsOpenDepartmentAddModal}
          setOpen={setOpen}
          open={open}
          stockGroupSelectedNode={stockGroupSelectedNode}
          selectedGroupObj={selectedGroupObj}
          isLoadingToast={isLoadingToast}
          handleGroupChange={handleGroupChange}
          handleOpenGroupAddModal={handleOpenGroupAddModal}
          departmentName={departmentName}
          handleChangeDepartmentName={handleChangeDepartmentName}
          handleCreateNewDepartment={handleCreateNewDepartment}
        />
      )}
      {isOpenGroupAddModal && (
        <StockGroupAddModal
          isOpenGroupAddModal={isOpenGroupAddModal}
          setIsOpenGroupAddModal={setIsOpenGroupAddModal}
          isLoadingToast={isLoadingToast}
          groupName={groupName}
          handleChangeGroupName={handleChangeGroupName}
          handleCreateNewGroup={handleCreateNewGroup}
        />
      )}
    </>
  );
};

export default AddStockItemsModal;
